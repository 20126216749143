import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletetripHandler } from "../../../features/tripSlice";
import EditEmployeeModal from "../../../components/Modal/EditDriverCleaner/EditEmployeeModal";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

interface RowData {
  id: any;
  trip_id: string;
  service_id: string;
  trip_origin: string;
  trip_destination: string;
  start_date: string;
  end_date: string;
  drivers: any;
  cleaners: any;
  start_time: string;
  end_time: string;
  status: string;
}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const TripTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { permissions } = useSelector((state: RootState) => state.permission);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [title, setTitle] = useState('');



  console.log(permissions);
  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const handleEditModal = (id: any,title:string) => {
    setOpenEditModel(true);
    setTitle(title)
  };

  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deletetripHandler(selectedId));
  };
  const confirmEdit = (data:any) => {
    console.log(data)
    setOpenEditModel(false);
  };

  const newColumns = [
    ...columns,
    {
      label: "Actions",
      field: "actions",
      width: 200,
    },
  ];
  const data = {
    columns: newColumns,
    rows: rows
      ? rows.map((rowData, index) => ({
          ...rowData,
          // drivers: (
          //   <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
          //     <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
          //       {rowData?.drivers?.map((driver: any) => driver?.driver_name)}
          //     </div>
          //     <div>
          //       <div className="icon-container" onClick={() => handleEditModal(rowData.id,"Driver")}>
          //         <PiNotePencilBold style={{ fontSize: "18px" }} />
          //       </div>
          //     </div>
          //   </div>
          // ),
          // cleaners: (
          //   <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
          //     <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
          //       {rowData?.cleaners?.map(
          //         (cleaner: any) => cleaner?.cleaner_name
          //       )}
          //     </div>
          //     <div>
          //       <div className="icon-container" onClick={() => handleEditModal(rowData.id,"Cleaner")}>
          //         <PiNotePencilBold style={{ fontSize: "18px" }} />
          //       </div>
          //     </div>
          //   </div>
          // ),
          status: (
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                {rowData?.status}
              </div>
              {/* <div>
                <div className="icon-container" onClick={() => handleEditModal(rowData.id,"Cleaner")}>
                  <PiNotePencilBold style={{ fontSize: "18px" }} />
                </div>
              </div> */}
            </div>
          ),
          actions: (
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
              {permissions.trips?.view && (
                <Button
                  type="button"
                  color="primary"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.tripDetail(rowData.id))}
                >
                  <PiEyeBold style={{ fontSize: "18px" }} />
                </Button>
              )}
              {permissions.trips?.edit && (
                <Button
                  type="button"
                  color="info"
                  className="waves-effect waves-light"
                  onClick={() => navigate(routerPath.updateTrip(rowData.id))}
                >
                  <PiNotePencilBold style={{ fontSize: "18px" }} />
                </Button>
              )}
              {permissions.trips?.delete && (
                <Button
                  type="button"
                  color="danger"
                  className="waves-effect waves-light"
                  onClick={() => handleDeleteModal(rowData.id)}
                >
                  <FaRegTrashAlt style={{ fontSize: "18px" }} />
                </Button>
              )}
            </div>
          ),
        }))
      : [],
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Trip"}
      />
      <EditEmployeeModal
        openModal={openEditModel}
        setOpenModal={setOpenEditModel}
        confirm={confirmEdit}
        title={title}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex gap-2 justify-content-end">
              <ParseToCsv data={rows} fileLabel="trips"/>
                {permissions.trips?.add && (
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() => navigate(routerPath.addTrip)}
                  >
                    Add Trip
                  </Button>
                )}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TripTable;

import React, { FC, useState } from "react";
import Modal from "../Modal";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ModalMessege, ModalWrapper } from "../ActionModal/StyleDeleteModal";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/Store";


interface ModalProp {
  title:string;
  openModal: boolean;
  setOpenModal: any;
  confirm: any;
}

const EditEmployeeModal: FC<ModalProp> = ({
  openModal,
  setOpenModal,
  confirm,
  title
}) => {

  const { allEmployees } = useSelector((state: RootState) => state.employees);
  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
  

  const handleSubmit = (event: any, errors: any, values: any) => {
    confirm(selectedDrivers);
    setSelectedDrivers([])
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        name={"Create update employee modal"}
        title={`Update ${title}`}
        toggleModal={() => setOpenModal(false)}
      >
         <ModalWrapper style={{textAlign:"left"}}>
          <ModalMessege>
            Select {title}.
           </ModalMessege>
           <Card>
        <CardBody>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
              <div className="mb-3">
                  <Label htmlFor="drivers">{title}</Label>
                  <Autocomplete
                    multiple
                    id="drivers"
                    size="small"
                    options={allEmployees}
                    getOptionLabel={(employee: any) => employee.employee_name}
                    onChange={(event, value) => setSelectedDrivers(value)}
                    value={selectedDrivers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={selectedDrivers.length === 0}
                        helperText={
                          selectedDrivers.length === 0
                            ? `Please select at least one ${title}.`
                            : ""
                        }
                        
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
            
            <div className="d-flex justify-content-end gap-2">
              <Button
                type="button"
                color="primary"
                className="waves-effect waves-light"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="danger"
                className="waves-effect waves-light"
              >
                Update
              </Button>
            </div>
          </AvForm>
        </CardBody>
        </Card>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default EditEmployeeModal;


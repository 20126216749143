import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { RootState } from "../../../app/Store";
import DesignationTable from "../../Tables/EmployeeTables/DesignationTable";
import { getAllDesignation, resetemployeeState } from "../../../features/employeesSlice";

const data = [
  { id: 1, designation_name: "john doe", status: "1" },
  { id: 2, designation_name: "john doe", status: "0" },
  { id: 2, designation_name: "john doe", status: "1" },
];
const DesignationList = () => {
  document.title = "Designation List| KSM - Admin Dashboard.";
 const {allDesignation}=useSelector((state:RootState)=>state.employees);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Designation List",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Designation list", link: "#" },
        ],
      })
    );
    dispatch(getAllDesignation());
    dispatch(resetemployeeState());

  }, []);
console.log(allDesignation)
  const columnData = [
    {
      label: "Sr.No",
      field: "id",
      sort: "asc",
      width: 150,
    },

    {
      label: "Designation Name",
      field: "designation_name",
      sort: "asc",
      width: 150,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 150,
    },
  ];
  return (
    <div>
      <DesignationTable rows={allDesignation} columns={columnData} />
    </div>
  );
};

export default DesignationList;

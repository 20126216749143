import { FC, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderLogoUrl from "../../assets/ksmlogoyellow.png";
import { routerPath } from "../../routes/routes";
import {
  StyledWrapper,
  NavLogo,
  LogoSection,
  NavMenuItem,
  NavIcon,
  NavLabel,
  NavItems,
  Option,
  SubmenuContainer,
  LabelAndArrow,
  LeftMenuSection,
  StyledMenu,
  DotSingleIcon,
  DownIcon,
  Bus,
  BusRoute,
  BusStop,
  CancelIcon,
  IconWrapper,
  UserIcon,
  LeftIcon,
  MasterIcon,
  TripIcon,
  EmployeeIcon,
  BusAlert,
  PayrollIcon,
  
} from "./StyledLeftmenu";
import {
  DashboardIcon1
} from "./StyledLeftmenu";
import { toggleLeftMenu } from "../../features/leftMenuSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/Store";

interface SubmenuOption {
  submenuLabel: string;
  isActive: boolean;
  routePath: string;
  activeRoutePaths: string[];
  iconName: string;
  listTitle: string;
}

interface NavResponse {
  label: string;
  isActive: boolean;
  id: string;
  position: number;
  isVisible: boolean;
  routePath: string;
  isSubmenuExists: boolean;
  isSubmenuExpanded: boolean;
  iconName: string;
  submenu?: SubmenuOption[];
  activeRoutePaths: string[];
  listTitle: string;
}

let leftMenuResponse: NavResponse[] = [
  {
    label: "Dashboard",
    isActive: true,
    id: "dashboard_1",
    iconName: "DashboardIcon",
    position: 1,
    isVisible: true,
    routePath: routerPath.dashboard,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.dashboard, routerPath.rootPath],
    listTitle: "dashboard",
    submenu: [],
  },
  {
    label: "Trip",
    isActive: false,
    id: "trip_2",
    iconName: "TripIcon",
    position: 2,
    isVisible: true,
    routePath: routerPath.trip,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.trip],
    listTitle: "trips",
    submenu: [],
  },
  {
    label: "Compliance",
    isActive: false,
    id: "ompliance_2",
    iconName: "BusAlertIcon",
    position: 4,
    isVisible: true,
    routePath: routerPath.compliance,
    isSubmenuExists: false,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.compliance],
    listTitle: "compliance",
    submenu: [],
  },
  {
    label: "Master List",
    isActive: false,
    id: "schedules_3",
    iconName: "MasterIcon",
    position: 4,
    isVisible: true,
    routePath: routerPath.servicesList,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [
      routerPath.servicesList,
      routerPath.buses,
      routerPath.route,
      routerPath.stopList,
    ],
    listTitle: "master",
    submenu: [
      {
        submenuLabel: "Services",
        isActive: true,
        routePath: routerPath.servicesList,
        activeRoutePaths: [routerPath.servicesList],
        iconName: "",
        listTitle: "services",
      },

      {
        submenuLabel: "Buses",
        isActive: false,
        routePath: routerPath.buses,
        activeRoutePaths: [routerPath.buses],
        iconName: "",
        listTitle: "buses",
      },

      {
        submenuLabel: "Routes",
        isActive: false,
        routePath: routerPath.route,
        activeRoutePaths: [routerPath.route],
        iconName: "",
        listTitle: "routes",
      },
      {
        submenuLabel: "Stops",
        isActive: false,
        routePath: routerPath.stopList,
        activeRoutePaths: [routerPath.stopList],
        iconName: "",
        listTitle: "stops",
      },
    ],
  },

  
  {
    label: "Employees",
    isActive: false,
    id: "list_8",
    iconName: "EmployeeIcon",
    position: 6,
    isVisible: true,
    routePath: routerPath.employee,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.employee, routerPath.designation],
    listTitle: "epmloyees",
    submenu: [
      {
        submenuLabel: "Employee",
        isActive: false,
        routePath: routerPath.employee,
        activeRoutePaths: [routerPath.employee],
        iconName: "",
        listTitle: "employees",
      },
      {
        submenuLabel: "Designation",
        isActive: false,
        routePath: routerPath.designation,
        activeRoutePaths: [routerPath.designation],
        iconName: "",
        listTitle: "designations",
      },
    ],
  },
  {
    label: "Payroll",
    isActive: false,
    id: "list_9",
    iconName: "PayrollIcon",
    position: 7,
    isVisible: true,
    routePath: routerPath.payrollList,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.payrollList, routerPath.payrollComponents,routerPath.payrollRuns],
    listTitle: "payrolls",
    submenu: [
      {
        submenuLabel: "Payroll List",
        isActive: true,
        routePath: routerPath.payrollList,
        activeRoutePaths: [routerPath.payrollList],
        iconName: "",
        listTitle: "payrolls",
      },
      {
        submenuLabel: "Component List",
        isActive: true,
        routePath: routerPath.payrollComponents,
        activeRoutePaths: [routerPath.payrollComponents],
        iconName: "",
        listTitle: "payrollComponents",
      },
      {
        submenuLabel: "Pay Runs",
        isActive: true,
        routePath: routerPath.payrollRuns,
        activeRoutePaths: [routerPath.payrollRuns],
        iconName: "",
        listTitle: "payruns",
      },
    ],
  },
  {
    label: "Users",
    isActive: false,
    id: "users_7",
    iconName: "UserIcon",
    position: 5,
    isVisible: true,
    routePath: routerPath.users,
    isSubmenuExists: true,
    isSubmenuExpanded: false,
    activeRoutePaths: [routerPath.users, routerPath.user_role],
    listTitle: "masteruser",

    submenu: [
      {
        submenuLabel: "User",
        isActive: true,
        routePath: routerPath.users,
        activeRoutePaths: [routerPath.users],
        iconName: "",
        listTitle: "users",
      },

      {
        submenuLabel: "User Role",
        isActive: false,
        routePath: routerPath.user_role,
        activeRoutePaths: [routerPath.user_role],
        iconName: "",
        listTitle: "userrole",
      },
    ],
  },
];


interface MyLeftMenuProp {
  open:boolean
}

const LeftMenuMobile: FC<MyLeftMenuProp> = ({open}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const permissions:any=useSelector((state:RootState)=>state.permission.permissions);

  const [menuResponse, setMenuResponse] =
    useState<NavResponse[]>(leftMenuResponse);

  useEffect(() => {
    if (menuResponse.length === 0) {
      setMenuResponse(leftMenuResponse);
    }
  }, []);

  const isActivePath = (routePaths: string | string[]): boolean => {
    const currentPath = location.pathname;

    if (Array.isArray(routePaths)) {
      // Check if the current path matches any of the provided route paths in the array
      return routePaths.some((path) => currentPath === path);
    }

    // For single route path, check equality with current path
    return currentPath === routePaths;
  };

  const redirectToView = (path: string): void => {
    navigate(path);
  };

  const toggleCollapseState = (routerObj: any) => {
    let updatedMenuItems: NavResponse[] = [];
    for (let menuObj of menuResponse) {
      let updatedObj = {
        ...menuObj,
        isSubmenuExpanded:
          menuObj.id === routerObj.id ? !routerObj.isSubmenuExpanded : false,
      };
      updatedMenuItems.push(updatedObj);
    }
    setMenuResponse(updatedMenuItems);
    redirectToView(routerObj.routePath);
  };

  const submenuView = (submenuArray: SubmenuOption[] | any) => {
    return submenuArray.map((obj: SubmenuOption) => {
      return (
        permissions[obj.listTitle]?.view_list&& <Option
          isActive={isActivePath(obj.activeRoutePaths)}
          onClick={() => {
            redirectToView(obj.routePath);
          }}
        >          
           <span>{obj.submenuLabel}</span>
        </Option>
      );
    });
  };

  let menuItems = menuResponse.map((menuObj) => {
    return (
      menuObj?.listTitle==='trips'&&permissions.trips?.view_list===false?"":<>
        <NavMenuItem
          onClick={() => {
            toggleCollapseState(menuObj);
          }}
          isActive={isActivePath(menuObj.activeRoutePaths)}
        >
          <NavIcon>
            {menuObj.iconName === "DashboardIcon" && (
              <DashboardIcon1
                isActive={isActivePath(menuObj.activeRoutePaths)}
              />
            )}
            {menuObj.iconName === "BusAlertIcon" && (
              <BusAlert
                isActive={isActivePath(menuObj.activeRoutePaths)}
              />
            )}
            {menuObj.iconName === "ScheduleBus" && (
              <Bus isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "BusRoute" && (
              <BusRoute isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "BusStop" && (
              <BusStop isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "UserIcon" && (
              <UserIcon
                isActive={isActivePath(menuObj.activeRoutePaths)}
              />
            )}
            {menuObj.iconName === "MasterIcon" && (
              <MasterIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "TripIcon" && (
              <TripIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
            {menuObj.iconName === "EmployeeIcon" && (
              <EmployeeIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
           {menuObj.iconName === "PayrollIcon" && (
              <PayrollIcon isActive={isActivePath(menuObj.activeRoutePaths)} />
            )}
          </NavIcon>
          <NavLabel>
            <LabelAndArrow
              onClick={() => {
                toggleCollapseState(menuObj.id);
              }}
            >
              <span>{menuObj.label}</span>
            {menuObj.isSubmenuExists && !menuObj.isSubmenuExpanded&&<LeftIcon />}
              {menuObj.isSubmenuExists && menuObj.isSubmenuExpanded&&<DownIcon />}
            </LabelAndArrow>
          </NavLabel>
        </NavMenuItem>
        {menuObj.isSubmenuExists && menuObj.isSubmenuExpanded && (
          <SubmenuContainer isSubmenuVisible={menuObj.isSubmenuExpanded}>
            {submenuView(menuObj.submenu)}
          </SubmenuContainer>
        )}
      </>
    );
  });

  const handleLeftmenu=()=>{
    dispatch(toggleLeftMenu({
      open:false
    }))
  }

  return (
    // <LeftMenuSection >
    <StyledMenu open={open}>


    <StyledWrapper>
      <IconWrapper>
        <CancelIcon onClick={handleLeftmenu}/>
      </IconWrapper>
      <LogoSection>
        <NavLogo src={HeaderLogoUrl} />
      </LogoSection>
      <NavItems>{menuItems}</NavItems>
    </StyledWrapper>
    </StyledMenu>
    // </LeftMenuSection>
  );
};

export default LeftMenuMobile;

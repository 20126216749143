import React from "react";
import C3Chart from "react-c3js";
import "c3/c3.css";

const DonutChart = ({ busData, routeData, servicesData, tripsData }) => {
  const data = {
    columns: [
      ["Buses", 100, busData],
      ["Routes", 100, routeData],
      ["Services", 100, servicesData],
      ["Trips", 100, tripsData],
    ],
    type: "donut",
  };

  const donut = {
    title: "KSM Data",
    width: 30,
    label: { show: false }, // Labels are not shown
  };

  const color = {
    pattern: ["#116DAA", "#2092DE", "#2C5773"],
  };

  const size = {
    height: 300,
  };

  return (
    <React.Fragment>
      <C3Chart data={data} donut={donut} color={color} size={size} dir="ltr" />
    </React.Fragment>
  );
};

export default DonutChart;

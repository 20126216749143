import React from "react";
import { Button } from "reactstrap";
import Papa from "papaparse";

interface DocumentProps {
  data: {
    id: string;
    user_id: string;
    employee_id: string;
    photo_url: string;
    employee_name: string;
    designation: string;
    id_type: string;
    id_number: string;
    id_issued_by: string;
    id_issue_date: string;
    id_expiry_date: string;
    license_number: string;
    license_expiry_date: string;
    pan_number: string;
    pan_url: string;
    document_upload: {
      license_doc: string;
    };
    contact_number: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    emergency_contact_name: string;
    emergency_contact_number: string;
    emergency_contact_relation: string;
    status: string;
  };
  filename: string;
}

const statusMapping = (status: string) => {
  switch (status) {
    case '0':
      return 'Active';
    case '1':
      return 'Inactive';
    case '2':
      return 'Terminated';
    case '3':
      return 'Rest';
    default:
      return 'Unknown';
  }
};

const DownloadEmployeeCsv: React.FC<DocumentProps> = ({ data, filename }) => {
  const downloadCsv = () => {
    const rows = [
      ["Fields", "Value"],
      ["ID", data.id],
      ["User ID", data.user_id],
      ["Employee ID", data.employee_id],
      ["Employee Name", data.employee_name],
      ["Designation", data.designation],
      ["ID Type", data.id_type],
      ["ID Number", data.id_number],
      ["ID Issued By", data.id_issued_by],
      ["ID Issue Date", data.id_issue_date],
      ["ID Expiry Date", data.id_expiry_date],
      ["License Number", data.license_number],
      ["License Expiry Date", data.license_expiry_date],
      ["PAN Number", data.pan_number],
      ["Contact Number", data.contact_number],
      ["Address", data.address],
      ["City", data.city],
      ["State", data.state],
      ["Country", data.country],
      ["Zip Code", data.zip_code],
      ["Emergency Contact Name", data.emergency_contact_name],
      ["Emergency Contact Number", data.emergency_contact_number],
      ["Emergency Contact Relation", data.emergency_contact_relation],
      ["Status", statusMapping(data.status)],
      ["Photo URL", data.photo_url],
      ["PAN URL", data.pan_url],
      ["License Document", data.document_upload.license_doc],
    ];

    const csv = Papa.unparse(rows);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button
      type="button"
      color="primary"
      className="waves-effect waves-light"
      onClick={downloadCsv}
      aria-label="Download employee details as CSV"
    >
      Download CSV
    </Button>
  );
};

export default DownloadEmployeeCsv;

import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletedriverHandler } from "../../../features/driverSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/Store";
import { getAllUsers } from "../../../features/userSlice";
import { myPermissions } from "../../../constants/permissions";
import { deleteemployeeHandler, getAllDesignation } from "../../../features/employeesSlice";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

interface RowData {
  id: any;
  user_id: any;
  username: string;
  employee_id: string;
  employee_name: string;
  designation: string;
  status: string;

}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}


const EmployeeTable: React.FC<TableProp> = ({ columns, rows }) => {
  console.log(myPermissions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const { permissions } = useSelector((state: RootState) => state.permission);

  const { allUsers } = useSelector((state: RootState) => state.user);
  const { allDesignation } = useSelector((state: RootState) => state.employees);
  console.log(allUsers);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllDesignation());

  }, []);

  const getUserName = (id: any) => {
    return allUsers.map((user: any) => {
      if (user.id === id) {
        // console.log(user.username);
        return user.username;
      }
    });
  };
  
  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };
  const newColumns = [
    ...columns,
    {
      label: "Actions",
      field: "actions",
      width: 100,
    },
  ];

  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
    console.log(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteemployeeHandler(selectedId));
  };

  // Define the function to handle "View" button click
  const handleViewClick = (id: any) => {
    // Navigate to the bus detail page with the provided bus_id
    navigate(routerPath.employeeDetail(id));
  };

  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,
      username: getUserName(rowData.user_id),
      designation:<span className="text-capitalize">{getDesignationName(rowData.designation)}</span>,
      status:
        rowData.status === "0" ? (
          <span className="text-success">Active</span>
        ) : rowData.status === "1" ? (
          <span className="text-warning">Inactive</span>
        ) : rowData.status === "2" ?(
          <span className="text-danger">Terminated</span>
        ):(
          <span className="text-info">Rest</span>
        ),
      actions: (
        // <div className="d-flex justify-content-around align-items-center " >
        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
          {permissions.employees?.view && (
            <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={() => handleViewClick(rowData.id)}
              title="View"
            >
              <PiEyeBold style={{ fontSize: "18px" }} />
            </Button>
          )}
          {permissions.employees?.edit && (
            <Button
              type="button"
              color="info"
              className="waves-effect waves-light"
              onClick={() => navigate(routerPath.updateEmployee(rowData.id))}
              title="Update"
            >
              <PiNotePencilBold style={{ fontSize: "18px" }} />
            </Button>
          )}
          {permissions.employees?.delete && (
            <Button
              type="button"
              color="danger"
              className="waves-effect waves-light"
              onClick={() => handleDeleteModal(rowData.id)}
              title="Delete"
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </Button>
          )}
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Employee"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex gap-2 justify-content-end">
              <ParseToCsv data={rows} fileLabel="employee"/>
                {permissions.employees?.add && (
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() => navigate(routerPath.addEmployee)}
                  >
                    Add Employee
                  </Button>
                )}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default EmployeeTable;

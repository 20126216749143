 
import React, { useEffect, useState } from "react";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../styledPayroll";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllDesignation, getAllEmployees } from "../../../../features/employeesSlice";
import { RootState } from "../../../../app/Store";
import { routerPath } from "../../../../routes/routes";
import { useNavigate, useParams } from "react-router-dom";
import { addNewpayrollComponent, getPayrollComponentDetail, updatePayrollComponent } from "../../../../features/payrollComponentSlice";
interface SelectProp {
  id:string;
  designation_name:string;
}
const ViewPayrollComponent = () => {
  document.title = "View Payroll Component| KSM - Admin Dashboard.";
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { allDesignation } = useSelector((state: RootState) => state.employees);
  const { isSuccess ,payrollComponent} = useSelector((state: RootState) => state.payrollComponents);
  const [selectedRole, setSelectedRole] = useState<any>({});


  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "View Payroll Component",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Components", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getAllDesignation());
    dispatch(getPayrollComponentDetail(params.id));

  }, []);
  useEffect(() => {
    if (payrollComponent && payrollComponent?.eligible_employee_roles?.length > 0) {
      setSelectedRole(payrollComponent?.eligible_employee_roles[0]);
    }
  }, [payrollComponent]);
  useEffect(()=>{
    if(isSuccess===true){
        navigate(routerPath.payrollComponents);
    }
  },[isSuccess])
console.log(allDesignation)
const handleUserSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const selectedRole = event.target.value;
  const role= allDesignation.find(
    (obj: SelectProp) => obj.id === selectedRole
  );
  setSelectedRole({
    id:role?.id,
  designation_name:role?.designation_name
  });
  // console.log(role)
};

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const payloadData={
        ...values,
        id:params.id,
        eligible_employee_roles:[selectedRole],
      }
      // console.log(payloadData);
      dispatch(updatePayrollComponent(payloadData));

    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">View Payroll Component</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="preport-type1">Eligible Employee Roles</Label>
                  <AvField
                    disabled
                    name="eligible_employee_roles"
                    type="select"
                    errorMessage="Please select role."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type1"
                    onChange={handleUserSelect}
                    value={selectedRole}
                  >
                    <option value="">Select Employee Role</option>
                    {allDesignation.map((Obj: SelectProp) => (
                      <option key={Obj.designation_name} value={Obj.id} className="text-capitalize">
                        {Obj.designation_name}
                      </option>
                    ))}
                  </AvField>
                  
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="preport-type">Component Type</Label>
                  <AvField
                    disabled
                    name="component_type"
                    type="select"
                    errorMessage="Please select component type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type"
                    value={payrollComponent?.component_type}

                  >
                    <option value="">Select Component Type</option>
                    <option value="variable">Variable</option>
                    <option value="fixed">Fixed</option>
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="pan">Component Name</Label>
                  <AvField
                    disabled
                    name="component_name"
                    type="text"
                    placeholder='Enter component name'
                    errorMessage="Please enter component name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="pan"
                    value={payrollComponent?.component_name}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="preport-type1">Pay Frequency</Label>
                  <AvField
                    disabled
                    name="pay_frequency"
                    type="select"
                    errorMessage="Please select pay frequency."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type1"
                    value={payrollComponent?.pay_frequency}

                  >
                    <option value="">Select pay Frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="uni">Effective Date</Label>
                  <AvField
                    disabled
                    name="effective_date"
                    type="date"
                    placeholder="select date."
                    errorMessage="Select effective date.."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="uni"
                    value={payrollComponent?.effective_date}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="decr">Component Description</Label>
                  <AvField
                    disabled
                    name="component_description"
                    type="textarea"
                    placeholder="Enter component description..."
                    errorMessage="Please enter component description."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="decr"
                    value={payrollComponent?.component_description}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="decr1">Calulation</Label>
                  <AvField
                    disabled
                    name="calculation"
                    type="number"
                    placeholder="Enter percent of basic."
                    errorMessage="Please enter calulation."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="decr1"
                    value={payrollComponent?.calculation}

                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    disabled
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={payrollComponent?.status}

                  >
                    <option value="">Select Status</option>
                    <option value="0">Active</option>
                    <option value="1">Inactive</option>
                    <option value="2">Terminated</option>
                    <option value="3">Rest</option>
                  </AvField>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              {/* <Button color="primary" type="submit">
                Submit
              </Button> */}
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};




export default ViewPayrollComponent;

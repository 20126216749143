import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from "papaparse";

interface PayrollReport {
  WeekNum: string;
  "Trip #": string;
  "Service #": string;
  Route: string;
  "Travel date": string;
  Year: string;
  Vehicle: string;
  "Employee full name": string;
  "Emp Code": string;
  PayGrade: string;
  Salary: number;
  Deductions: number;
  Payable: number;
  "Payment Status": string;
  "Payment Date": string;
}

const downloadToCsv = (blobUrl: string, fileLabel: string) => {
  const dateObj = new Date();
  const link = document.createElement("a");
  const dateString = `${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`; // Create a date string for the file name
  link.download = `export_${fileLabel}_${dateString}_${dateObj.getTime()}.csv`; // Set the download file name
  link.href = blobUrl; // Set the href to the Blob URL
  link.click(); // Programmatically click the link to trigger the download
};

export const convertToBlobLink = (
  dataArray: PayrollReport[] | PayrollReport,
  weekNumber: number
) => {
  try {
    // If dataArray is not an array, convert it to an array
    if (!Array.isArray(dataArray)) {
      dataArray = [dataArray];
    }

    // Define the headers you want for your CSV
    const headers = [
      "WeekNum",
      "Trip #",
      "Service #",
      "Route",
      "Travel date",
      "Year",
      "Vehicle",
      "Employee full name",
      "Emp Code",
      "PayGrade",
      "Salary",
      "Deductions",
      "Payable",
      "Payment Status",
      "Payment Date",
    ];

    // Convert the JSON array to CSV
    const csv = Papa.unparse({
      fields: headers,
      data: dataArray.map((item) => ({
        WeekNum: item.WeekNum,
        "Trip #": item["Trip #"],
        "Service #": item["Service #"],
        Route: item.Route,
        "Travel date": item["Travel date"],
        Year: item.Year,
        Vehicle: item.Vehicle,
        "Employee full name": item["Employee full name"],
        "Emp Code": item["Emp Code"],
        PayGrade: item.PayGrade,
        Salary: item.Salary,
        Deductions: item.Deductions,
        Payable: item.Payable,
        "Payment Status": item["Payment Status"],
        "Payment Date": item["Payment Date"],
      })),
    });

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Trigger the download
    downloadToCsv(url, `report_week_${weekNumber}`);
  } catch (error) {
    console.error("Error converting data to CSV:", error);
  }
};

export const generatePdf = (dataArray: PayrollReport[], weekNumber: number) => {
  try {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add a title
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(`Payroll Report for Week ${weekNumber}`, pageWidth / 2, 20, {
      align: "center",
    });

    // Define table columns
    const columns = [
      { header: "Field", dataKey: "field" },
      { header: "Value", dataKey: "value" },
    ];

    // Process each payroll entry
    dataArray.forEach((item: PayrollReport, index: number) => {
      if (index > 0) {
        doc.addPage(); // Add a new page for each item except the first one
      }

      const rows = [
        { field: "Week Number", value: item.WeekNum },
        { field: "Trip #", value: item["Trip #"] },
        { field: "Service #", value: item["Service #"] },
        { field: "Route", value: item.Route },
        { field: "Travel Date", value: item["Travel date"] },
        { field: "Year", value: item.Year },
        { field: "Vehicle", value: item.Vehicle },
        { field: "Employee Full Name", value: item["Employee full name"] },
        { field: "Employee Code", value: item["Emp Code"] },
        { field: "Pay Grade", value: item.PayGrade },
        { field: "Salary", value: item.Salary.toFixed(2) },
        { field: "Deductions", value: item.Deductions.toFixed(2) },
        { field: "Payable", value: item.Payable.toFixed(2) },
        { field: "Payment Status", value: item["Payment Status"] },
        { field: "Payment Date", value: item["Payment Date"] },
      ];

      autoTable(doc, {
        columns: columns,
        body: rows,
        margin: { top: 30, left: 10, right: 10, bottom: 20 },
        styles: {
          fontSize: 12,
          cellPadding: 2,
          valign: "middle",
          lineColor: [44, 62, 80],
          lineWidth: 0.75,
          fontStyle: "normal",
          overflow: "linebreak",
        },
        headStyles: {
          fillColor: [135, 206, 235],
          textColor: [0, 0, 0],
          fontSize: 14,
          fontStyle: "bold",
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        columnStyles: {
          field: { cellWidth: 70 },
          value: { cellWidth: 120 },
        },
        theme: "grid",
      });

      // Get the final Y position to add footer
      const finalY = (doc as any).lastAutoTable.finalY || 30;

      doc.setFontSize(10);
      doc.setTextColor(0, 0, 255);
      // doc.textWithLink("Company Website", 10, finalY + 10, {
      //   url: "https://example.com",
      // });

      // Add date, time, and copyright at the bottom
      const now = new Date();
      const dateString = now.toLocaleDateString();
      const timeString = now.toLocaleTimeString();
      const footerText = `Generated on: ${dateString} ${timeString} © ${now.getFullYear()} KSM`;

      const footerWidth = doc.getStringUnitWidth(footerText) * 10;
      const xPosition = pageWidth - footerWidth - 10;
      const footerYPosition = doc.internal.pageSize.getHeight() - 10;

      if (xPosition < 10) {
        doc.text(footerText, 10, footerYPosition);
      } else {
        doc.text(footerText, xPosition, footerYPosition);
      }
    });

    // Save the PDF with a file name
    const dateObj = new Date();
    const dateStringForFile = `${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()}`;
    doc.save(`payroll_report_week_${weekNumber}_${dateStringForFile}.pdf`);
  } catch (error) {
    console.error("Failed to generate PDF:", error);
    alert("Failed to generate PDF. Please try again.");
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { Button, Card, CardBody, Col, Label, Row, Table, FormFeedback } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Wrapper } from "../../Services/AddService/StyledAddService";
import { useNavigate, useParams } from "react-router-dom";
import { getAllServices } from "../../../features/serviceSlice";
import { gettripDetail, updatetrip } from "../../../features/tripSlice";
import { routerPath } from "../../../routes/routes";
import { getUserData } from "../../../features/DashboardSlice";
import { Autocomplete, TextField } from "@mui/material";
import { getAllDesignation, getAllEmployees } from "../../../features/employeesSlice";

// Define the types used for employees and trip data
interface SelectProp {
  service_name: string;
  service_id: string;
}

interface ServiceData {
  id: any;
  isRecurring: any;
  bus_number: string;
  route_number: string;
  service_id: string;
  service_name: string;
  service_status: string;
}

interface TripData {
  serviceId: string;
  serviceName: string;
  routeId: string;
  origin: string;
  destination: string;
  busNumber: string;
  startDay: string;
  endDay: string;
  serviceStatus: string;
}

interface Employee {
  id: string;
  user_id: string;
  employee_id: string;
  photo_url: string;
  employee_name: string;
  designation: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  license_number: string;
  license_expiry_date: string;
  pan_number: string;
  pan_url: string;
  document_upload: {
    license_doc: string;
  };
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: string;
}

interface SimplifiedEmployee {
  id: string;
  employee_id: string;
  designation: string;
  employee_name: string;
}

const UpdateTrip = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams<{ id: string }>(); // Explicit type for useParams
  const navigate = useNavigate();
  const { isSuccess, trip } = useSelector((state: RootState) => state.trips);
  const { allServices } = useSelector((state: RootState) => state.services);
  const { allEmployees ,allDesignation} = useSelector((state: RootState) => state.employees);

  // Initialize state for selected service and employees
  const [selectedService, setSelectedService] = useState<ServiceData | null>(null);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Set<string>>(new Set());
  const [validationError, setValidationError] = useState<string | null>(null);
  const [formData, setFormData] = useState<TripData>({
    serviceId: "",
    serviceName: "",
    routeId: "",
    origin: "",
    destination: "",
    busNumber: "",
    startDay: "",
    endDay: "",
    serviceStatus: "",
  });

  // Load trip details and set form data
  useEffect(() => {
    if (trip.tripDetails) {
      const { serviceId, serviceName, routeId, origin, destination, busNumber, startDay, endDay, serviceStatus } = trip.tripDetails;
      setFormData({
        serviceId,
        serviceName,
        routeId,
        origin,
        destination,
        busNumber,
        startDay,
        endDay,
        serviceStatus,
      });
      const stopObj = allServices.find((service: ServiceData) => service.service_id === serviceId);
      if (stopObj) {
        setSelectedService(stopObj);
      }
    }
    if (trip.dutyDetails) {
      const dutyEmployees = trip.dutyDetails.employeeDetails.map((emp: any) => ({
        id: emp.id,
        employee_id: emp.employee_id,
        designation: emp.designation,
        employee_name: emp.employee_name,
      }));
      setSelectedEmployeeIds(new Set(dutyEmployees.map((emp: SimplifiedEmployee) => emp.id)));
    }
  }, [trip, allServices]);

  // Dispatch actions and update heading
  useEffect(() => {
    dispatch(changeHeading({
      heading: "Update Trip",
      isBackBtn: true,
      breadcrumbItems: [
        { title: "Ksm", link: "#" },
        { title: "Trip", link: "#" },
        { title: `${params.id}`, link: "#" },
      ],
    }));
    dispatch(getAllServices());
    dispatch(getUserData());
    dispatch(gettripDetail(params.id));
    dispatch(getAllEmployees());
    dispatch(getAllDesignation());
  }, [dispatch, params.id]);
  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };
  // Navigate on successful update
  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.trip);
    }
  }, [isSuccess, navigate]);

  // Handle service selection
  const handleServiceSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedServiceName = event.target.value;
    const stopObj = allServices.find((service: ServiceData) => service.service_id === selectedServiceName);
    if (stopObj) {
      setSelectedService(stopObj);
    }
  };

  // Handle employee selection
  const handleEmployeeSelection = (employeeId: string, isChecked: boolean) => {
    setSelectedEmployeeIds((prevSelectedEmployeeIds) => {
      const updatedSelectedEmployeeIds = new Set(prevSelectedEmployeeIds);
      if (isChecked) {
        updatedSelectedEmployeeIds.add(employeeId);
      } else {
        updatedSelectedEmployeeIds.delete(employeeId);
      }
      return updatedSelectedEmployeeIds;
    });
  };

  // Submit form data
  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const selectedEmployees = simplifiedArray.filter((employee) =>
        selectedEmployeeIds.has(employee.id)
      );

      if (selectedEmployees.length === 0) {
        setValidationError("Please select at least one employee.");
        return;
      } else {
        setValidationError(null);
      }

      const formattedData = {
        ...values,
        bus_number: selectedService?.bus_number,
        route_number: selectedService?.route_number,
        service_id: selectedService?.service_id,
        id: params.id,
        employee_details: selectedEmployees,
        created_by: 1,
        updated_by: 1,
      };
      dispatch(updatetrip(formattedData));
      console.log(formattedData);
    }
  };

  // Simplify employee data for Autocomplete
  const simplifyEmployees = (employees: Employee[]): SimplifiedEmployee[] => {
    return employees.map((employee) => ({
      id: employee.id,
      employee_id: employee.employee_id,
      designation: employee.designation,
      employee_name: employee.employee_name,
    }));
  };

  const simplifiedArray: SimplifiedEmployee[] = simplifyEmployees(allEmployees);

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Update Trip</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="serviceSelect">Service</Label>
                  <AvField
                    name="service_id"
                    type="select"
                    errorMessage="Please select service."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="serviceSelect"
                    onChange={handleServiceSelect}
                    value={formData.serviceId}
                  >
                    <option value="">Select Service</option>
                    {allServices.map((serviceObj: SelectProp) => (
                      <option
                        key={serviceObj.service_id}
                        value={serviceObj.service_id}
                      >
                        {serviceObj.service_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="BusNumber">Bus Number</Label>
                  <AvField
                    name="bus_number"
                    placeholder="Bus Number"
                    type="text"
                    errorMessage="Bus number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="BusNumber"
                    value={selectedService?.bus_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="routeNumber">Route Number</Label>
                  <AvField
                    name="route_number"
                    placeholder="Route Number"
                    type="text"
                    errorMessage="Route number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeNumber"
                    value={selectedService?.route_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate1">Start Date</Label>
                  <AvField
                    name="start_date"
                    placeholder="Start Date"
                    type="date"
                    errorMessage="Please provide start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate1"
                    value={formData.startDay}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="endDate1">End Date</Label>
                  <AvField
                    name="end_date"
                    placeholder="End Date"
                    type="date"
                    errorMessage="Please provide end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="endDate1"
                    value={formData.endDay}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="origin">Origin</Label>
                  <AvField
                    name="origin"
                    placeholder="Origin"
                    type="text"
                    errorMessage="Please provide origin."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="origin"
                    value={formData.origin}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="destination">Destination</Label>
                  <AvField
                    name="destination"
                    placeholder="Destination"
                    type="text"
                    errorMessage="Please provide destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination"
                    value={formData.destination}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="serviceStatus">Service Status</Label>
                  <AvField
                    name="status"
                    placeholder="Service Status"
                    type="text"
                    errorMessage="Please provide service status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="serviceStatus"
                    value={formData.serviceStatus}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employeeDetails">Employee Details</Label>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {simplifiedArray.map((employee: SimplifiedEmployee) => (
                        <tr key={employee.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedEmployeeIds.has(employee.id)}
                              onChange={(e) =>
                                handleEmployeeSelection(employee.id, e.target.checked)
                              }
                            />
                          </td>
                          <td>{employee.employee_name}</td>
                          <td className="text-capitalize">{getDesignationName(employee.designation)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {validationError && (
                    <FormFeedback style={{ display: "block" }}>
                      {validationError}
                    </FormFeedback>
                  )}
                </div>
              </Col>
              <div className="d-flex justify-content-end">
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default UpdateTrip;

import React, { useEffect, useState } from "react";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../styledPayroll";
import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllDesignation, getAllEmployees } from "../../../../features/employeesSlice";
import { RootState } from "../../../../app/Store";
import { addNewpayroll, getPayrollDetail, updatePayroll } from "../../../../features/payrollSlice";
import { useNavigate, useParams } from "react-router-dom";
import { routerPath } from "../../../../routes/routes";
import { getAllPayrollComponents } from "../../../../features/payrollComponentSlice";
import { toast } from "react-toastify";

// Define the types for your form values and selected components
interface FormValues {
  emp_id: string;
  designation_id: string;
  bank_account_number: string;
  account_name: string;
  branch: string;
  ifsc: string;
  total_salary: string;
  status: string;
}

interface PayrollComponent {
  id: string;
  component_name: string;
}

const UpdatePayroll = () => {
  document.title = "Update Payroll | KSM - Admin Dashboard.";
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allEmployees, allDesignation } = useSelector((state: RootState) => state.employees);
  const { allPayrollComponents } = useSelector((state: RootState) => state.payrollComponents);
  const { isSuccess, payroll } = useSelector((state: RootState) => state.payroll);
  const [totalSalary, setTotalSalary] = useState<number>(0);
  const { loggedInUser } = useSelector((state: RootState) => state.user);
  const [designationId,setDesignationId]=useState<any>("");
  
  const [selectedComponents, setSelectedComponents] = useState<Record<string, boolean>>({});
  const [amounts, setAmounts] = useState<Record<string, number>>({});
  const [formValues, setFormValues] = useState<FormValues>({
    emp_id: '',
    designation_id: '',
    bank_account_number: '',
    account_name: '',
    branch: '',
    ifsc: '',
    total_salary: '',
    status: ''
  });

  useEffect(() => {
    dispatch(changeHeading({
      heading: "Update Payroll",
      isBackBtn: true,
      breadcrumbItems: [
        { title: "KSM", link: "#" },
        { title: "Payroll", link: "#" },
        { title: `${params.id}`, link: "#" },
      ],
    }));
    dispatch(getAllEmployees());
    dispatch(getAllDesignation());
    dispatch(getAllPayrollComponents());
    dispatch(getPayrollDetail(params.id));
  }, [dispatch, params.id]);
  useEffect(() => {
    if (isSuccess) {
      navigate(routerPath.payrollList);
    }
  }, [isSuccess, navigate]);
  useEffect(() => {
    // Recalculate total salary whenever amounts change
    const newTotalSalary = Object.keys(amounts).reduce((sum, id) => sum + amounts[id], 0);
    setTotalSalary(newTotalSalary);
  }, [amounts]);
  useEffect(() => {
    if (payroll) {
      // Populate form values
      setFormValues({
        emp_id: payroll.emp_id || '',
        designation_id: payroll.designation_id || '',
        bank_account_number: payroll.bank_account_number || '',
        account_name: payroll.account_name || '',
        branch: payroll.branch || '',
        ifsc: payroll.ifsc || '',
        total_salary: payroll.total_salary || '',
        status: payroll.status || ''
      });
      setDesignationId(payroll.designation_id);

      // Initialize selected components and amounts
      const selectedComponentsState: Record<string, boolean> = {};
      const amountsState: Record<string, number> = {};

      // Check if pay_components is defined before using forEach
      if (payroll.pay_components && Array.isArray(payroll.pay_components)) {
        payroll.pay_components.forEach((component: { id: number, [key: string]: any }) => {
          const componentId = component.id.toString();
          selectedComponentsState[componentId] = true;
          amountsState[componentId] = Object.values(component)[1]; // Assumes the amount is the second value
        });
      }

      setSelectedComponents(selectedComponentsState);
      setAmounts(amountsState);
    }
  }, [payroll]);

  const handleCheckboxChange = (id: string) => {
    setSelectedComponents(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
    if (selectedComponents[id]) {
      setAmounts(prevAmounts => {
        const { [id]: _, ...rest } = prevAmounts;
        return rest;
      });
    }
  };
  const handleSelectEmployee = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let selectedID = event.target.value;
    const Employee = allEmployees.find((emp: any) => emp.id === selectedID);
    // console.log(Employee.designation);
    setDesignationId(Employee.designation);
  };
  const handleAmountChange = (id: string, value: number) => {
    setAmounts(prevAmounts => ({ ...prevAmounts, [id]: value }));
  };

  const handleSubmit = (event: React.FormEvent, errors: any[], values: FormValues) => {
    event.preventDefault(); // Prevent default form submission

    // Check if at least one component is selected
    const hasSelectedComponents = Object.keys(selectedComponents).some(id => selectedComponents[id]);

    if (errors.length === 0 && hasSelectedComponents) {
      // Filter and transform selected components to match the desired format
      const payComponents = Object.keys(selectedComponents)
        .filter(id => selectedComponents[id] && amounts[id])
        .map(id => {
          const component = allPayrollComponents.find((comp: PayrollComponent) => comp.id === id);
          return {
            id: Number(id),
            [component?.component_name || '']: Number(amounts[id]),
          };
        });

      // Create payloadData with proper structure
      const payloadData = {
        id: params.id,
        emp_id: Number(values.emp_id),
        designation_id: Number(values.designation_id),
        pay_components: payComponents,
        bank_account_number: values.bank_account_number,
        ifsc: values.ifsc,
        account_name: values.account_name,
        branch: values.branch,
        // total_salary: Number(values.total_salary),
        total_salary: totalSalary, // Use computed totalSalary

        status: values.status,
        // created_by: 1,
        updated_by: loggedInUser?.username,
      };

      console.log(payloadData);

      // Dispatch action to add new payroll
      dispatch(updatePayroll(payloadData));
    } else if (!hasSelectedComponents) {
      // Show an error message if no components are selected
      toast.warning("Please select at least one pay component.");
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Update Payroll</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="emp_id">Employee</Label>
                  <AvField
                    name="emp_id"
                    type="select"
                    value={formValues.emp_id}
                    errorMessage="Please select an employee."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="emp_id"
                    onChange={handleSelectEmployee}

                  >
                    <option value="">Select Employee</option>
                    {allEmployees.map((employee: any) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.employee_id} - {employee.employee_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="designation_id">Employee Designation</Label>
                  <AvField
                    name="designation_id"
                    type="select"
                    errorMessage="Please select employee first it will auto populate."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="designation_id"
                    value={designationId}
                    disabled
                  >
                    <option value="">Select Employee Role</option>
                    {allDesignation.map((designation: any) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.id} - {designation.designation_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="bank_account_number">Bank Account Number</Label>
                  <AvField
                    name="bank_account_number"
                    type="number"
                    placeholder="Enter Bank Account Number"
                    value={formValues.bank_account_number}
                    errorMessage="Please enter bank account number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="bank_account_number"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="account_name">Account Holder Name</Label>
                  <AvField
                    name="account_name"
                    type="text"
                    placeholder="Enter account holder name"
                    value={formValues.account_name}
                    errorMessage="Please enter account holder name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="account_name"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="branch">Branch</Label>
                  <AvField
                    name="branch"
                    type="text"
                    placeholder="Enter branch name"
                    value={formValues.branch}
                    errorMessage="Please enter branch name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="branch"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="ifsc">IFSC</Label>
                  <AvField
                    name="ifsc"
                    type="text"
                    placeholder="Enter IFSC code"
                    value={formValues.ifsc}
                    errorMessage="Please enter IFSC code."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="ifsc"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="total_salary">Total Salary</Label>
                  <AvField
                    name="total_salary"
                    type="number"
                    value={totalSalary}

                    placeholder="Enter total salary"
                    // value={formValues.total_salary}
                    errorMessage="Please enter total salary."
                    className="form-control"
                    // validate={{ required: { value: true } }}
                    id="total_salary"
                    readOnly

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    value={formValues.status}
                    errorMessage="Please select a status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="pay_components">Pay Components</Label>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>ID</th>
                        <th>Component Name</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPayrollComponents.map((component: PayrollComponent) => {
                        const isSelected = !!selectedComponents[component.id];
                        return (
                          <tr key={component.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => handleCheckboxChange(component.id)}
                              />
                            </td>
                            <td>{component.id}</td>
                            <td>{component.component_name}</td>
                            <td>
                              {isSelected ? (
                                <AvField
                                  name={`amount_${component.id}`}
                                  type="number"
                                  placeholder="Enter amount"
                                  value={amounts[component.id] || ''}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleAmountChange(component.id, +e.target.value)
                                  }
                                  validate={{ required: { value: true, errorMessage: "Amount is required." } }}
                                />
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default UpdatePayroll;

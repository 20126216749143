import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allPayrollRuns: any;
  payrollRuns: any;
  isSuccess: boolean;
  weeklyReport: any;
}

const initialState: initialStateProp = {
  allPayrollRuns: [],
  payrollRuns: {},
  isSuccess: false,
  weeklyReport: {},
};

interface allPayrollRunsProp {
  allPayrollRuns: any;
}
interface payrollRunsProp {
  payrollRuns: any;
}
interface payrollRunsStateProp {
  isSuccess: boolean;
}
interface Employee {
  employee_id: number;
  designation_id: number;
}

interface addpayrollRunsPayload {
  start_date: string;
  end_date: string;
  employees: Employee[];
  status: string;
  created_by: number;
  updated_by: number;
}

interface updatepayrollRunsPayload {
  id: any;
  start_date: string;
  end_date: string;
  employees: Employee[];
  status: string;
  created_by: number;
  updated_by: number;
}
interface WeeklyReportProp {
  weeklyReport: any;
}
export const getAllPayrollRuns = createAsyncThunk(
  "get all payrollRunss", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allPayruns, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      const data = await response.json();
      if (response.status === 200) {
        // toast.success("payrollRuns Data Fetch Successfully!.");
        // console.log(data);
        dispatch(
          setAllPayrollRuns({
            allPayrollRuns: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        handle500Status();
      } else if (response.status === 404 && data.status === 404) {
        toast.error("No payruns found.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewpayRuns = createAsyncThunk(
  "add new payrollRuns",
  async (payload: addpayrollRunsPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      console.log(JSON.stringify(payload));

      const response = await fetch(endpoints.addPayrun, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success("Payruns Added Successfully!.");
        // console.log(data);
        dispatch(
          setPayrollRunsState({
            isSuccess: true,
          })
        );
        dispatch(getAllPayrollRuns());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new payrollRuns:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updatepayrollRuns = createAsyncThunk(
  "update payrollRuns",
  async (payload: updatepayrollRunsPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      console.log(payload);
      const response = await fetch(`${endpoints.editPayrun}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Payruns updated Successfully!.");
        // console.log(data);
        dispatch(
          setPayrollRunsState({
            isSuccess: true,
          })
        );
        dispatch(getAllPayrollRuns());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new payrollRuns:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deletepayRunsHandler = createAsyncThunk(
  "delete payrollRuns from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      console.log(payload);
      const response = await fetch(`${endpoints.deletePayrun}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllPayrollRuns());
        // console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getpayrollRunsDetail = createAsyncThunk(
  "get payrollRuns details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewPayrun}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setPayrollRunsDetails({
            payrollRuns: data,
          })
        );
        toast.success("Payruns Details Fetch successfully!.");
        console.log(data);
      }

      if (data.code === 404) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const filterPayRunByWeek = createAsyncThunk(
  "get payrollRuns details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      dispatch(
        setPayrollRunsState({
          isSuccess: false,
        })
      );
      const response = await fetch(`${endpoints.reportByWeek}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      console.log(response);
      if (response.status === 200 || response.status === 200) {
        toast.success("Downloading Report...");

        dispatch(
          setWeeklyReport({
            weeklyReport: data,
          })
        );
        console.log(data);
        setTimeout(() => {
          dispatch(
            setPayrollRunsState({
              isSuccess: true,
            })
          );
        }, 2000);
      }

      if (response.status === 404 && data.status === 404) {
        toast.error(data.messages.error);
      }
      if (response.status === 500) {
        toast.error(response.statusText);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const payrollRunsSlice = createSlice({
  name: "payrollRuns slice",
  initialState: initialState,
  reducers: {
    setAllPayrollRuns: (state, action: PayloadAction<allPayrollRunsProp>) => {
      return {
        ...state,
        allPayrollRuns: action.payload.allPayrollRuns,
      };
    },
    setPayrollRunsDetails: (state, action: PayloadAction<payrollRunsProp>) => {
      return {
        ...state,
        payrollRuns: action.payload.payrollRuns,
      };
    },
    setWeeklyReport: (state, action: PayloadAction<WeeklyReportProp>) => {
      return {
        ...state,
        weeklyReport: action.payload.weeklyReport,
      };
    },
    setPayrollRunsState: (
      state,
      action: PayloadAction<payrollRunsStateProp>
    ) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetPayrollRunsState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllPayrollRuns,
  setPayrollRunsState,
  resetPayrollRunsState,
  setPayrollRunsDetails,
  setWeeklyReport,
} = payrollRunsSlice.actions;
export default payrollRunsSlice.reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import PayrollComponentTable from "../../Tables/PayrolTable/PayrollComponentTable";
import { getAllPayrollComponents, resetPayrollComponentState } from "../../../features/payrollComponentSlice";
import { RootState } from "../../../app/Store";

const data = [
  {
    sr_no: 1,
    component_name: "Medical Allownce",
    component_type: "Variable",
    component_desc: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit nostrum placeat nobis, error doloremque molestias temporibus rem, eius labore, eos aliquid. Maxime ipsam mollitia, accusantium iste est inventore alias iusto?",
    pay_freq: "Monthly",
  },
];

const ComponentList = () => {
  document.title = "Component List | KSM - Admin Dashboard.";
const {allPayrollComponents} =useSelector((state:RootState)=>state.payrollComponents)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Component List",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Components", link: "#" },
          { title: "List", link: "#" },
        ],
      })
    );
    dispatch(getAllPayrollComponents());
    dispatch(resetPayrollComponentState());

  }, []);
  console.log(allPayrollComponents)
  const columnData=[
    {
      label: 'Sr.No',
      field: 'id',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Component type',
      field: 'component_type',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Component name',
      field: 'component_name',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Component Description',
      field: 'component_description',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Pay frequency',
      field: 'pay_frequency',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
      width: 150
    },
]
  return <div>
      <PayrollComponentTable columns={columnData} rows={allPayrollComponents}/>
    
  </div>;
};

export default ComponentList;

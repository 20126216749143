// Import necessary React and Redux dependencies
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { addNewUser, addNewUserRole } from "../../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Define the AddUser component
const AddUserRole: React.FC = () => {
  document.title = "Add User Role | Ksm Admin Dashboard.";

  // Initialize useDispatch and useNavigate hooks
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess } = useSelector((state: RootState) => state.user);
  const [allPermissionsUser, setAllPermissionsUser] = useState(false);
  const [allPermissionsUserRole, setAllPermissionsUserRole] = useState(false);
  const [allPermissionsBus, setAllPermissionsBus] = useState(false);
  const [allPermissionsRoute, setAllPermissionsRoute] = useState(false);
  const [allPermissionsService, setAllPermissionsService] = useState(false);
  const [allPermissionsTrip, setAllPermissionsTrip] = useState(false);
 
  const [allPermissionsStop, setAllPermissionsStop] = useState(false);
  const [allPermissionsEmployee, setAllPermissionsEmployee] = useState(false);
  const [allPermissionsDesignation, setAllPermissionsDesignation] = useState(false);
  const [allPermissionsPayroll, setAllPermissionsPayroll] = useState(false);
  const [allPermissionsPayrun, setAllPermissionsPayrun] = useState(false);
  const [allPermissionsPayrollComponent, setAllPermissionsPayrollComponent] = useState(false);
    

  // Function to handle changing the "All Permissions" checkbox
  const handleUserAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsUser(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleUserRoleAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsUserRole(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
 
  const handleBusAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsBus(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleRouteAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsRoute(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleServiceAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsService(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleTripAllPermissionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsTrip(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };

 
  const handleStopAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsStop(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleEmployeeAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsEmployee(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handleDesignationAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsDesignation(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handlePayrollAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsPayroll(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handlePayrollComponentAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsPayrollComponent(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  const handlePayrunAllPermissionsChange= (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllPermissionsPayrun(isChecked);
    const checkboxGroupName = event.target.name;
    const checkboxes = document.querySelectorAll<HTMLInputElement>(
      `input[name="${checkboxGroupName}"]`
    );
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  };
  // useEffect to dispatch the changeHeading action when the component mounts
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add User Role",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Users", link: "#" },
          { title: "Add User Role", link: "#" },
        ],
      })
    );
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.user_role);
    }
  }, [isSuccess]);

  // Function to handle form submission
  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // Extract the selected permissions from the checkboxes
      const selectedPermissions = {
        users: {
          view_list: event.target.querySelector(
            'input[name="user_permissions"][value="view_user_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="user_permissions"][value="view_user"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="user_permissions"][value="add_user"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="user_permissions"][value="edit_user"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="user_permissions"][value="delete_user"]'
          ).checked,
        },
        userrole: {
          view_list: event.target.querySelector(
            'input[name="userrole_permissions"][value="view_user_role_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="userrole_permissions"][value="view_user_role"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="userrole_permissions"][value="add_user_role"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="userrole_permissions"][value="edit_user_role"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="userrole_permissions"][value="delete_user_role"]'
          ).checked,
        },
        
        buses: {
          view_list: event.target.querySelector(
            'input[name="bus_permissions"][value="view_bus_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="bus_permissions"][value="view_bus"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="bus_permissions"][value="add_bus"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="bus_permissions"][value="edit_bus"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="bus_permissions"][value="delete_bus"]'
          ).checked,
        },
        routes: {
          view_list: event.target.querySelector(
            'input[name="route_permissions"][value="view_route_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="route_permissions"][value="view_route"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="route_permissions"][value="add_route"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="route_permissions"][value="edit_route"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="route_permissions"][value="delete_route"]'
          ).checked,
        },
        services: {
          view_list: event.target.querySelector(
            'input[name="service_permissions"][value="view_service_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="service_permissions"][value="view_service"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="service_permissions"][value="add_service"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="service_permissions"][value="edit_service"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="service_permissions"][value="delete_service"]'
          ).checked,
        },
        trips: {
          view_list: event.target.querySelector(
            'input[name="trip_permissions"][value="view_trip_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="trip_permissions"][value="view_trip"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="trip_permissions"][value="add_trip"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="trip_permissions"][value="edit_trip"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="trip_permissions"][value="delete_trip"]'
          ).checked,
        },
        
        stops: {
          view_list: event.target.querySelector(
            'input[name="stop_permissions"][value="view_stop_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="stop_permissions"][value="view_stop"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="stop_permissions"][value="add_stop"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="stop_permissions"][value="edit_stop"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="stop_permissions"][value="delete_stop"]'
          ).checked,
        },
        employees: {
          view_list: event.target.querySelector(
            'input[name="employee_permissions"][value="view_employee_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="employee_permissions"][value="view_employee"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="employee_permissions"][value="add_employee"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="employee_permissions"][value="edit_employee"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="employee_permissions"][value="delete_employee"]'
          ).checked,
        },
        designations: {
          view_list: event.target.querySelector(
            'input[name="designation_permissions"][value="view_designation_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="designation_permissions"][value="view_designation"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="designation_permissions"][value="add_designation"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="designation_permissions"][value="edit_designation"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="designation_permissions"][value="delete_designation"]'
          ).checked,
        },
        payrolls: {
          view_list: event.target.querySelector(
            'input[name="payroll_permissions"][value="view_payroll_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="payroll_permissions"][value="view_payroll"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="payroll_permissions"][value="add_payroll"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="payroll_permissions"][value="edit_payroll"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="payroll_permissions"][value="delete_payroll"]'
          ).checked,
        },
        payruns: {
          view_list: event.target.querySelector(
            'input[name="payrun_permissions"][value="view_payrun_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="payrun_permissions"][value="view_payrun"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="payrun_permissions"][value="add_payrun"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="payrun_permissions"][value="edit_payrun"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="payrun_permissions"][value="delete_payrun"]'
          ).checked,
        },
        payrollComponents: {
          view_list: event.target.querySelector(
            'input[name="payrollComponent_permissions"][value="view_payrollComponent_list"]'
          ).checked,
          view: event.target.querySelector(
            'input[name="payrollComponent_permissions"][value="view_payrollComponent"]'
          ).checked,
          add: event.target.querySelector(
            'input[name="payrollComponent_permissions"][value="add_payrollComponent"]'
          ).checked,
          edit: event.target.querySelector(
            'input[name="payrollComponent_permissions"][value="edit_payrollComponent"]'
          ).checked,
          delete: event.target.querySelector(
            'input[name="payrollComponent_permissions"][value="delete_payrollComponent"]'
          ).checked,
        },
      };

      // Add the permissions to the values object
      values.permissions = selectedPermissions;

      // Dispatch the addNewUser action with the updated form values
      dispatch(addNewUserRole(values));
      console.log(values);

      // Example: Navigate to a different page after successful form submission
      // navigate(routerPath.users);
    }
  };

  // Return the JSX structure for the AddUser component

  const user = (
    <div>
      <div className="divider">
        <span>users</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox"
            name="user_permissions" // Change the group name accordingly
            onChange={handleUserAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsUser} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="alluCheckbox"
            name="user_permissions"
            value="view_user_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="alluCheckbox"
          >
            View user list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vuCheckbox"
            name="user_permissions"
            value="view_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="vuCheckbox"
          >
            View user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="aduCheckbox"
            name="user_permissions"
            value="add_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="aduCheckbox"
          >
            Add user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="eduCheckbox"
            name="user_permissions"
            value="edit_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="eduCheckbox"
          >
            Edit user
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="deluCheckbox"
            name="user_permissions"
            value="delete_user"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="deluCheckbox"
          >
            Delete user
          </Label>
        </div>
      </div>
    </div>
  );

  const userRole = (
    <div>
      <div className="divider">
        <span>users roles</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckbox1"
            name="userrole_permissions" // Change the group name accordingly
            onChange={handleUserRoleAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsUserRole} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckbox1">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vurlCheckbox"
            name="userrole_permissions"
            value="view_user_role_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="vurlCheckbox"
          >
            View user role list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="vurCheckbox"
            name="userrole_permissions"
            value="view_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="vurCheckbox"
          >
            View user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addurCheckbox"
            name="userrole_permissions"
            value="add_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addurCheckbox"
          >
            Add user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="edurCheckbox"
            name="userrole_permissions"
            value="edit_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="edurCheckbox"
          >
            Edit user role
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delurCheckbox"
            name="userrole_permissions"
            value="delete_user_role"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delurCheckbox"
          >
            Delete user role
          </Label>
        </div>
      </div>
    </div>
  );
  
  const bus = (
    <div>
      <div className="divider">
        <span>Bus</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxbus"
            name="bus_permissions" // Change the group name accordingly
            onChange={handleBusAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsBus} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxbus">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewbuslCheckbox"
            name="bus_permissions"
            value="view_bus_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewbuslCheckbox"
          >
            View bus list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewbusCheckbox"
            name="bus_permissions"
            value="view_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewbusCheckbox"
          >
            View bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addbusCheckbox"
            name="bus_permissions"
            value="add_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addbusCheckbox"
          >
            Add bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editbusCheckbox"
            name="bus_permissions"
            value="edit_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editbusCheckbox"
          >
            Edit bus
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delbusCheckbox"
            name="bus_permissions"
            value="delete_bus"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delbusCheckbox"
          >
            Delete bus
          </Label>
        </div>
      </div>
    </div>
  );

  const route  = (
    <div>
      <div className="divider">
        <span>Route</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxroute"
            name="route_permissions" // Change the group name accordingly
            onChange={handleRouteAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsRoute} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxroute">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewroutelCheckbox"
            name="route_permissions"
            value="view_route_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewroutelCheckbox"
          >
            View route list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewrouteCheckbox"
            name="route_permissions"
            value="view_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewrouteCheckbox"
          >
            View route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addrouteCheckbox"
            name="route_permissions"
            value="add_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addrouteCheckbox"
          >
            Add route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editrouteCheckbox"
            name="route_permissions"
            value="edit_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editrouteCheckbox"
          >
            Edit route
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delrouteCheckbox"
            name="route_permissions"
            value="delete_route"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delrouteCheckbox"
          >
            Delete route
          </Label>
        </div>
      </div>
    </div>
  );

  const service  = (
    <div>
      <div className="divider">
        <span>Service</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxservice"
            name="service_permissions" // Change the group name accordingly
            onChange={handleServiceAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsService} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxservice">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewservicelCheckbox"
            name="service_permissions"
            value="view_service_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewservicelCheckbox"
          >
            View service list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewserviceCheckbox"
            name="service_permissions"
            value="view_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewserviceCheckbox"
          >
            View service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addserviceCheckbox"
            name="service_permissions"
            value="add_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addserviceCheckbox"
          >
            Add service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editserviceCheckbox"
            name="service_permissions"
            value="edit_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editserviceCheckbox"
          >
            Edit service
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delserviceCheckbox"
            name="service_permissions"
            value="delete_service"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delserviceCheckbox"
          >
            Delete service
          </Label>
        </div>
      </div>
    </div>
  );
  

  const trip  = (
    <div>
      <div className="divider">
        <span>Trip</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxtrip"
            name="trip_permissions" // Change the group name accordingly
            onChange={handleTripAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsTrip} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxtrip">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewtriplCheckbox"
            name="trip_permissions"
            value="view_trip_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewtriplCheckbox"
          >
            View trip list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewtripCheckbox"
            name="trip_permissions"
            value="view_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewtripCheckbox"
          >
            View trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addtripCheckbox"
            name="trip_permissions"
            value="add_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addtripCheckbox"
          >
            Add trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="edittripCheckbox"
            name="trip_permissions"
            value="edit_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="edittripCheckbox"
          >
            Edit trip
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="deltripCheckbox"
            name="trip_permissions"
            value="delete_trip"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="deltripCheckbox"
          >
            Delete trip
          </Label>
        </div>
      </div>
    </div>
  );

 

  const stop  = (
    <div>
      <div className="divider">
        <span>stops</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxstop"
            name="stop_permissions" // Change the group name accordingly
            onChange={handleStopAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsStop} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxstop">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewstoplCheckbox"
            name="stop_permissions"
            value="view_stop_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewstoplCheckbox"
          >
            View stop list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewstopCheckbox"
            name="stop_permissions"
            value="view_stop"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewstopCheckbox"
          >
            View stop
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addstopCheckbox"
            name="stop_permissions"
            value="add_stop"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addstopCheckbox"
          >
            Add stop
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editstopCheckbox"
            name="stop_permissions"
            value="edit_stop"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editstopCheckbox"
          >
            Edit stop
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delstopCheckbox"
            name="stop_permissions"
            value="delete_stop"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delstopCheckbox"
          >
            Delete stop
          </Label>
        </div>
      </div>
    </div>
  );

  const employee  = (
    <div>
      <div className="divider">
        <span>Employees</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxemp"
            name="employee_permissions" // Change the group name accordingly
            onChange={handleEmployeeAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsEmployee} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxemp">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewemployeelCheckbox"
            name="employee_permissions"
            value="view_employee_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewemployeelCheckbox"
          >
            View employee list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewemployeeCheckbox"
            name="employee_permissions"
            value="view_employee"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewemployeeCheckbox"
          >
            View employee
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addemployeeCheckbox"
            name="employee_permissions"
            value="add_employee"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addemployeeCheckbox"
          >
            Add employee
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editemployeeCheckbox"
            name="employee_permissions"
            value="edit_employee"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editemployeeCheckbox"
          >
            Edit employee
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delemployeeCheckbox"
            name="employee_permissions"
            value="delete_employee"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delemployeeCheckbox"
          >
            Delete employee
          </Label>
        </div>
      </div>
    </div>
  );

  const designation  = (
    <div>
      <div className="divider">
        <span>Designations</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxemp"
            name="designation_permissions" // Change the group name accordingly
            onChange={handleDesignationAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsDesignation} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxemp">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewdesignationlCheckbox"
            name="designation_permissions"
            value="view_designation_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewdesignationlCheckbox"
          >
            View designation list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewdesignationCheckbox"
            name="designation_permissions"
            value="view_designation"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewdesignationCheckbox"
          >
            View designation
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="adddesignationCheckbox"
            name="designation_permissions"
            value="add_designation"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="adddesignationCheckbox"
          >
            Add designation
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editdesignationCheckbox"
            name="designation_permissions"
            value="edit_designation"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editdesignationCheckbox"
          >
            Edit designation
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="deldesignationCheckbox"
            name="designation_permissions"
            value="delete_designation"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="deldesignationCheckbox"
          >
            Delete designation
          </Label>
        </div>
      </div>
    </div>
  );

  const payroll  = (
    <div>
      <div className="divider">
        <span>payrolls</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxpayroll"
            name="payroll_permissions" // Change the group name accordingly
            onChange={handlePayrollAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsPayroll} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxpayroll">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrolllCheckbox"
            name="payroll_permissions"
            value="view_payroll_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrolllCheckbox"
          >
            View payroll list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrollCheckbox"
            name="payroll_permissions"
            value="view_payroll"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrollCheckbox"
          >
            View payroll
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addpayrollCheckbox"
            name="payroll_permissions"
            value="add_payroll"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addpayrollCheckbox"
          >
            Add payroll
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editpayrollCheckbox"
            name="payroll_permissions"
            value="edit_payroll"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editpayrollCheckbox"
          >
            Edit payroll
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delpayrollCheckbox"
            name="payroll_permissions"
            value="delete_payroll"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delpayrollCheckbox"
          >
            Delete payroll
          </Label>
        </div>
      </div>
    </div>
  );
  const payrollComponent  = (
    <div>
      <div className="divider">
        <span>payrollComponents</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxpayrollComponent"
            name="payrollComponent_permissions" // Change the group name accordingly
            onChange={handlePayrollComponentAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsPayrollComponent} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxpayrollComponent">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrollComponentlCheckbox"
            name="payrollComponent_permissions"
            value="view_payrollComponent_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrollComponentlCheckbox"
          >
            View payrollComponent list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrollComponentCheckbox"
            name="payrollComponent_permissions"
            value="view_payrollComponent"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrollComponentCheckbox"
          >
            View payrollComponent
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addpayrollComponentCheckbox"
            name="payrollComponent_permissions"
            value="add_payrollComponent"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addpayrollComponentCheckbox"
          >
            Add payrollComponent
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editpayrollComponentCheckbox"
            name="payrollComponent_permissions"
            value="edit_payrollComponent"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editpayrollComponentCheckbox"
          >
            Edit payrollComponent
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delpayrollComponentCheckbox"
            name="payrollComponent_permissions"
            value="delete_payrollComponent"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delpayrollComponentCheckbox"
          >
            Delete payrollComponent
          </Label>
        </div>
      </div>
    </div>
  );
  const payrun  = (
    <div>
      <div className="divider">
        <span>Payruns</span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between flex-wrap row"
        style={{ margin: "30px 20px 30px 20px" }}
      >
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input"
            id="allPermissionsCheckboxpayrun"
            name="payrun_permissions" // Change the group name accordingly
            onChange={handlePayrunAllPermissionsChange} // Attach onChange event handler
            checked={allPermissionsPayrun} // Controlled component
            style={{ transform: "scale(1.5)" }}
          />
          <label className="form-check-label" htmlFor="allPermissionsCheckboxpayrun">
            All Permissions
          </label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrunlCheckbox"
            name="payrun_permissions"
            value="view_payrun_list"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label"
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrunlCheckbox"
          >
            View payrun list
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="viewpayrunCheckbox"
            name="payrun_permissions"
            value="view_payrun"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="viewpayrunCheckbox"
          >
            View payrun
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="addpayrunCheckbox"
            name="payrun_permissions"
            value="add_payrun"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="addpayrunCheckbox"
          >
            Add payrun
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="editpayrunCheckbox"
            name="payrun_permissions"
            value="edit_payrun"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="editpayrunCheckbox"
          >
            Edit payrun
          </Label>
        </div>
        <div className="form-check col-md-2">
          <input
            type="checkbox"
            className="form-check-input "
            id="delpayrunCheckbox"
            name="payrun_permissions"
            value="delete_payrun"
            style={{ transform: "scale(1.5)" }}
          />
          <Label
            className="form-check-label "
            style={{ marginLeft: "5px" }}
            htmlFor="delpayrunCheckbox"
          >
            Delete payrun
          </Label>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Add User Role</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">User Role Name</Label>
                  <AvField
                    name="role_name"
                    placeholder="User role name"
                    type="text"
                    errorMessage=" Enter User Role Name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom03"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Permissions(Check the applicable permissions).</Label>
                  {user}
                  {userRole}
                  {bus}
                  {route}
                  {service}
                  {stop}
                  {trip}
                  {employee}
                  {designation}
                  {payroll}
                  {payrollComponent}
                  {payrun}


                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
      <br />
    </>
  );
};

// Export the AddUser component
export default AddUserRole;

import React, { useEffect } from "react";
import "./App.css";
import AppRoute from "./routes/routes";
import AppWrapper from "./containers/AppWrapper/AppWrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./app/Store";
import './assets/scss/theme.scss';
import { getPermissions, setPermissions } from "./features/permissionSlice";
import { myPermissions } from "./constants/permissions";


function App() {
const dispatch:AppDispatch=useDispatch();
 const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const {permissions} = useSelector((state: RootState) => state.permission);
  // console.log(permissions)
  useEffect(()=>{
  dispatch(getPermissions());
  // dispatch(setPermissions({
  //   permissions:myPermissions
  // }))
  },[]);
  
 

  return (
    <div className="App">
      <Loader isLoading={isLoading} />
      <AppWrapper>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
        <AppRoute />
      </AppWrapper>
    </div>
  );
}

export default App;

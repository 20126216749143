import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../../features/topbarHeadingSlice'
import PayrollTable from '../../Tables/PayrolTable/PayrollTable'
import { getAllPayrolls, resetPayrollState } from '../../../features/payrollSlice'
import { RootState } from '../../../app/Store'


const data = [
    {sr_no:1,emp_id:'emp1',emp_name:"john doe",phone_no:'2563654125',total_fixed_salary:'2000'},
    {sr_no:2,emp_id:'emp2',emp_name:"john doe1",phone_no:'2563654125',total_fixed_salary:'2000'},
    {sr_no:3,emp_id:'emp3',emp_name:"john doe2",phone_no:'2563654125',total_fixed_salary:'2000'},

]
const PayrollList = () => {
  document.title = "Payroll List| KSM - Admin Dashboard.";
const {allPayrolls}=useSelector((state:RootState)=>state.payroll)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Payroll List",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Payroll", link: "#" },
              { title: "List", link: "#" },
            ],
          })
        );
        dispatch(getAllPayrolls());
        dispatch(resetPayrollState());
      }, []);
    const columnData=[
          {
            label: 'Sr.No',
            field: 'sl_no',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee Id',
            field: 'employee_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee Name',
            field: 'employee_name',
            sort: 'asc',
            width: 150
          },
          
          {
            label: 'Total Fixed Salary',
            field: 'total_fixed_salary',
            sort: 'asc',
            width: 150
          },
          
    ]

    console.log(allPayrolls)
  return (
    <div>
      <PayrollTable columns={columnData} rows={allPayrolls}/>

    </div>
  )
}

export default PayrollList
import React, { useEffect } from 'react'
import TripTable from '../Tables/Trip/TripTable'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../features/topbarHeadingSlice'
import { getAllTrips, resettripState } from '../../features/tripSlice'
import { RootState } from '../../app/Store'
import { getPermissions } from '../../features/permissionSlice'
import { getAllEmployees } from '../../features/employeesSlice'


const data = [
    {id:1,trip_id:"trp12",service_id:"SER02",trip_origin:'pune',trip_destination:'banglore',start_date:'01-04-2024',end_date:'01-04-2024', start_time:'07:00:00',end_time:'12:00:00'},
    {id:2,trip_id:"trp12",service_id:"SER02",trip_origin:'pune',trip_destination:'banglore',start_date:'01-04-2024',end_date:'01-04-2024', start_time:'07:00:00',end_time:'12:00:00'},
    {id:3,trip_id:"trp12",service_id:"SER02",trip_origin:'pune',trip_destination:'banglore',start_date:'01-04-2024',end_date:'01-04-2024', start_time:'07:00:00',end_time:'12:00:00'}
]
const TripList = () => {
  document.title = "Trip | KSM - Admin Dashboard.";
  const {allTrips}=useSelector((state:RootState)=>state.trips)

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Trip",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Trip", link: "#" },
            ],
          })
        );
        dispatch(getAllTrips());
        dispatch(resettripState());
        dispatch(getAllEmployees());


      }, []);
// Trip ID
// Service Number
// Origin
// Destination
// Start Date
// Start Time
// End Date
// End time
    const columnData=[
        // {
        //     label: 'Id',
        //     field: 'id',
        //     sort: 'asc',
        //     width: 150
        //   },
        {
            label: 'Trip ID',
            field: 'trip_id',
            sort: 'asc',
            width: 150
          },
        {
            label: 'Service ID',
            field: 'service_id',
            sort: 'asc',
            width: 150
          },
          // {
          //   label: 'Origin',
          //   field: 'trip_origin',
          //   sort: 'asc',
          //   width: 150
          // },
          // {
          //   label: 'Destination',
          //   field: 'trip_destination',
          //   sort: 'asc',
          //   width: 150
          // },
          {
            label: 'Start Date',
            field: 'start_date',
            sort: 'asc',
            width: 150
          },
          {
            label: 'End Date',
            field: 'end_date',
            sort: 'asc',
            width: 150
          },
         
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 150
          },
    ]
  return (
    <div>
     <TripTable columns={columnData} rows={allTrips}/>
    </div>
  )
}

export default TripList
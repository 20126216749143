import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allPayrolls: any;
  payroll: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allPayrolls: [],
  payroll: {},
  isSuccess: false,
};

interface allPayrollsProp {
  allPayrolls: any;
}
interface payrollProp {
  payroll: any;
}

interface addpayrollPayload {
  emp_id: number;
  designation_id: number;
  pay_components: any;
  bank_account_number: string;
  ifsc: string;
  account_name: string;
  branch: string;
  total_salary: number;
  status: any;
  created_by: any;
}
interface updatepayrollPayload {
  id: any;
  emp_id: number;
  designation_id: number;
  pay_components: any;
  bank_account_number: string;
  ifsc: string;
  account_name: string;
  branch: string;
  total_salary: number;
  status: any;
  updated_by: any;
}
interface payrollStateProp {
  isSuccess: boolean;
}
export const getAllPayrolls = createAsyncThunk(
  "get all payrolls", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allPayrolls, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      const data = await response.json();
      if (response.status === 200) {
        // toast.success("payroll Data Fetch Successfully!.");
        // console.log(data);
        dispatch(
          setAllPayrolls({
            allPayrolls: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
      } else if (response.status === 404 && data.status === 404) {
        toast.error("No payroll found.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewpayroll = createAsyncThunk(
  "add new payroll",
  async (payload: addpayrollPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      console.log(JSON.stringify(payload));
      dispatch(toggleLoader({ isLoading: false }));

      const response = await fetch(endpoints.addPayroll, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success("Payroll Added Successfully!.");
        // console.log(data);
        dispatch(
          setPayrollState({
            isSuccess: true,
          })
        );
        dispatch(getAllPayrolls());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new payroll:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updatePayroll = createAsyncThunk(
  "update payroll",
  async (payload: updatepayrollPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      console.log(payload);
      const response = await fetch(`${endpoints.editPayroll}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Payroll updated Successfully!.");
        // console.log(data);
        dispatch(
          setPayrollState({
            isSuccess: true,
          })
        );
        dispatch(getAllPayrolls());
      } else {
        toast.success("Sorry!,Something went wrong!.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new payroll:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deletepayrollHandler = createAsyncThunk(
  "delete payroll from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      console.log(payload);
      const response = await fetch(`${endpoints.deletePayroll}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllPayrolls());
        // console.log(data);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getPayrollDetail = createAsyncThunk(
  "get payroll details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewPayroll}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setPayrollDetails({
            payroll: data,
          })
        );
        toast.success("Payroll Details Fetch successfully!.");
        console.log(data);
      }

      if (data.code === 404) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const payrollSlice = createSlice({
  name: "payroll slice",
  initialState: initialState,
  reducers: {
    setAllPayrolls: (state, action: PayloadAction<allPayrollsProp>) => {
      return {
        ...state,
        allPayrolls: action.payload.allPayrolls,
      };
    },
    setPayrollDetails: (state, action: PayloadAction<payrollProp>) => {
      return {
        ...state,
        payroll: action.payload.payroll,
      };
    },
    setPayrollState: (state, action: PayloadAction<payrollStateProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetPayrollState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllPayrolls,
  setPayrollState,
  resetPayrollState,
  setPayrollDetails,
} = payrollSlice.actions;
export default payrollSlice.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/Store";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../../routes/routes";
import {  getDesignationDetail, updateDesignation } from "../../../../features/employeesSlice";

const UpdateDesignation: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {isSuccess,designation}=useSelector((state:RootState)=>state.employees);
console.log(designation)
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Update Designation",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Designations", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getDesignationDetail(params.id))
  }, []);

  useEffect(()=>{
    if(isSuccess===true){
        navigate(routerPath.designation);
    }
  },[isSuccess])

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // Dispatch the UpdateNewUser action with the form values
      console.log(values);
      const payloadData ={
        ...values,
        id:params.id
      }
      dispatch(updateDesignation(payloadData));
    }
  };

 

  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">Update Designation</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>        
            <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="status1">Designation</Label>
                  <AvField
                    name="designation_name"
                    type="select"
                    errorMessage="Please provide a valid designation."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={designation?.designation_name}
                  >
                  
                    <option value="">Select Status</option>
                    <option value="manager">Manager</option>
                    <option value="senior manager">Senior Manager</option>
                    <option value="owner">Owner</option>

                    <option value="driver">Driver</option>
                    <option value="cleaner">Cleaner</option>
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={designation?.status}

                  >
                  
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </AvField>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddDesignation component
export default UpdateDesignation;

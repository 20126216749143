const response = {
  id: "8",
  role_name: "adminrole1",
  permissions: {
    buses: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    trips: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    users: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    routes: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    staffs: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    drivers: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    cleaners: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    services: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    stops: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    userrole: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    employees: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payrolls: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payrollComponents: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payruns: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    designations: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
  },
  created_by: "1",
  updated_by: "1",
  created_at: "2024-04-19 15:49:40",
  updated_at: "2024-04-22 11:32:31",
  deleted_at: null,
};
interface permissionState {
  buses: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  trips: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  users: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  routes: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  staffs: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  drivers: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  cleaners: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  services: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  stops: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  userrole: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  employees: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  designations: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payrolls: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payrollComponents: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payruns: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
}
export const myPermissions: permissionState = response.permissions;

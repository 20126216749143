import styled from "styled-components";
import * as UIConstants from "../../constants/UIConstants";

export const StyledAppWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  /* background-color: rgba(191, 173, 117, 255); */
  background-color: #f5f5f5;
`;

interface HumBtn {
  open: boolean;
}

export const StyledBurger = styled.button<HumBtn>`
  position: absolute;
  top: 2%;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  /* z-index: 10; */

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#8A92A6" : "rgba(44,87,115,255)")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const LeftMenuSection = styled.div`
  display: flex;
  flex-basis: 21%;
  height: 100vh;
  /* border-right: 1px solid #f6f6f6; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis: 100%;

  ${UIConstants.mobileView} {
    flex-basis: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 15px 8px 15px;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  ${UIConstants.customView} {
    max-height: 90vh;
  }
  scrollbar-width: thin;
  /* scrollbar-color: #3b4854 #f5f5f5;  */

  /* WebKit */
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /* background-color: #cccccc;  */
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    /* background-color: #263442;  */
    border-radius: 5px;
  }
`;

export const AuthContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  ${UIConstants.customView} {
  }
`;

interface LftMenuProp {
  open: boolean;
}
export const LeftMenuWrapper = styled.div<LftMenuProp>`
  z-index: 100;
  flex-basis: ${({ open }) => (open ? "18%" : "")};
  ${UIConstants.mobileView} {
    flex-basis: 0%;
  }
`;

import React, { useState } from "react";
import logoDark from "../../../assets/ksmlogo.png";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Input,
  FormFeedback,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/Store";
import { createLogin } from "../../../features/authSlice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  document.title = "Login | KSM - Admin Dashboard";
  const dispatch: AppDispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: FormValues) => {
      dispatch(createLogin(values));
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden shadow-lg">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt="KSM Logo"
                        height="50"
                        className="auth-logo-dark"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back!
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to KSM.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={validation.handleSubmit}
                    >
                      <div className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email}
                          invalid={Boolean(
                            validation.touched.email && validation.errors.email
                          )}
                        />
                        {validation.touched.email && validation.errors.email && (
                          <FormFeedback>{validation.errors.email}</FormFeedback>
                        )}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="password">Password</Label>
                        <InputGroup>
                          <Input
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            type={showPassword ? "text" : "password"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                            invalid={Boolean(
                              validation.touched.password &&
                              validation.errors.password
                            )}
                          />
                          <InputGroupText style={{cursor:'pointer'}} onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                          </InputGroupText>
                          {validation.touched.password && validation.errors.password && (
                            <FormFeedback>{validation.errors.password}</FormFeedback>
                          )}
                        </InputGroup>
                      </div>
                      <Row className="mb-3 mt-4">
                        <Col xs={6}>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </Label>
                          </div>
                        </Col>
                        <Col xs={6} className="text-end">
                          <Button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                © {new Date().getFullYear()} KSM
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/Store";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletepayRunsHandler } from "../../../features/payrollRunsSlice";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

interface RowData {
  id:string; 
      start_date:string; 
      end_date:string; 
      pay_run_date:string; 
      status:string; 

}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const PayrunTable: React.FC<TableProp> = ({ columns, rows }) => {
  const dispatch = useDispatch();
  
  const { permissions } = useSelector((state: RootState) => state.permission);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
// console.log(permissions)
  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    console.log(selectedId);
    dispatch(deletepayRunsHandler(selectedId));
    
  };

  const newColumns = [
    ...columns,
    {
      label: "Actions",
      field: "actions",
      width: 100,
    },
  ];
  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData, 
      actions: (
        // <div className="d-flex justify-content-around align-items-center " >
        <div className="d-flex  gap-1 justify-content-center align-items-center">
          {permissions.payruns?.view && (
            <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={() =>navigate(routerPath.viewPayrun(rowData.id))}
              title="View"
            >
              <PiEyeBold style={{ fontSize: "18px" }} />
            </Button>
           )}
          {permissions.payruns?.edit && (
            <Button
              type="button"
              color="info"
              className="waves-effect waves-light"
              onClick={() => navigate(routerPath.updatePayrun(rowData.id))}
              title="Update"
            >
              <PiNotePencilBold style={{ fontSize: "18px" }} />
            </Button>
           )} 
          {permissions.payruns?.delete && (
            <Button
              type="button"
              color="danger"
              className="waves-effect waves-light"
              onClick={() => handleDeleteModal(rowData.id)}
              title="Delete"
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </Button>
           )} 
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Payrun"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex gap-2 justify-content-end">
              <ParseToCsv data={rows} fileLabel="pay-runs"/>
              {permissions.payruns?.add && (
                  <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() =>navigate(routerPath.generatePayrollReport)}
                  >
                    Generate Report
                  </Button>
               )} 
                {permissions.payruns?.add && (
                <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() =>navigate(routerPath.addPayrun)}
                  >
                    Add Payrun
                  </Button>
               )} 
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      
    </React.Fragment>
  );
};


export default PayrunTable;
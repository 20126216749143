import React from 'react';
import { Button } from 'reactstrap';

interface DownloadImageProps {
  imgUrl: string;
  filename: string;
}

const DownloadImage: React.FC<DownloadImageProps> = ({ imgUrl, filename }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imgUrl;
    link.download = `${filename}.jpg`;  // using the filename prop correctly
    link.target = '_blank';  // Ensure it opens in a new tab
    document.body.appendChild(link);    // append to body to make the link work in all browsers
    link.click();
    document.body.removeChild(link);    // remove after clicking
  };

  return (
    <div>
      <Button onClick={handleDownload} color="primary" type="button">Download Image</Button>
    </div>
  );
};

export default DownloadImage;

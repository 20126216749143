import React, { useEffect, useState } from "react";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../styledPayroll";
import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  getAllDesignation,
  getAllEmployees,
} from "../../../../features/employeesSlice";
import { RootState } from "../../../../app/Store";
import { addNewpayroll } from "../../../../features/payrollSlice";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../../routes/routes";
import { getAllPayrollComponents } from "../../../../features/payrollComponentSlice";
import { toast } from "react-toastify";

interface FormValues {
  emp_id: string;
  designation_id: string;
  bank_account_number: string;
  account_name: string;
  branch: string;
  ifsc: string;
  total_salary: string;
  status: string;
}

interface PayrollComponent {
  id: string;
  component_name: string;
}

const AddToPayroll = () => {
  document.title = "Add Employee To Payroll | KSM - Admin Dashboard.";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allEmployees, allDesignation } = useSelector(
    (state: RootState) => state.employees
  );
  const { allPayrollComponents } = useSelector(
    (state: RootState) => state.payrollComponents
  );
  const { isSuccess } = useSelector((state: RootState) => state.payroll);
  const { loggedInUser } = useSelector((state: RootState) => state.user);

  const [selectedComponents, setSelectedComponents] = useState<
    Record<string, boolean>
  >({});
  const [designationId,setDesignationId]=useState<any>("");
  const [amounts, setAmounts] = useState<Record<string, number>>({});
  const [totalSalary, setTotalSalary] = useState<number>(0);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Employee To Payroll",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "KSM", link: "#" },
          { title: "Payroll", link: "#" },
          { title: "Add Employee", link: "#" },
        ],
      })
    );
    dispatch(getAllEmployees());
    dispatch(getAllDesignation());
    dispatch(getAllPayrollComponents());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      navigate(routerPath.payrollList);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    // Recalculate total salary whenever amounts change
    const newTotalSalary = Object.keys(amounts).reduce(
      (sum, id) => sum + amounts[id],
      0
    );
    setTotalSalary(newTotalSalary);
  }, [amounts]);

  const handleCheckboxChange = (id: string) => {
    setSelectedComponents((prev) => {
      const newSelectedComponents = { ...prev, [id]: !prev[id] };
      // Remove amount if component is unselected
      if (!newSelectedComponents[id]) {
        setAmounts((prevAmounts) => {
          const { [id]: _, ...rest } = prevAmounts;
          return rest;
        });
      }
      return newSelectedComponents;
    });
  };

  const handleSelectEmployee = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let selectedID = event.target.value;
    const Employee = allEmployees.find((emp: any) => emp.id === selectedID);
    // console.log(Employee.designation);
    setDesignationId(Employee.designation);
  };
  const handleAmountChange = (id: string, value: number) => {
    setAmounts((prevAmounts) => ({ ...prevAmounts, [id]: value }));
  };

  const handleSubmit = (
    event: React.FormEvent,
    errors: any[],
    values: FormValues
  ) => {
    event.preventDefault(); // Prevent default form submission

    // Check if at least one component is selected
    const hasSelectedComponents = Object.keys(selectedComponents).some(
      (id) => selectedComponents[id]
    );

    if (errors.length === 0 && hasSelectedComponents) {
      // Filter and transform selected components to match the desired format
      const payComponents = Object.keys(selectedComponents)
        .filter((id) => selectedComponents[id] && amounts[id])
        .map((id) => {
          const component = allPayrollComponents.find(
            (comp: PayrollComponent) => comp.id === id
          );
          return {
            id: Number(id),
            [component?.component_name || ""]: Number(amounts[id]),
          };
        });

      // Create payloadData with proper structure
      const payloadData = {
        emp_id: Number(values.emp_id),
        designation_id: Number(values.designation_id),
        pay_components: payComponents,
        bank_account_number: values.bank_account_number,
        ifsc: values.ifsc,
        account_name: values.account_name,
        branch: values.branch,
        total_salary: totalSalary, // Use computed totalSalary
        status: values.status,
        created_by: loggedInUser?.username,
        // updated_by: 1,
      };

      console.log(payloadData);

      dispatch(addNewpayroll(payloadData));
    } else if (!hasSelectedComponents) {
      // Show an error message if no components are selected
      toast.warning("Please select at least one pay component.");
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Employee</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="emp_id">Employee</Label>
                  <AvField
                    name="emp_id"
                    type="select"
                    errorMessage="Please select an employee."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="emp_id"
                    onChange={handleSelectEmployee}
                  >
                    <option value="">Select Employee</option>
                    {allEmployees.map((employee: any) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.employee_id} - {employee.employee_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="designation_id">Employee Designation</Label>
                  <AvField
                    name="designation_id"
                    type="select"
                    errorMessage="Please select employee first it will auto populate."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="designation_id"
                    value={designationId}
                    disabled
                  >
                    <option value="">Select Employee Role</option>
                    {allDesignation.map((designation: any) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.id} - {designation.designation_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="bank_account_number">
                    Bank Account Number
                  </Label>
                  <AvField
                    name="bank_account_number"
                    type="number"
                    placeholder="Enter Bank Account Number"
                    errorMessage="Please enter bank account number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="bank_account_number"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="account_name">Account Holder Name</Label>
                  <AvField
                    name="account_name"
                    type="text"
                    placeholder="Enter account holder name"
                    errorMessage="Please enter account holder name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="account_name"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="branch">Branch</Label>
                  <AvField
                    name="branch"
                    type="text"
                    placeholder="Enter branch name"
                    errorMessage="Please enter branch name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="branch"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="ifsc">IFSC</Label>
                  <AvField
                    name="ifsc"
                    type="text"
                    placeholder="Enter IFSC code"
                    errorMessage="Please enter IFSC code."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="ifsc"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="total_salary">Total Salary</Label>
                  <AvField
                    name="total_salary"
                    type="number"
                    placeholder="Enter total salary"
                    value={totalSalary}
                    errorMessage="Total salary is required."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="total_salary"
                    readOnly
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please select a status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="pay_components">Pay Components</Label>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>ID</th>
                        <th>Component Name</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allPayrollComponents.map(
                        (component: PayrollComponent) => {
                          const isSelected = !!selectedComponents[component.id];
                          return (
                            <tr key={component.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={isSelected}
                                  onChange={() =>
                                    handleCheckboxChange(component.id)
                                  }
                                />
                              </td>
                              <td>{component.id}</td>
                              <td>{component.component_name}</td>
                              <td>
                                {isSelected ? (
                                  <AvField
                                    name={`amount_${component.id}`}
                                    type="number"
                                    placeholder="Enter amount"
                                    value={amounts[component.id] || ""}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      handleAmountChange(
                                        component.id,
                                        +e.target.value
                                      )
                                    }
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Amount is required.",
                                      },
                                    }}
                                  />
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AddToPayroll;

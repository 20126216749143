import React, { FC } from "react";
import { Card, CardBody, Row, CardTitle } from "reactstrap";
import DonutChart from "../../Charts/DonutChart";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/Store";



const TotalDataGraph: FC = () => {
  const { busData,routeData,servicesData,tripsData}=useSelector((state:RootState)=>state.dashBoard);
  // console.log(busData?.length,routeData?.length,servicesData?.length,tripsData?.length);
  return (
    <>
      <Card>
        <CardBody>
          {/* <CardTitle className="h4 mb-4">Total Data</CardTitle> */}

          <Row className="text-center ">
            <div className="col-6">
              <h5 className="font-size-20">{busData?.length}</h5>
              <p className="text-muted">Total Buses</p>
            </div>
            <div className="col-6">
              <h5 className="font-size-20">{routeData?.length}</h5>
              <p className="text-muted">Total Route</p>
            </div>
          </Row>
          <Row className="text-center ">
            <div className="col-6">
              <h5 className="font-size-20">{servicesData?.length}</h5>
              <p className="text-muted">Total Services</p>
            </div>
            <div className="col-6">
              <h5 className="font-size-20">{tripsData?.length}</h5>
              <p className="text-muted">Total Trips</p>
            </div>
          </Row>
          <div dir="ltr">
            <DonutChart busData={busData?.length} routeData={servicesData?.length} servicesData={servicesData?.length} tripsData={tripsData?.length}/>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TotalDataGraph;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allCleaner: any;
  cleaner: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allCleaner: [],
  cleaner: {},
  isSuccess: false,
};

interface allCleanerProp {
  allCleaner: any;
}
interface cleanerProp {
  cleaner: any;
}

interface buscleanerProp {
  isSuccess: boolean;
}

interface addcleanerPayload {
  user_id: number;
  photo_url: string;
  cleaner_name: string;
  employee_id: string;
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  pan_number: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: number;
}
interface updatecleanerPayload {
  id: any;
  user_id: any;
  photo_url: string;
  cleaner_name: string;
  employee_id: string;
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  pan_number: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: number;
}
export const getAllCleaner = createAsyncThunk(
  "get all cleaners", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allCleaners, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("cleaner Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllCleaner({
            allCleaner: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewcleaner = createAsyncThunk(
  "add new cleaner",
  async (payload: addcleanerPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.addCleaner, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setcleanerState({
            isSuccess: true,
          })
        );
        dispatch(getAllCleaner());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deletecleanerHandler = createAsyncThunk(
  "delete cleaner from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteCleaner}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllCleaner());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getcleanerDetail = createAsyncThunk(
  "get cleaner details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewCleaner}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setcleanerDetails({
            cleaner: data,
          })
        );
        toast.success("cleaner data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updatecleaner = createAsyncThunk(
  "update cleaner",
  async (payload: updatecleanerPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const bodyObj = {
        user_id: payload.user_id,
        employee_id: payload.employee_id,
        photo_url: payload.photo_url,
        cleaner_name: payload.cleaner_name,
        id_type: payload.id_type,
        id_number: payload.id_number,
        id_issued_by: payload.id_issued_by,
        id_issue_date: payload.id_issue_date,
        id_expiry_date: payload.id_expiry_date,
        pan_number: payload.pan_number,
        contact_number: payload.contact_number,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        zip_code: payload.zip_code,
        emergency_contact_name: payload.emergency_contact_name,
        emergency_contact_number: payload.emergency_contact_number,
        emergency_contact_relation: payload.emergency_contact_relation,
        status: payload.status,
      };

      const response = await fetch(`${endpoints.editCleaner}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(bodyObj),
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setcleanerState({
            isSuccess: true,
          })
        );
        dispatch(getAllCleaner());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const cleanerSlice = createSlice({
  name: "cleaner slice",
  initialState: initialState,
  reducers: {
    setAllCleaner: (state, action: PayloadAction<allCleanerProp>) => {
      return {
        ...state,
        allCleaner: action.payload.allCleaner,
      };
    },
    setcleanerDetails: (state, action: PayloadAction<cleanerProp>) => {
      return {
        ...state,
        cleaner: action.payload.cleaner,
      };
    },
    setcleanerState: (state, action: PayloadAction<buscleanerProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetcleanerState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllCleaner,
  setcleanerState,
  resetcleanerState,
  setcleanerDetails,
} = cleanerSlice.actions;
export default cleanerSlice.reducer;

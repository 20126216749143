import React from 'react'
import { Content, Text, Wrapper } from './StyledFooter'

const Footer:React.FC = () => {
  return (
    <Wrapper>
        <Content>
            <Text>&#169;{new Date().getFullYear()} KSM.</Text>
            <Text>Vesion : 2.1.1 </Text>
        </Content>
    </Wrapper>
  )
}

export default Footer
import React from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/Store";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

interface RowData {
  id: string;
  compliance_status: string;
  bus_number: string;
  fitness_end_date: string;
  mother_permit_expiry_date: string;
  mother_permit_authorization_end_date: string;
  mother_road_tax_expiry_date: string;
  mother_road_tax_authorization_end_date: string;
  all_india_tourist_permit_expiry_date: string;
  insurance_end_date: string;
  emission_end_date: string;
}

interface TableProp {
  rows: RowData[];
  columns: any;
}

const ComplianceTable: React.FC<TableProp> = ({ columns, rows }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  // Define a function to determine cell class based on compliance_status
  const getCellClass = (compliance_status: string) => {
    return compliance_status !== "compliant" ? "red-bg" : "green-bg";
  };
  const getCellBackgroundColor = (compliance_status: string) => {
    return compliance_status !== "compliant" ? "#ff9c9c" : "#a0dea1";
  };

  const data = {
    columns: [
      // { label: "ID", field: "id" },
      { label: "Bus Number", field: "bus_number" },
      { label: "Compliance Status", field: "compliance_status" },
      { label: "Fitness End Date", field: "fitness_end_date" },
      {
        label: "Mother Permit Expiry Date",
        field: "mother_permit_expiry_date",
      },
      {
        label: "Mother Permit Authorization End Date",
        field: "mother_permit_authorization_end_date",
      },
      {
        label: "Mother Road Tax Expiry Date",
        field: "mother_road_tax_expiry_date",
      },
      {
        label: "Mother Road Tax Authorization End Date",
        field: "mother_road_tax_authorization_end_date",
      },
      {
        label: "All India Tourist Permit Expiry Date",
        field: "all_india_tourist_permit_expiry_date",
      },
      { label: "Insurance End Date", field: "insurance_end_date" },
      { label: "Emission End Date", field: "emission_end_date" },
      // Add more columns as needed
    ],
    rows: rows.map((row) => ({
      ...row,
      compliance_status: (
        <div
          className={getCellClass(row.compliance_status)}
          style={{
            backgroundColor: getCellBackgroundColor(row.compliance_status),
            textAlign: "center",
            textTransform: "capitalize",
          }}
        >
          {row.compliance_status}
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
            <CardTitle className="d-flex gap-2 justify-content-end">
            <ParseToCsv data={rows} fileLabel="compliance"/>

              </CardTitle>
              <MDBDataTable
                responsive
                bordered
                noBottomColumns
                data={data}
                // className="custom-table"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ComplianceTable;

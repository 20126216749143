import React, { useEffect, useState } from "react";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../styledPayroll";
import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllDesignation, getAllEmployees } from "../../../../features/employeesSlice";
import { RootState } from "../../../../app/Store";
import { routerPath } from "../../../../routes/routes";
import { useNavigate } from "react-router-dom";
import { addNewpayRuns } from "../../../../features/payrollRunsSlice";

interface Employee {
  id: string;
  user_id: string;
  employee_id: string;
  photo_url: string;
  employee_name: string;
  designation: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  license_number: string;
  license_expiry_date: string;
  pan_number: string;
  pan_url: string;
  document_upload: {
    license_doc: string;
  };
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: string;
}

interface Designation {
  id: string;
  designation_name: string;
  status: string;
}

const AddPayrun = () => {
  document.title = "Add Payrun | KSM - Admin Dashboard";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allEmployees, allDesignation } = useSelector((state: RootState) => state.employees);
  const { loggedInUser } = useSelector((state: RootState) => state.user);
  const { isSuccess } = useSelector((state: RootState) => state.payruns);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Set<string>>(new Set());
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(changeHeading({
      heading: "Add Payrun",
      isBackBtn: true,
      breadcrumbItems: [
        { title: "KSM", link: "#" },
        { title: "Payrun", link: "#" },
        { title: "Add", link: "#" },
      ],
    }));
    dispatch(getAllDesignation());
    dispatch(getAllEmployees());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      navigate(routerPath.payrollRuns);
    }
  }, [isSuccess, navigate]);

  const findDesignationIdByName = (name: string): string | undefined =>
    allDesignation.find((d: Designation) => d.designation_name === name)?.id;

  const handleCheckboxChange = (employee: Employee) => {
    setSelectedEmployeeIds(prevIds => {
      const newIds = new Set(prevIds);
      if (newIds.has(employee.id)) {
        newIds.delete(employee.id);
      } else {
        newIds.add(employee.id);
      }
      return newIds;
    });
  };

  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };

  const formatSelectedEmployees = () => {
    return Array.from(selectedEmployeeIds).map(employeeId => {
      const employee = allEmployees.find((emp: Employee) => emp.id === employeeId);
      return employee ? {
        employee_id: employee.id,
        designation_id: employee.designation,
      } : null;
    }).filter((item): item is { employee_id: string; designation_id: string } => item !== null);
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      if (selectedEmployeeIds.size === 0) {
        setFormError("Please select at least one employee.");
        return;
      }
      setFormError(null); // Clear error if validation passes
      const payloadData = {
        ...values,
        employees: formatSelectedEmployees(),
        created_by: loggedInUser?.username,
        // updated_by: 1,
      };
      console.log(payloadData);
      dispatch(addNewpayRuns(payloadData));
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Payrun</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="start_date1">Start Date</Label>
                  <AvField
                    name="start_date"
                    type="date"
                    placeholder="Select start date."
                    errorMessage="Please select a start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="start_date1"
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="end_date1">End Date</Label>
                  <AvField
                    name="end_date"
                    type="date"
                    placeholder="Select end date."
                    errorMessage="Please select an end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="end_date1"
                  />
                </div>
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                  >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="complete">Complete</option>
                  </AvField>
                </div>
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employees">Employees</Label>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allEmployees.map((employee: Employee) => (
                        <tr key={employee.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedEmployeeIds.has(employee.id)}
                              onChange={() => handleCheckboxChange(employee)}
                            />
                          </td>
                          <td>{employee.employee_name}</td>
                          <td className="text-capitalize">{getDesignationName(employee.designation)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {formError && <div className="text-danger">{formError}</div>}
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AddPayrun;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { Button, Card, CardBody, Col, FormFeedback, Label, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { Wrapper } from "../../Services/AddService/StyledAddService";

import { useNavigate, useParams } from "react-router-dom";
import { getAllServices } from "../../../features/serviceSlice";
import {
  addNewtrip,
  gettripDetail,
  updatetrip,
} from "../../../features/tripSlice";
import { routerPath } from "../../../routes/routes";
import TripStopTable from "../../Tables/TripStop/TripStopTable";
import { getUserData } from "../../../features/DashboardSlice";
import { Autocomplete, TextField } from "@mui/material";
import { getAllDesignation, getAllEmployees } from "../../../features/employeesSlice";

interface SelectProp {
  service_name: string;
  service_id: string;
}

interface ServiceData {
  id: any;
  isRecurring: any;
  bus_number: string;
  route_number: string;
  service_id: string;
  service_name: string;
  service_status: string;
}
interface tripData {
  serviceId: any;
  serviceName: any;
  routeId: any;
  origin: any;
  destination: any;
  busNumber: any;
  startDay: any;
  serviceStatus: any;
}
interface Employee {
  id: string;
  user_id: string;
  employee_id: string;
  photo_url: string;
  employee_name: string;
  designation: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  license_number: string;
  license_expiry_date: string;
  pan_number: string;
  pan_url: string;
  document_upload: {
    license_doc: string;
  };
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: string;
}

interface SimplifiedEmployee {
  id: string;
  employee_id: string;
  designation: string;
  employee_name: string;
}
const Dtcolumns = [
  {
    label: "ID",
    field: "index",
    sort: "asc",
    width: 150,
  },
  {
    label: "Stop Name",
    field: "stop_name",
    sort: "asc",
    width: 150,
  },
  
 
      
];

const ViewTrip = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { isSuccess, trip } = useSelector((state: RootState) => state.trips);
  const { allServices } = useSelector((state: RootState) => state.services);
  const { usersData } = useSelector((state: RootState) => state.dashBoard);
  const { allEmployees,allDesignation } = useSelector((state: RootState) => state.employees);

  const [selectedService, setSelectedService] = useState<ServiceData | null>(
    null
  );
  const [selectedEmployee, setSelectedEmployee] = useState<SimplifiedEmployee[]>([]);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Set<string>>(new Set());
  const [validationError, setValidationError] = useState<string | null>(null);

  const [formData, setFormData] = useState<tripData>({
    serviceId: "",
    serviceName: "",
    routeId: "",
    origin: "",
    destination: "",
    busNumber: "",
    startDay: "",
    serviceStatus: "",
  });

  useEffect(() => {
    if (trip.tripDetails) {
      const {
        serviceId,
        serviceName,
        routeId,
        origin,
        destination,
        busNumber,
        startDay,
        serviceStatus,
      } = trip.tripDetails;
      setFormData({
        serviceId,
        serviceName,
        routeId,
        origin,
        destination,
        busNumber,
        startDay,
        serviceStatus,
      });
      const stopObj = allServices.find(
        (service: ServiceData) => service?.service_id === serviceId
      );
      if (stopObj) {
        setSelectedService(stopObj);
      }
      if (trip.dutyDetails) {
        const dutyEmployees = trip.dutyDetails.employeeDetails.map((emp: any) => ({
          id: emp.id,
          employee_id: emp.employee_id,
          designation: emp.designation,
          employee_name: emp.employee_name,
        }));
        setSelectedEmployee(dutyEmployees);
      setSelectedEmployeeIds(new Set(dutyEmployees.map((emp: SimplifiedEmployee) => emp.id)));

      }
    }
  }, [trip]);
  
  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };
  console.log(trip);
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "View Trip",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Trip", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getAllServices());
    dispatch(gettripDetail(params.id));
    dispatch(getUserData());
    dispatch(getAllEmployees());
    dispatch(getAllDesignation());
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.trip);
    }
  }, [isSuccess]);
 
  
  const handleServiceSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedServiceName = event.target.value;
    const stopObj = allServices.find(
      (service: ServiceData) => service.service_id === selectedServiceName
    );
    if (stopObj) {
      setSelectedService(stopObj);
    }

  };
  const simplifyEmployees = (employees: Employee[]): SimplifiedEmployee[] => {
    return employees.map((employee) => ({
      id: employee.id,
      employee_id: employee.employee_id,
      designation: employee.designation,
      employee_name: employee.employee_name,
    }));
  };

  const simplifiedArray: SimplifiedEmployee[] = simplifyEmployees(allEmployees);

  const handleEmployeeSelection = (employeeId: string, isChecked: boolean) => {
    setSelectedEmployeeIds((prevSelectedEmployeeIds) => {
      const updatedSelectedEmployeeIds = new Set(prevSelectedEmployeeIds);
      if (isChecked) {
        updatedSelectedEmployeeIds.add(employeeId);
      } else {
        updatedSelectedEmployeeIds.delete(employeeId);
      }
      return updatedSelectedEmployeeIds;
    });
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const selectedEmployees = simplifiedArray.filter((employee) =>
        selectedEmployeeIds.has(employee.id)
      );
      if (selectedEmployees.length === 0) {
        setValidationError("Please select at least one employee.");
        return;
      } else {
        setValidationError(null);
      }
      const formattedData = {
        ...values,
        bus_number: selectedService?.bus_number,
        route_number: selectedService?.route_number,
        service_id: selectedService?.service_id,
        id: params.id,
      };
      // Dispatch the addNewUser action with the form values
      dispatch(updatetrip(formattedData));
      console.log(formattedData);
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">View Trip</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="busNumber">Service</Label>
                  <AvField
                    name="service_id"
                    type="select"
                    errorMessage="Please select service."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="busNumber"
                    onChange={handleServiceSelect}
                    value={formData.serviceId}
                  >
                    <option value="">Select Service</option>
                    {allServices.map((serviceObj: SelectProp) => (
                      <option
                        key={serviceObj.service_name}
                        value={serviceObj.service_id}
                      >
                        {serviceObj.service_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="BusNumber">Bus Number</Label>
                  <AvField
                    name="bus_number"
                    placeholder="Bus Number"
                    type="text"
                    errorMessage="Please select service bus number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="BusNumber"
                    value={selectedService?.bus_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="routeNumber">Route Number</Label>
                  <AvField
                    name="route_number"
                    placeholder="Route Number"
                    type="text"
                    errorMessage="Please select service route number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeNumber"
                    value={selectedService?.route_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate1">Start Date</Label>
                  <AvField
                    name="start_date"
                    placeholder="Start Date"
                    type="date"
                    errorMessage="Please provide start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate1"
                    value={formData.startDay}

                  />
                </div>
              </Col>
              
              
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate13">Origin</Label>
                  <AvField
                    name="origin"
                    placeholder="Origin"
                    type="text"
                    errorMessage="Please provide origin."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate13"
                    value={formData.origin}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate12">Destination</Label>
                  <AvField
                    name="destination"
                    placeholder="destination"
                    type="text"
                    errorMessage="Please provide destination."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate12"
                    value={formData.destination}

                  />
                </div>
              </Col>
              {/* <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employeeDetails">Employee Details</Label>
                  <Autocomplete
                    multiple
                    options={simplifiedArray}
                    getOptionLabel={(employee: SimplifiedEmployee) =>
                      `${employee?.designation} - ${employee?.employee_name}`
                    }
                    value={selectedEmployee}
                    onChange={(event, newValue) => setSelectedEmployee(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} label="Employees" placeholder="Select Employees" />
                    )}
                  />
                </div>
              </Col> */}
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employeeDetails">Employee Details</Label>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {simplifiedArray.map((employee: SimplifiedEmployee) => (
                        <tr key={employee.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedEmployeeIds.has(employee.id)}
                              onChange={(e) =>
                                handleEmployeeSelection(employee.id, e.target.checked)
                              }
                            />
                          </td>
                          <td>{employee.employee_name}</td>
                          <td className="text-capitalize">{getDesignationName(employee.designation)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {validationError && (
                    <FormFeedback style={{ display: "block" }}>
                      {validationError}
                    </FormFeedback>
                  )}
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div> */}
          </AvForm>
        </CardBody>
      </Card>
      <TripStopTable columns={Dtcolumns} rows={trip?.announcementPoints} service_id={formData?.serviceId}/> 
    </Wrapper>
  );
};



export default ViewTrip;

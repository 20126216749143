import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { Button, Card, CardBody, Col, Label, Row, Table, FormFeedback } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Wrapper } from "../../Services/AddService/StyledAddService";
import { useNavigate } from "react-router-dom";
import { getAllServices } from "../../../features/serviceSlice";
import { addNewtrip, updatetrip } from "../../../features/tripSlice";
import { routerPath } from "../../../routes/routes";
import { getAllDesignation, getAllEmployees } from "../../../features/employeesSlice";
import { getUserData } from "../../../features/DashboardSlice";
import { toast } from "react-toastify";

interface SelectProp {
  service_name: string;
  service_id: string;
}

interface ServiceData {
  id: string;
  isRecurring: boolean;
  bus_number: string;
  route_number: string;
  service_id: string;
  service_name: string;
  service_status: string;
}

interface Employee {
  id: string;
  user_id: string;
  employee_id: string;
  photo_url: string;
  employee_name: string;
  designation: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  license_number: string;
  license_expiry_date: string;
  pan_number: string;
  pan_url: string;
  document_upload: {
    license_doc: string;
  };
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: string;
}

interface SimplifiedEmployee {
  id: string;
  employee_id: string;
  designation: string;
  employee_name: string;
}

const AddTrip = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { allServices } = useSelector((state: RootState) => state.services);
  const [selectedService, setSelectedService] = useState<ServiceData | null>(null);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Set<string>>(new Set());
  const [validationError, setValidationError] = useState<string | null>(null);

  const { isSuccess } = useSelector((state: RootState) => state.trips);
  const { loggedInUser } = useSelector((state: RootState) => state.user);
  const { allEmployees ,allDesignation} = useSelector((state: RootState) => state.employees);

  const handleServiceSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedServiceName = event.target.value;
    const stopObj = allServices.find(
      (service: ServiceData) => service.service_id === selectedServiceName
    );
    if (stopObj) {
      setSelectedService(stopObj);
    }
  };

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Add Trip",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Trip", link: "#" },
          { title: "Add Trip", link: "#" },
        ],
      })
    );
    dispatch(getAllServices());
    dispatch(getUserData());
    dispatch(getAllEmployees());
    dispatch(getAllDesignation());
  }, [dispatch]);
  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };
  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.trip);
    }
  }, [isSuccess, navigate]);

  const simplifyEmployees = (employees: Employee[]): SimplifiedEmployee[] => {
    return employees.map((employee) => ({
      id: employee.id,
      employee_id: employee.employee_id,
      designation: employee.designation,
      employee_name: employee.employee_name,
    }));
  };

  const simplifiedArray: SimplifiedEmployee[] = simplifyEmployees(allEmployees);

  const handleEmployeeSelection = (employeeId: string, isChecked: boolean) => {
    setSelectedEmployeeIds((prevSelectedEmployeeIds) => {
      const updatedSelectedEmployeeIds = new Set(prevSelectedEmployeeIds);
      if (isChecked) {
        updatedSelectedEmployeeIds.add(employeeId);
      } else {
        updatedSelectedEmployeeIds.delete(employeeId);
      }
      return updatedSelectedEmployeeIds;
    });
  };

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      const selectedEmployees = simplifiedArray.filter((employee) =>
        selectedEmployeeIds.has(employee.id)
      );

      if (selectedEmployees.length === 0) {
        setValidationError("Please select at least one employee.");
        toast.error("Please select at least one employee.");
        return;
      } else {
        setValidationError(null);
      }

      const formattedData = {
        ...values,
        bus_number: selectedService?.bus_number,
        route_number: selectedService?.route_number,
        service_id: selectedService?.service_id,
        employee_details: selectedEmployees,
        created_by: loggedInUser.username,
        updated_by: loggedInUser.username,

      };
      dispatch(addNewtrip(formattedData));
      console.log(formattedData);
    }
  };

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">Add Trip</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="serviceSelect">Service</Label>
                  <AvField
                    name="service_id"
                    type="select"
                    errorMessage="Please select service."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="serviceSelect"
                    onChange={handleServiceSelect}
                  >
                    <option value="">Select Service</option>
                    {allServices.map((serviceObj: SelectProp) => (
                      <option
                        key={serviceObj.service_id}
                        value={serviceObj.service_id}
                      >
                        {serviceObj.service_name}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="BusNumber">Bus Number</Label>
                  <AvField
                    name="bus_number"
                    placeholder="Bus Number"
                    type="text"
                    errorMessage="Bus number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="BusNumber"
                    value={selectedService?.bus_number}
                    disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="routeNumber">Route Number</Label>
                  <AvField
                    name="route_number"
                    placeholder="Route Number"
                    type="text"
                    errorMessage="Route number will populate automatically."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeNumber"
                    value={selectedService?.route_number}
                    disabled
                  />
                </div>
              </Col>

              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="startDate1">Start Date</Label>
                  <AvField
                    name="start_date"
                    placeholder="Start Date"
                    type="date"
                    errorMessage="Please provide start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="startDate1"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="endDate1">End Date</Label>
                  <AvField
                    name="end_date"
                    placeholder="End Date"
                    type="date"
                    errorMessage="Please provide end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="endDate1"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                  >
                    <option value="">Select Status</option>
                    <option value="Started">Started</option>
                    <option value="Not Started">Not Started</option>
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employeesTable">Employees</Label>
                  <Table id="employeesTable" responsive>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {simplifiedArray.map((employee) => (
                        <tr key={employee.id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedEmployeeIds.has(employee.id)}
                              onChange={(e) =>
                                handleEmployeeSelection(
                                  employee.id,
                                  e.target.checked
                                )
                              }
                            />
                          </td>
                          <td>{employee.employee_name}</td>
                          <td className="text-capitalize">{getDesignationName(employee.designation)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {validationError && (
                    <FormFeedback style={{ display: "block" }}>
                      {validationError}
                    </FormFeedback>
                  )}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default AddTrip;

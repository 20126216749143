import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";

import "../datatables.scss";
import { useNavigate } from "react-router-dom";
import { routerPath } from "../../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/Store";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { deletepayrollHandler } from "../../../features/payrollSlice";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

interface RowData {
  id:any;
  emp_id:any;
  emp_name:string;
  phone_no:string;
  total_fixed_salary:string;
}

interface ColumnData {
  label: string;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

const PayrollTable: React.FC<TableProp> = ({ columns, rows }) => {
  const { permissions } = useSelector((state: RootState) => state.permission);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
const dispatch = useDispatch();
  const handleDeleteModal = (id: any) => {
    setOpenDeleteModel(true);
    setSelectedId(id);
  };
  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deletepayrollHandler(selectedId));
    
  };

  const newColumns = [
    ...columns,
    {
      label: "Actions",
      field: "actions",
      width: 100,
    },
  ];
  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,   
      total_fixed_salary:<div>&#8377; {rowData.total_fixed_salary}</div>,   
      actions: (
        // <div className="d-flex justify-content-around align-items-center " >
        <div className="d-flex  gap-1 justify-content-center align-items-center">
          {permissions.payrolls?.view && (
            <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={() =>navigate(routerPath.viewPayroll(rowData.id))}
              title="View"
            >
              <PiEyeBold style={{ fontSize: "18px" }} />
            </Button>
           )}
          {permissions.payrolls?.edit && (
            <Button
              type="button"
              color="info"
              className="waves-effect waves-light"
              onClick={() => navigate(routerPath.updatePayroll(rowData.id))}
              title="Update"
            >
              <PiNotePencilBold style={{ fontSize: "18px" }} />
            </Button>
           )} 
          {permissions.payrolls?.delete && (
            <Button
              type="button"
              color="danger"
              className="waves-effect waves-light"
              onClick={() => handleDeleteModal(rowData.id)}
              title="Delete"
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </Button>
           )} 
        </div>
      ),
    })),
  };

  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Employee Payroll"}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
            <CardTitle className="d-flex gap-2 justify-content-end">
            <ParseToCsv data={rows} fileLabel="payrolls"/>

                {permissions.payrolls?.add && (
                <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() =>navigate(routerPath.addPayroll)}
                  >
                    Add to Payroll
                  </Button>
                )}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      
    </React.Fragment>
  );
};

export default PayrollTable;

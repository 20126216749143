// const baseUrl = "https://dev.kalalu.in/public";
const baseUrl = "https://api.kalalu.in/public";
// const baseUrl = "3.19.215.11";

//auth
export const login = `${baseUrl}/login`;

//users
export const loggedInUser = `${baseUrl}/users/me`; //user/me
export const allUser = `${baseUrl}/users/all`;
export const user = `${baseUrl}/users/view/`;
export const addUser = `${baseUrl}/users/add`;
export const updateUser = `${baseUrl}/users/edit/`;
export const deleteUser = `${baseUrl}/users/delete/`;

//user roles
export const allUserRoles = `${baseUrl}/user/roles/all`;
export const addUserRoles = `${baseUrl}/user/roles/add`;
export const viewUserRoles = `${baseUrl}/user/roles/view/`;
export const editUserRole = `${baseUrl}/user/roles/edit/`;
export const deleteUserRole = `${baseUrl}/user/roles/delete/`;

//buses
export const allBuses = `${baseUrl}/master/buses/all`;
export const addBus = `${baseUrl}/master/buses/add`;
export const busDetail = `${baseUrl}/master/buses/view/`; // e.g buses/{id}
export const deleteBus = `${baseUrl}/master/buses/delete/`; // e.g buses/{id}
export const editBus = `${baseUrl}/master/buses/edit/`; // e.g buses/{id}

//routes and services
export const allRoutes = `${baseUrl}/master/routes/all`;
export const viewRoute = `${baseUrl}/master/routes/view/`;
export const addRoute = `${baseUrl}/master/routes/add`;
export const deleteRoute = `${baseUrl}/master/routes/delete/`;
export const editRoute = `${baseUrl}/master/routes/edit/`;

export const allServices = `${baseUrl}/master/services/all`;
export const addService = `${baseUrl}/master/services/add`;
export const editService = `${baseUrl}/master/services/edit/`; //service id
export const serviceDetails = `${baseUrl}/master/services/view/`; //services/{serviceid}
export const deleteService = `${baseUrl}/master/services/delete/`; //services/{serviceid}

export const addServiceStop = `${baseUrl}/services/`; //{service_id}/stops/add
export const addServiceStopToNextIndex = `${baseUrl}/master/services/stops/rearrange/`; // /serviceid/index

export const deleteServiceStop = `${baseUrl}/services/`; //{service_id}
export const editServiceStop = `${baseUrl}/services/`; //{service_id}/stops/add

//stops
export const allStops = `${baseUrl}/master/stops/all`;
export const addStop = `${baseUrl}/master/stops/add`;
export const viewStop = `${baseUrl}/master/stops/view/`;
export const editStop = `${baseUrl}/master/stops/edit/`;
export const deleteStop = `${baseUrl}/master/stops/delete/`;

export const audioUpload = `${baseUrl}/master/stops/audio/add`;

//compliance
export const allCompliance = `${baseUrl}/master/buses/compliance`;

//Trips
export const allTrips = `${baseUrl}/trips/all`;
export const addTrip = `${baseUrl}/trips/add`;
export const viewTrip = `${baseUrl}/trips/view/`;
export const editTrip = `${baseUrl}/trips/edit/`;
export const deleteTrip = `${baseUrl}/trips/delete/`;

//Drivers
export const allDrivers = `${baseUrl}/master/drivers/all`;
export const addDriver = `${baseUrl}/master/drivers/add`;
export const viewDriver = `${baseUrl}/master/drivers/view/`;
export const editDriver = `${baseUrl}/master/drivers/edit/`;
export const deleteDriver = `${baseUrl}/master/drivers/delete/`;

//Cleaners
export const allCleaners = `${baseUrl}/master/cleaners/all`;
export const addCleaner = `${baseUrl}/master/cleaners/add`;
export const editCleaner = `${baseUrl}/master/cleaners/edit/`;
export const viewCleaner = `${baseUrl}/master/cleaners/view/`;
export const deleteCleaner = `${baseUrl}/master/cleaners/delete/`;

// //Staffs
// export const allStaffs = `${baseUrl}/master/Staffs/all`;
// export const addStaff = `${baseUrl}/master/Staffs/add`;
// export const editStaff = `${baseUrl}/master/Staffs/edit/`;
// export const viewStaff = `${baseUrl}/master/Staffs/view/`;
// export const deleteStaff = `${baseUrl}/master/Staffs/delete/`;

export const allPermisions = `${baseUrl}/user/permissions/check`;

//employee
export const allEmployees = `${baseUrl}/master/employees/all`;
export const addEmployee = `${baseUrl}/master/employees/add`;
export const viewEmployee = `${baseUrl}/master/employees/view/`;
export const editEmployee = `${baseUrl}/master/employees/edit/`;
export const deleteEmployee = `${baseUrl}/master/employees/delete/`;
export const userAvtarApi = `${baseUrl}/users/avatar/upload`;
export const documentApi = `${baseUrl}/employees/document/upload`;

//designation
export const allDesignations = `${baseUrl}/designations/all`;
export const addDesignation = `${baseUrl}/designations/add`;
export const viewDesignation = `${baseUrl}/designations/view/`;
export const editDesignation = `${baseUrl}/designations/edit/`;
export const deleteDesignation = `${baseUrl}/designations/delete/`;

export const allPayrolls = `${baseUrl}/payroll/all`;
export const addPayroll = `${baseUrl}/payroll/add`;
export const viewPayroll = `${baseUrl}/payroll/view/`;
export const editPayroll = `${baseUrl}/payroll/edit/`;
export const deletePayroll = `${baseUrl}/payroll/delete/`;

export const allPayrollComponents = `${baseUrl}/payroll/components/all`;
export const addPayrollComponent = `${baseUrl}/payroll/components/add`;
export const viewPayrollComponent = `${baseUrl}/payroll/components/view/`;
export const editPayrollComponent = `${baseUrl}/payroll/components/edit/`;
export const deletePayrollComponent = `${baseUrl}/payroll/components/delete/`;

export const allPayruns = `${baseUrl}/payroll/payruns/all`;
export const addPayrun = `${baseUrl}/payroll/payruns/add`;
export const viewPayrun = `${baseUrl}/payroll/payruns/view/`;
export const editPayrun = `${baseUrl}/payroll/payruns/edit/`;
export const deletePayrun = `${baseUrl}/payroll/payruns/delete/`;
export const reportByWeek = `${baseUrl}/payroll/payruns/filterByWeek/`;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allEmployees: any;
  allDesignation: any;
  employee: any;
  designation: any;
  allEmployeeDriver: any;
  allEmployeecleaner: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allEmployees: [],
  allDesignation: [],
  allEmployeeDriver: [],
  allEmployeecleaner: [],
  employee: {},
  designation: {},
  isSuccess: false,
};

interface allEmployeesProp {
  allEmployees: any;
}
interface allDesgnationProp {
  allDesignation: any;
}
interface allEmployeeDProp {
  allEmployeeDriver: any;
}
interface allEmployeeCProp {
  allEmployeeCleaner: any;
}

interface employeeProp {
  employee: any;
}
interface designstionProp {
  designstion: any;
}
interface busemployeeProp {
  isSuccess: boolean;
}

interface addemployeePayload {
  user_id: any;
  employee_id: any;
  photo_url: any;
  employee_name: any;
  designation: any;
  id_type: any;
  id_number: any;
  id_issued_by: any;
  id_issue_date: any;
  id_expiry_date: any;
  license_number: any;
  license_expiry_date: any;
  pan_number: any;
  pan_url: any;
  document_upload: any;
  contact_number: any;
  address: any;
  city: any;
  state: any;
  country: any;
  zip_code: any;
  emergency_contact_name: any;
  emergency_contact_number: any;
  emergency_contact_relation: any;
  status: any;
}
interface updateemployeePayload {
  id: any;
  user_id: any;
  employee_id: any;
  photo_url: any;
  employee_name: any;
  designation: any;
  id_type: any;
  id_number: any;
  id_issued_by: any;
  id_issue_date: any;
  id_expiry_date: any;
  license_number: any;
  license_expiry_date: any;
  pan_number: any;
  pan_url: any;
  document_upload: any;
  contact_number: any;
  address: any;
  city: any;
  state: any;
  country: any;
  zip_code: any;
  emergency_contact_name: any;
  emergency_contact_number: any;
  emergency_contact_relation: any;
  status: any;
}
interface addDesignationPayload {
  designation_name: any;
  status: any;
}
interface updateDesignationPayload {
  id: any;
  designation_name: any;
  status: any;
}
export const getAllEmployees = createAsyncThunk(
  "get all employees", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allEmployees, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setAllEmployees({
            allEmployees: data,
          })
        );
        let driver: any[] = [];
        let cleaner: any[] = [];
        let staff: any[] = [];

        data.filter((emp: any) => {
          if (emp.designation === "driver") {
            driver.push(emp);
          } else if (emp.designation === "cleaner") {
            cleaner.push(emp);
          } else {
            staff.push(emp);
          }
        });

        dispatch(
          setAllEmployeeDrivers({
            allEmployeeDriver: driver,
          })
        );
        dispatch(
          setAllEmployeeCleaner({
            allEmployeeCleaner: cleaner,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else if (response.status === 404 && data.status === 404) {
        toast.error("No employee found.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewemployee = createAsyncThunk(
  "add new employee",
  async (payload: addemployeePayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      const formData = new FormData();
      formData.append("user_id", payload?.user_id);
      formData.append("employee_id", payload?.employee_id);
      formData.append("photo_url", payload?.photo_url);
      formData.append("employee_name", payload?.employee_name);
      formData.append("designation", payload?.designation);
      formData.append("id_type", payload?.id_type);
      formData.append("id_number", payload?.id_number);
      formData.append("id_issued_by", payload?.id_issued_by);
      formData.append("id_issue_date", payload?.id_issue_date);
      formData.append("id_expiry_date", payload?.id_expiry_date);
      formData.append("license_number", payload?.license_number);
      formData.append("license_expiry_date", payload?.license_expiry_date);
      formData.append("pan_number", payload?.pan_number);
      formData.append("pan_url", payload?.pan_url);
      formData.append(
        "document_upload",
        JSON.stringify(payload?.document_upload)
      );
      formData.append("contact_number", payload?.contact_number);
      formData.append("address", payload?.address);
      formData.append("city", payload?.city);
      formData.append("state", payload?.state);
      formData.append("country", payload?.country);
      formData.append("zip_code", payload?.zip_code);
      formData.append(
        "emergency_contact_name",
        payload?.emergency_contact_name
      );
      formData.append(
        "emergency_contact_number",
        payload?.emergency_contact_number
      );
      formData.append(
        "emergency_contact_relation",
        payload?.emergency_contact_relation
      );
      formData.append("status", payload?.status);

      const response = await fetch(endpoints.addEmployee, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setemployeeState({
            isSuccess: true,
          })
        );
        dispatch(getAllEmployees());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deleteemployeeHandler = createAsyncThunk(
  "delete employee from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteEmployee}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllEmployees());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getemployeeDetail = createAsyncThunk(
  "get employee details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewEmployee}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setemployeeDetails({
            employee: data,
          })
        );
        toast.success("employee data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "update employee",
  async (payload: updateemployeePayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const formData = new FormData();
      formData.append("user_id", payload?.user_id);
      formData.append("employee_id", payload?.employee_id);
      formData.append("photo_url", payload?.photo_url);
      formData.append("employee_name", payload?.employee_name);
      formData.append("designation", payload?.designation);
      formData.append("id_type", payload?.id_type);
      formData.append("id_number", payload?.id_number);
      formData.append("id_issued_by", payload?.id_issued_by);
      formData.append("id_issue_date", payload?.id_issue_date);
      formData.append("id_expiry_date", payload?.id_expiry_date);
      formData.append("license_number", payload?.license_number);
      formData.append("license_expiry_date", payload?.license_expiry_date);
      formData.append("pan_number", payload?.pan_number);
      formData.append("pan_url", payload?.pan_url);
      formData.append(
        "document_upload",
        JSON.stringify(payload?.document_upload)
      );
      formData.append("contact_number", payload?.contact_number);
      formData.append("address", payload?.address);
      formData.append("city", payload?.city);
      formData.append("state", payload?.state);
      formData.append("country", payload?.country);
      formData.append("zip_code", payload?.zip_code);
      formData.append(
        "emergency_contact_name",
        payload?.emergency_contact_name
      );
      formData.append(
        "emergency_contact_number",
        payload?.emergency_contact_number
      );
      formData.append(
        "emergency_contact_relation",
        payload?.emergency_contact_relation
      );
      formData.append("status", payload?.status);

      const response = await fetch(`${endpoints.editEmployee}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success("Data Updated Successful.");
        // console.log(data);
        dispatch(
          setemployeeState({
            isSuccess: true,
          })
        );
        dispatch(getAllEmployees());
      } else {
        toast.error("Error while updating data.");
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getAllDesignation = createAsyncThunk(
  "get all designation", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allDesignations, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setAllDesignation({
            allDesignation: data,
          })
        );
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else if (response.status === 404 && data.status === 404) {
        toast.error("No designation found.");
      } else {
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const addNewDesignation = createAsyncThunk(
  "add new employee",
  async (payload: addDesignationPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.addDesignation, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log(response);
      if (response.status === 201) {
        toast.success("Designation Added Successfully.");
        // console.log(data);
        dispatch(
          setemployeeState({
            isSuccess: true,
          })
        );
        dispatch(getAllDesignation());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const updateDesignation = createAsyncThunk(
  "add new employee",
  async (payload: updateDesignationPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(
        `${endpoints.editDesignation}${payload.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getLocalStorage(
              localStorageActionType.GET_ACCESS_TOKEN
            )}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log(response);
      if (response.status === 200) {
        toast.success("Designation updated Successfully.");
        // console.log(data);
        dispatch(
          setemployeeState({
            isSuccess: true,
          })
        );
        dispatch(getAllDesignation());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const getDesignationDetail = createAsyncThunk(
  "get employee details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewDesignation}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setdesignationDetails({
            designstion: data,
          })
        );
        toast.success("Designation data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const deleteDesignationHandler = createAsyncThunk(
  "delete employee from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteDesignation}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllDesignation());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const employeeSlice = createSlice({
  name: "employee slice",
  initialState: initialState,
  reducers: {
    setAllEmployees: (state, action: PayloadAction<allEmployeesProp>) => {
      return {
        ...state,
        allEmployees: action.payload.allEmployees,
      };
    },
    setAllDesignation: (state, action: PayloadAction<allDesgnationProp>) => {
      return {
        ...state,
        allDesignation: action.payload.allDesignation,
      };
    },
    setAllEmployeeDrivers: (state, action: PayloadAction<allEmployeeDProp>) => {
      return {
        ...state,
        allEmployeeDriver: action.payload.allEmployeeDriver,
      };
    },
    setAllEmployeeCleaner: (state, action: PayloadAction<allEmployeeCProp>) => {
      return {
        ...state,
        allEmployeecleaner: action.payload.allEmployeeCleaner,
      };
    },
    setemployeeDetails: (state, action: PayloadAction<employeeProp>) => {
      return {
        ...state,
        employee: action.payload.employee,
      };
    },
    setdesignationDetails: (state, action: PayloadAction<designstionProp>) => {
      return {
        ...state,
        designation: action.payload.designstion,
      };
    },
    setemployeeState: (state, action: PayloadAction<busemployeeProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetemployeeState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllEmployees,
  setemployeeState,
  resetemployeeState,
  setemployeeDetails,
  setAllEmployeeCleaner,
  setAllEmployeeDrivers,
  setAllDesignation,
  setdesignationDetails,
} = employeeSlice.actions;
export default employeeSlice.reducer;

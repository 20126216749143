// Import necessary React and library components
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { useNavigate } from "react-router-dom";

// Import styles if needed
import "../datatables.scss";
import { routerPath } from "../../../routes/routes";
import ActionModal from "../../../components/Modal/ActionModal/ActionModal";
import { AppDispatch, RootState } from "../../../app/Store";
import { useDispatch, useSelector } from "react-redux";
import { deleteBusHandler } from "../../../features/busSlice";
import { getServiceData } from "../../../features/DashboardSlice";
import WarningModal from "../../../components/Modal/WarningModal/WarningModal";
import { deleteRouteHandler } from "../../../features/routeSlice";
import { FaRegTrashAlt } from "react-icons/fa";
import { PiEyeBold, PiNotePencilBold } from "react-icons/pi";
import ParseToCsv from "../../../components/JSONToCSV/JsonToCsv";

// Import necessary interfaces
interface RowData {
  routeId: any;
  origin: string;
  status: string;
  destination: string;
}

interface ColumnData {
  label: any;
  field: string;
  sort?: string;
  width?: number;
}

interface TableProp {
  columns: ColumnData[];
  rows: RowData[];
}

interface serviceProp {
  routeId: any;
  serviceId: any;
}
// Define the BusTable component
const RouteTable: React.FC<TableProp> = ({ columns, rows }) => {
  // Initialize the navigate function from react-router-dom
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openWarningModel, setOpenWarningModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [serviceData, setServiceData] = useState<serviceProp>({
    serviceId: "",
    routeId: "",
  });
  const { permissions } = useSelector((state: RootState) => state.permission);


  const services = useSelector(
    (state: RootState) => state.dashBoard.servicesData
  );

  useEffect(() => {
    if (serviceData.routeId === selectedId) {
      setOpenWarningModel(true);
    } else {
      setOpenDeleteModel(true);
    }
  }, [selectedId]);

  useEffect(() => {
    setOpenWarningModel(false);
    setOpenDeleteModel(false);
    dispatch(getServiceData());
  }, []);

  const handleDeleteModal = (id: any) => {
    services.map((item: any) => {
      if (item?.route_number === id) {
        // setOpenWarningModel(true);
        setServiceData({
          serviceId: item?.service_id,
          routeId: item?.route_number,
        });
      }
    });
    setSelectedId(id);
    // console.log(id);
  };

  const confirmDelete = () => {
    setOpenDeleteModel(false);
    dispatch(deleteRouteHandler(selectedId));
  };
  const confirmWarning = () => {
    setOpenWarningModel(false);
  };
  // Define the function to handle "View" button click
  const handleViewClick = (routeId: any) => {
    // Navigate to the bus detail page with the provided bus_id
    navigate(routerPath.view_route(routeId));
  };

  // Add a new column for the "View" button
  const newColumns = [
    ...columns,
    {
      label: <div className="text-center">Actions</div>,
      field: "actions",
      width: 100,
    },
  ];

  // Configure data for MDBDataTable with a custom "View" button
  const data = {
    columns: newColumns,
    rows: rows.map((rowData) => ({
      ...rowData,
      actions: (
        // <div className="d-flex justify-content-around align-items-center ">
        <div className="d-flex flex-wrap gap-1 justify-content-center align-items-center">
          {permissions.routes?.view&&<Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={() => handleViewClick(rowData.routeId)}
            title="View"
          >
            <PiEyeBold style={{ fontSize: "18px" }} />
          </Button>}
          {permissions.routes?.edit&&<Button
            type="button"
            color="info"
            className="waves-effect waves-light"
            onClick={
              () => 
              navigate(routerPath.update_route(rowData.routeId))
            }
            title="Update"
          >
            <PiNotePencilBold style={{ fontSize: "18px" }} />
          </Button>}
          {permissions.routes?.delete&&<Button
            type="button"
            color="danger"
            className="waves-effect waves-light"
            onClick={() => handleDeleteModal(rowData.routeId)}
          >
            <FaRegTrashAlt style={{ fontSize: "18px" }} />
          </Button>}
        </div>
      ),
    })),
  };

  // Render the component structure
  return (
    <React.Fragment>
      <ActionModal
        openModal={openDeleteModel}
        setOpenModal={setOpenDeleteModel}
        confirm={confirmDelete}
        title={"Delete"}
        content={"Route"}
      />
      <WarningModal
        openModal={openWarningModel}
        setOpenModal={setOpenWarningModel}
        confirm={confirmWarning}
        title={`Route`}
        content={`Service-${serviceData.serviceId}`}
        id={serviceData.serviceId}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="d-flex gap-2 justify-content-end">
              <ParseToCsv data={rows} fileLabel="route"/>
                { permissions.routes?.add&& <Button
                    type="button"
                    color="primary"
                    className="waves-effect waves-light"
                    onClick={() => navigate(routerPath.add_route)}
                  >
                    Add Route
                  </Button>}
              </CardTitle>
              <MDBDataTable responsive bordered noBottomColumns data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

// Export the BusTable component
export default RouteTable;

import styled from "styled-components";
import SpeedIcon from "@mui/icons-material/Speed";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import BusAlertIcon from "@mui/icons-material/BusAlert";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ClearIcon from "@mui/icons-material/Clear";
import PaymentsIcon from "@mui/icons-material/Payments";

import { TfiDashboard } from "react-icons/tfi";
import { GoDotFill } from "react-icons/go";
import { FaAngleLeft, FaAngleRight, FaBusAlt } from "react-icons/fa";
import { FaAngleDown, FaAngleDoubleRight } from "react-icons/fa";
import bg from "../../assets/ksmbgnew.jpg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TableViewIcon from "@mui/icons-material/TableView";
import TripOriginIcon from "@mui/icons-material/TripOrigin";
import PeopleIcon from "@mui/icons-material/People";

interface NavItemProps {
  isActive: boolean;
}

interface SubmenuProps {
  isSubmenuVisible: boolean;
}

export const StyledWrapper = styled.nav`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
`;

export const NavLogo = styled.img`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  object-fit: contain;
  max-width: 100px;
`;

export const LogoSection = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 5px 0 12px 0;
  /* border-bottom: 1px solid #e9ecef; */
  /* box-shadow: 0px 4px 44px rgb(0 0 0 / 12%); */

  margin-bottom: 30px;
`;

export const NavItems = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  overflow-y: auto;
  height: 80vh;
  /* Customize the scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #3b4854 #2b3a4a; /* thumb color, track color */

  /* WebKit */
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* thumb color */
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #263442; /* track color */
    border-radius: 5px;
  }
  /* border-radius: 5px; */
`;

export const NavMenuItem = styled.div<NavItemProps>`
  width: 100%;
  /* border-radius: 5px; */
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px 3px 10px 15px;
  transition: 0.4s;
  margin: 0px 0;
  background-color: ${(props) => (props.isActive ? "#263442" : "")};
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  &:hover {
    /* color: #ffffff; */
    color: #ffffff;
    background-color: #2c343b;
    span {
      & > svg {
        /* color: #cccccc; */
        color: #ffffff;
      }
    }
  }
  span {
    & > svg {
      font-size: 18px; /* Adjust the font-size for the icon */
    }
  }
`;

export const NavIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 15%;
  padding: 5px 10px 5px 5px;
  /* font-size: 10px; */
`;

export const NavLabel = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-weight: 400;
`;

export const LabelAndArrow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
`;

export const Option = styled.div<NavItemProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  font-weight: 400;
  font-size: 14px;
  padding: 8px 0 8px 8px;
  margin-left: 40px;
  margin-right: 0px;
  border-radius: 5px;
  background-color: ${(props) => (props.isActive ? "" : "")};
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  &:hover {
    /* color: #ffffff; */
    color: #ffffff;
    /* background-color: #2c343b; */
    span {
      & > svg {
        /* color: #cccccc; */
        color: #ffffff;
      }
    }
  }
  span {
    & > svg {
      font-size: 18px; /* Adjust the font-size for the icon */
    }
  }
`;

export const SubmenuContainer = styled.div<SubmenuProps>`
  width: 100%;
  box-sizing: border-box;
  display: ${(props) => (props.isSubmenuVisible ? "block" : "none")};
  /* background-color: #ffffff; */
  cursor: pointer;
`;

export const DashboardIcon1 = styled(DashboardIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  /* height: 10px; */

  font-weight: bolder;
`;
export const MasterIcon = styled(TableViewIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  /* height: 10px; */

  font-weight: bolder;
`;

export const TripIcon = styled(TripOriginIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  /* height: 10px; */

  font-weight: bolder;
`;
export const EmployeeIcon = styled(PeopleIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  /* height: 10px; */

  font-weight: bolder;
`;
export const PayrollIcon = styled(PaymentsIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  /* height: 10px; */

  font-weight: bolder;
`;

export const BusAlert = styled(BusAlertIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 20px;
`;
export const ScheduleBus = styled(DepartureBoardIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 20px;
  left: -5px;
`;
export const Bus = styled(DirectionsBusIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 18px;
`;
export const BusStop = styled(PlaceIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 18px;
`;
export const BusRoute = styled(AltRouteIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 18px;
`;
export const UserIcon = styled(PersonIcon)<NavItemProps>`
  color: ${(props) => (props.isActive ? "#ffffff" : "#cccccc")};
  height: 20px;
`;
export const DotSingleIcon = styled(GoDotFill)`
  height: 10px;
  margin-right: 4px;
`;
export const DownIcon = styled(FaAngleDown)`
  height: 15px;
  margin-right: 2px;
  margin-top: 5px;
`;
export const LeftIcon = styled(FaAngleRight)`
  height: 15px;
  margin-right: 2px;
  margin-top: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CancelIcon = styled(ClearIcon)`
  margin: 10px 10px 0 10px;
  height: 15px;
  color: #ffffff;
`;

export const LeftMenuSection = styled.div`
  /* display: flex; */
  /* flex-basis: 17%; */
  height: 100vh;
  width: 100%;
  /* border-right: 1px solid #f6f6f6; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #2b3a4a;
  /* background-image: url(${bg}); */
`;

interface StlMenu {
  open: boolean;
}

export const StyledMenu = styled.nav<StlMenu>`
  display: flex;
  height: 100vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #2b3a4a;
  /* background-image: url(${bg}); */
  /* border-right: 1px solid #ffffff; */
  box-shadow: ${({ open }) => (open ? "0px 0px 10px 1px" : "")};

  width: 250px;

  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  position: absolute;

  transition: transform 0.3s ease-in-out;
`;

import React from "react";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Button } from "reactstrap";

interface DocumentProps {
  data: {
    id: string;
    user_id: string;
    employee_id: string;
    photo_url: string;
    employee_name: string;
    designation: string;
    id_type: string;
    id_number: string;
    id_issued_by: string;
    id_issue_date: string;
    id_expiry_date: string;
    license_number: string;
    license_expiry_date: string;
    pan_number: string;
    pan_url: string;
    document_upload: {
      license_doc: string;
    };
    contact_number: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zip_code: string;
    emergency_contact_name: string;
    emergency_contact_number: string;
    emergency_contact_relation: string;
    status: string;
  };
  filename: string;
}

const statusMapping = (status: string) => {
  switch (status) {
    case '0':
      return 'Active';
    case '1':
      return 'Inactive';
    case '2':
      return 'Terminated';
    case '3':
      return 'Rest';
    default:
      return 'Unknown';
  }
};

const DownloadEmployeePdf: React.FC<DocumentProps> = ({ data, filename }) => {
  const downloadPdf = () => {
    const doc = new jsPDF();
    const title = data.employee_name;
    const pageWidth = doc.internal.pageSize.getWidth();

    // Add title centered
    doc.setFontSize(18);
    doc.setFont("helvetica", "bold");
    doc.text(title, pageWidth / 2, 20, { align: 'center' });

    // Define table columns and data
    const columns = [
      { header: 'Fields', dataKey: 'key' },
      { header: 'Value', dataKey: 'value' }
    ];

    const rows = [
      { key: 'ID', value: data.id },
      { key: 'User ID', value: data.user_id },
      { key: 'Employee ID', value: data.employee_id },
      { key: 'Employee Name', value: data.employee_name },
      { key: 'Designation', value: data.designation },
      { key: 'ID Type', value: data.id_type },
      { key: 'ID Number', value: data.id_number },
      { key: 'ID Issued By', value: data.id_issued_by },
      { key: 'ID Issue Date', value: data.id_issue_date },
      { key: 'ID Expiry Date', value: data.id_expiry_date },
      { key: 'License Number', value: data.license_number },
      { key: 'License Expiry Date', value: data.license_expiry_date },
      { key: 'PAN Number', value: data.pan_number },
      { key: 'Contact Number', value: data.contact_number },
      { key: 'Address', value: data.address },
      { key: 'City', value: data.city },
      { key: 'State', value: data.state },
      { key: 'Country', value: data.country },
      { key: 'Zip Code', value: data.zip_code },
      { key: 'Emergency Contact Name', value: data.emergency_contact_name },
      { key: 'Emergency Contact Number', value: data.emergency_contact_number },
      { key: 'Emergency Contact Relation', value: data.emergency_contact_relation },
      { key: 'Status', value: statusMapping(data.status) }
    ];

    try {
      // Add table to PDF with styles
      autoTable(doc, {
        columns: columns,
        body: rows,
        margin: { top: 10, left: 10, right: 10, bottom: 30 }, // Adjust bottom margin for date and time
        styles: {
          fontSize: 12,
          cellPadding: 2,
          valign: 'middle',
          lineColor: [44, 62, 80],
          lineWidth: 0.55,
          fontStyle: 'normal',
          overflow: 'linebreak',
        },
        headStyles: {
          fillColor: [135, 206, 235],
          textColor: [0, 0, 0],
          fontSize: 14,
          fontStyle: 'bold',
        },
        bodyStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        columnStyles: {
          key: { cellWidth: 50 },
          value: { cellWidth: 140 }
        },
        theme: 'grid',
      });

      // Add URLs at the end of the table
      const finalY = (doc as any).lastAutoTable.finalY || 40;

      doc.setFontSize(12);
      doc.setTextColor(0, 0, 255);
      doc.textWithLink('Photo URL', 10, finalY + 10, { url: data.photo_url });
      doc.textWithLink('PAN URL', 10, finalY + 20, { url: data.pan_url });
      
      doc.setFontSize(12);
      doc.textWithLink('License Document', 10, finalY + 30, { url: data.document_upload.license_doc });
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.text(`Employee ID`, 140, finalY + 20);
      doc.text(`: ${data.employee_id}`, 162, finalY + 20);

      doc.text(`Name`, 140, finalY + 30);
      doc.text(`: ${data.employee_name}`, 162, finalY + 30);


      // Get current date and time
      const now = new Date();
      const dateString = now.toLocaleDateString();
      const timeString = now.toLocaleTimeString();
      const footerText = `Generated on: ${dateString} ${timeString} ©${new Date().getFullYear()} KSM`;

      // Add date, time, and copyright at the bottom
      doc.setFontSize(10);
      const footerWidth = doc.getStringUnitWidth(footerText) * 10;
      const xPosition = pageWidth - footerWidth - 10;
      const footerYPosition = doc.internal.pageSize.getHeight() - 10;

      if (xPosition < 10) {
        doc.text(footerText, 10, footerYPosition);
      } else {
        doc.text(footerText, xPosition, footerYPosition);
      }

      doc.save(`${filename}.pdf`);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      alert("Failed to generate PDF. Please try again.");
    }
  };

  return (
    <Button
      type="button"
      color="primary"
      className="waves-effect waves-light"
      onClick={downloadPdf}
      aria-label="Download employee details as PDF"
    >
      Download PDF
    </Button>
  );
};

export default DownloadEmployeePdf;

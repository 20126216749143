import { useNavigate } from "react-router-dom";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { setLocalStorage } from "../localStorage/SetLocalStorage";
import { routerPath } from "../routes/routes";

import { FormElement, customValidationType } from "./InterFacesAndEnum";
import moment from "moment";
import { toast } from "react-toastify";

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const emailRegex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
const characterRegex = new RegExp(/^[a-zA-Z. ]*$/);
const numberRegex = new RegExp(/^[0-9]*$/);

interface validationProp {
  value: string;
  errorMessage: string;
}

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export const emailValidation = (value: string): validationProp => {
  let updatedEmail = value;
  let message = "";

  if (!emailRegex.test(updatedEmail)) {
    message = `Invalid Email,ie email should be like this : "example123@gmail.com".`;
  }
  if (updatedEmail.length > 100) {
    message = "Email should be less than 100 character";
  }
  return {
    value: updatedEmail.slice(0, 100),
    errorMessage: message,
  };
};

export const nameValidation = (value: string): validationProp => {
  let updatedName = value;
  let message = "";

  if (!characterRegex.test(updatedName)) {
    message = `Invalid Name,ie name should be in alphabets.`;
  }
  if (updatedName.length > 50) {
    message = "Name should be less than 50 character";
  }
  return {
    value: updatedName.slice(0, 50),
    errorMessage: message,
  };
};

export const characterValidation = (value: string): string => {
  let updatedString = value
    .split("")
    .map((character) => {
      if (characterRegex.test(character)) {
        return character;
      } else {
        return "";
      }
    })
    .join("");
  return updatedString;
};

export const numberValidation = (value: string): string => {
  let updatedString = value
    .split("")
    .map((character) => {
      if (numberRegex.test(character)) {
        return character;
      } else {
        return "";
      }
    })
    .join("");
  return updatedString;
};

export const mobileNumberValidation = (value: string): validationProp => {
  let updatedNumbers = numberValidation(value);
  let message = "";

  if (updatedNumbers.length < 11) {
    message =
      "Invalid mobile number i.e contact number length should not be less than 13 with its country code.";
  }

  return {
    value: updatedNumbers.slice(0, 13),
    errorMessage: message,
  };
};

export const titleValidation = (value: string): validationProp => {
  let updatedTitle = value;
  let message = "";

  if (updatedTitle.length > 200) {
    message = "Title should be less than 200 character";
  }

  return {
    value: updatedTitle.slice(0, 200),
    errorMessage: message,
  };
};

export const descriptionValidation = (value: string): validationProp => {
  let updatedDescription = value;
  let message = "";

  if (updatedDescription.length > 6000) {
    message = "Description should be less than 6000 character";
  }

  return {
    value: updatedDescription.slice(0, 6000),
    errorMessage: message,
  };
};

export const priceValidation = (value: string): validationProp => {
  let updatedPrice = value;
  let message = "";

  if (updatedPrice.length > 15) {
    message = "Price length should be less than 15.";
  }

  return {
    value: updatedPrice.slice(0, 15),
    errorMessage: message,
  };
};

export const addressValidation = (value: string): validationProp => {
  let updatedAddress = value;
  let message = "";

  if (updatedAddress.length > 100) {
    message = "Address should be less than 100 characters.";
  }

  return {
    value: updatedAddress.slice(0, 100),
    errorMessage: message,
  };
};

export const cityStateValidation = (value: string): validationProp => {
  let updatedCityStateValue = value;
  let message = "";

  if (updatedCityStateValue.length > 20) {
    message = "Should be less than 20 characters.";
  }

  return {
    value: updatedCityStateValue.slice(0, 20),
    errorMessage: message,
  };
};

export const zipValidation = (value: string): validationProp => {
  let updatedZip = value;
  let message = "";

  if (updatedZip.length > 10) {
    message = "Zip code length should be less than 10.";
  }

  return {
    value: updatedZip.slice(0, 10),
    errorMessage: message,
  };
};

interface ValidatedFormObj {
  value: string;
  isValidInput: boolean;
  errorMessage: string;
}

// method to return updated form input value state
// export const updateFormInputState =
// (event: React.ChangeEvent <HTMLTextAreaElement | HTMLInputElement>, values:FormElement[]):FormElement[] => {
//     const {name, value} = event.target;
//     let updatedArry:FormElement[] = values.map((formObj:FormElement):FormElement => {
//         if (formObj.id === name) {
//             let obj = Object.assign(formObj);
//             let validatedObj:ValidatedFormObj = validateFormElement(obj.customValidationType, value, obj.isRequired, formObj.isCustomValidationRequred);

//              obj["value"] = validatedObj.value;
//              obj["isTouched"] = true;
//              obj["errorMessage"] = validatedObj.errorMessage;
//              obj["isValidInput"] = validatedObj.isValidInput;
//              return obj;
//         } else {
//             return formObj;
//         }
//     });
//     return updatedArry
// };

export const updateFormInputState = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  values: FormElement[]
): FormElement[] => {
  const { name, value } = event.target;
  let updatedArry: FormElement[] = values.map(
    (formObj: FormElement): FormElement => {
      if (formObj.id === name) {
        let obj = Object.assign(formObj);
        let validatedObj: ValidatedFormObj = validateFormElement(
          obj.customValidationType,
          value,
          obj.isRequired,
          formObj.isCustomValidationRequred
        );

        if (
          formObj.id === "auctionTitle" ||
          formObj.id === "rewardGiftName" ||
          formObj.id === "coinTitle" ||
          formObj.id === "emailSubject"
        ) {
          const enteredTitle = value;
          if (enteredTitle.length > 200) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "auctionDesc" || formObj.id === "coinDescription") {
          const enteredDescription = value;
          if (enteredDescription.length > 6000) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (
          formObj.id === "auctionPrice" ||
          formObj.id === "auctionProposedPrice" ||
          formObj.id === "rewardAmount" ||
          formObj.id === "coinPrice" ||
          formObj.id === "bronzeTicketPrice" ||
          formObj.id === "silverTicketPrice" ||
          formObj.id === "goldTicketPrice" ||
          formObj.id === "platinumTicketPrice"
        ) {
          const enteredPrice = value;
          if (enteredPrice.length > 15) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "address") {
          const enteredAddress = value;
          if (enteredAddress.length > 100) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "city" || formObj.id === "state") {
          const enteredValue = value;
          if (enteredValue.length > 20) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "pincode") {
          const enteredValue = value;
          if (enteredValue.length > 10) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "userName") {
          const enteredValue = value;
          if (enteredValue.length > 50) {
            // console.log("sorry")
          } else {
            obj["value"] = validatedObj.value;
            obj["isTouched"] = true;
            obj["errorMessage"] = validatedObj.errorMessage;
            obj["isValidInput"] = validatedObj.isValidInput;
          }
        }

        if (formObj.id === "mobileNumber" || formObj.id === "userMobile") {
          const enterNumer = value.trim();
          if (enterNumer.length > 13) {
            // not accept number digits which is greter than 13
          } else {
            if (enterNumer.length > 0 && enterNumer.charAt(0) !== "+") {
              obj["value"] = "+" + enterNumer;
              obj["isTouched"] = true;
              obj["errorMessage"] = validatedObj.errorMessage;
              obj["isValidInput"] = validatedObj.isValidInput;
            } else {
              obj["value"] = enterNumer;
              obj["isTouched"] = true;
              obj["errorMessage"] = validatedObj.errorMessage;
              obj["isValidInput"] = validatedObj.isValidInput;
            }
          }
        } else {
          obj["value"] = validatedObj.value;
          obj["isTouched"] = true;
          obj["errorMessage"] = validatedObj.errorMessage;
          obj["isValidInput"] = validatedObj.isValidInput;
        }

        return obj;
      } else {
        return formObj;
      }
    }
  );
  return updatedArry;
};

export const updateFormSelectState = (
  selectedValue: string,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      let validatedObj: ValidatedFormObj = validateFormElement(
        obj.customValidationType,
        selectedValue,
        obj.isRequired,
        formObj.isCustomValidationRequred
      );
      obj["value"] = validatedObj.value;
      obj["isTouched"] = true;
      obj["errorMessage"] = validatedObj.errorMessage;
      obj["isValidInput"] = validatedObj.isValidInput;
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

export const updateFormTimeState = (
  date: Date | null,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      obj["value"] = obj.value;
      obj["isTouched"] = true;
      obj["errorMessage"] = "";
      obj["selectedTime"] = date;
      obj["isValidInput"] = "";
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

export const updateFormDate = (
  date: Date | null,
  name: string,
  formValues: FormElement[]
): FormElement[] => {
  let updatedArray = formValues.map((formObj) => {
    if (formObj.id === name) {
      let obj = Object.assign(formObj);
      obj["value"] = date;
      obj["isTouched"] = true;
      obj["errorMessage"] = "";
      obj["slectedDate"] = date;
      obj["selectedTime"] = date;
      obj["isValidInput"] = "";
      return obj;
    } else {
      return formObj;
    }
  });
  return updatedArray;
};

// method will validate the input with empty and custom validation
const validateFormElement = (
  validationType: customValidationType,
  value: string,
  isRequired: boolean,
  customValidationRequired: boolean
): ValidatedFormObj => {
  console.log(validationType);
  let updatedValue = value;
  let validatedObj = {
    value: updatedValue,
    isValidInput: false,
    errorMessage: "",
  };
  if (isRequired && updatedValue.length === 0) {
    return {
      value: updatedValue,
      isValidInput: false,
      errorMessage: "value should not be empty",
    };
  }

  if (customValidationRequired && updatedValue.length > 0) {
    switch (validationType) {
      // case customValidationType.emailValidation:
      //     return (validatedObj = {
      //         value: updatedValue,
      //         isValidInput: validateEmail(updatedValue),
      //         errorMessage: validateEmail(updatedValue) ? "" : "Invalid email id",
      //     });
      case customValidationType.characterValidation:
        return (validatedObj = {
          value: characterValidation(updatedValue),
          isValidInput: true,
          errorMessage: "",
        });
      case customValidationType.mobileValidation:
        let mobileValidateObj = mobileNumberValidation(updatedValue);
        return {
          value: mobileValidateObj.value,
          isValidInput: mobileValidateObj.errorMessage.length > 0,
          errorMessage: mobileValidateObj.errorMessage,
        };
      case customValidationType.emailValidation:
        let emailValidateObj = emailValidation(updatedValue);
        return {
          value: emailValidateObj.value,
          isValidInput: emailValidateObj.errorMessage.length > 0,
          errorMessage: emailValidateObj.errorMessage,
        };

      case customValidationType.titleValidation:
        let titleVadidationObj = titleValidation(updatedValue);
        return {
          value: titleVadidationObj.value,
          isValidInput: titleVadidationObj.errorMessage.length > 0,
          errorMessage: titleVadidationObj.errorMessage,
        };
      case customValidationType.descriptionValidation:
        let descriptionVadidationObj = descriptionValidation(updatedValue);
        return {
          value: descriptionVadidationObj.value,
          isValidInput: descriptionVadidationObj.errorMessage.length > 0,
          errorMessage: descriptionVadidationObj.errorMessage,
        };
      case customValidationType.priceValidation:
        let priceVadidationObj = priceValidation(updatedValue);
        return {
          value: priceVadidationObj.value,
          isValidInput: priceVadidationObj.errorMessage.length > 0,
          errorMessage: priceVadidationObj.errorMessage,
        };
      case customValidationType.adressValidation:
        let addressVadidationObj = addressValidation(updatedValue);
        return {
          value: addressVadidationObj.value,
          isValidInput: addressVadidationObj.errorMessage.length > 0,
          errorMessage: addressVadidationObj.errorMessage,
        };
      case customValidationType.cityStateValidation:
        let cityStateVadidationObj = cityStateValidation(updatedValue);
        return {
          value: cityStateVadidationObj.value,
          isValidInput: cityStateVadidationObj.errorMessage.length > 0,
          errorMessage: cityStateVadidationObj.errorMessage,
        };
      case customValidationType.zipValidation:
        let zipVadidationObj = zipValidation(updatedValue);
        return {
          value: zipVadidationObj.value,
          isValidInput: zipVadidationObj.errorMessage.length > 0,
          errorMessage: zipVadidationObj.errorMessage,
        };
      case customValidationType.nameValidation:
        let nameVadidationObj = nameValidation(updatedValue);
        return {
          value: nameVadidationObj.value,
          isValidInput: nameVadidationObj.errorMessage.length > 0,
          errorMessage: nameVadidationObj.errorMessage,
        };
      default:
        return validatedObj;
    }
  }

  return validatedObj;
};

// method to validate complete form
export const validateForm = (formElements: FormElement[]) => {
  let isValidForm = true;
  for (let element of formElements) {
    if (
      element.isRequired ||
      (element.isCustomValidationRequred && element.errorMessage.length > 0)
    ) {
      isValidForm = false;
      break;
    }
  }
  return isValidForm;
};

// export const validateCompleteForm = (formElements: ) => {}

// method to add padding zero to the incoming digit
// incoming value 8 it will return 08
export const addPaddingZero = (digit: number): string =>
  digit > 9 ? `${digit}` : `0${digit}`;

export const monthNames = (monthNumber: number): string => {
  switch (monthNumber) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "NA";
  }
};

export function formatLocalDateTimeFromISO(apiDate: string): string {
  // Create a Date object from the API date string
  const date = new Date(apiDate);

  // Format the date and time in the user's local time zone
  const formattedDateTime = date.toLocaleString(); // Automatically adjusts to the user's local time zone

  return formattedDateTime;
}

//   // Example usage:
//   const apiDate = "2023-10-13T00:01:53.000+00:00";
//   const formattedDateTime = formatLocalDateTimeFromISO(apiDate);
//   console.log(formattedDateTime); // Output in the user's local time zone

export const transformDateTime = (inputDate: string): string => {
  const date = new Date(inputDate);

  // Extract date components in UTC
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Create an array of month names
  const monthNames: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Convert hours to 12-hour format and determine AM/PM
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  // Zero-padding for minutes
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Format the date and time string
  const formattedDate = `${day} ${monthNames[monthIndex]} ${year} at ${formattedHours}:${formattedMinutes}${amPm}`;

  return formattedDate;
};

// Function to transform DOB format
export const transformDOB = (inputDOB: string): string => {
  const dateObject = new Date(inputDOB);

  const day = dateObject.getDate();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();

  return `${day} ${month} ${year}`;
};

export const transformDate = (dateObj: Date): string => {
  let date = new Date(dateObj);

  const addPaddingZero = (num: number) => {
    return num < 10 ? `0${num}` : num.toString();
  };

  const hour = date.getHours();
  const isAM = hour < 12;
  const hour12 = hour % 12 || 12; // Convert to 12-hour format

  return `${date.getDate()} ${monthNames(
    date.getMonth()
  )} ${date.getFullYear()} at ${addPaddingZero(hour12)}:${addPaddingZero(
    date.getMinutes()
  )} ${isAM ? "AM" : "PM"}`;
};

export const transformTimeWithAMPM = (timeString: any): any => {
  let parts = timeString.split(":");
  let hours = parseInt(parts[0]);
  let minutes = parseInt(parts[1]);

  let period = "AM";

  if (hours >= 12) {
    period = "PM";
  }

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12; // Set hours to 12 if it was originally 0
  }

  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};

export const convertToUTC = (date: any, time: any): any => {
  let string = `'${date} ${time} UTC'`;
  return transformDate(new Date(string));
};
export const printTimeUTCtoLocal = (inputDateString: any, time: any): any => {
  let string = `'${inputDateString} ${time} UTC'`;
  const date = new Date(string);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hour12 = hours % 12 || 12;
  const formattedTime = `${hour12.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
  return formattedTime;
};

export const getCurrentDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const sortTableValues = (
  response: any,
  objeckKey: string,
  isSortAsc: boolean
) => {
  let arrayForSort = [...response];
  let sortedResponse = arrayForSort.sort((obj1: any, obj2: any) => {
    if (isSortAsc) {
      return obj1[objeckKey] - obj2[objeckKey];
    } else {
      return obj2[objeckKey] - obj1[objeckKey];
    }
  });

  return sortedResponse;
};

// method to create pagination
export const tablePagination = (response: any, pageNumber: number) => {
  let startPageNumber = pageNumber * 10 - 10;
  let endPageNumber = pageNumber * 10;

  let totalPageNumbers = Math.ceil(response.length / 10);

  let responseObj = {
    isValidResponse: false,
    pageNumber: pageNumber,
    data: [],
  };

  if (totalPageNumbers >= pageNumber) {
    responseObj["pageNumber"] = pageNumber;
    responseObj["isValidResponse"] = true;
    responseObj["data"] = response.slice(startPageNumber, endPageNumber);
  }

  return responseObj;
};

export const searchTableData = (response: any, searchKey: string) => {
  if (response.length > 0) {
    let filteredResponse: any = [];
    for (let rowObj of response) {
      let values = Object.values(rowObj).join(" ");
      var re = new RegExp(searchKey, "gi");
      if (values.match(re) !== null) {
        filteredResponse.push(rowObj);
      }
    }
    return filteredResponse;
  }
};

export const transformGMTToUTC = (dateString: any) => {
  // console.log(dateString)
  // format("YYYY-MM-DDTHH:mm:ss")
  let date = new Date(dateString);
  let modifiedDate: any = moment
    .utc(date)
    .format("YYYY-MM-DDTHH:mm:ss")
    .valueOf();
  //  modifiedDate = new Date(modifiedDate).toString();

  // console.log(modifiedDate)
  return modifiedDate;
};

export function convertDateToUTC(localTime: Date): any {
  console.log(localTime);
}

export const convertCamelCaseToReadableWord = (label: string) => {
  let updatedString = "";
  if (label !== undefined && label !== null && label.length > 0) {
    for (let i = 0; i < label.length; i++) {
      let character = label.charAt(i);
      if (character === character.toUpperCase()) {
        updatedString += " " + character;
      } else {
        updatedString += character;
      }
    }
  }
  return updatedString;
};

export const handle500Status = () => {
  setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
  window.location.href = routerPath.login;
  toast.error("Session Timeout, Please login again.");
};

export function formatTimeWithUserTimeZone(selectedTime: string): string {
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Create a Date object for the current date and time in the user's timezone
  const currentDate = new Date();
  const userTimeZoneDate = new Date(
    currentDate.toLocaleString("en-US", { timeZone: userTimeZone })
  );

  // Split the selected time to extract hours, minutes, and seconds
  const [hours, minutes, seconds] = selectedTime.split(":");

  // Set the time components to the user's timezone date
  userTimeZoneDate.setHours(
    parseInt(hours, 10),
    parseInt(minutes, 10),
    parseInt(seconds, 10)
  );

  // Define the formatting options for the date string
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
    timeZone: userTimeZone,
  };

  // Get the formatted date string
  const formattedDate = userTimeZoneDate.toLocaleString("en-US", options);

  return formattedDate;
}

// Example usage:
//   const selectedTime = "12:44:00";
//   const formattedTime = formatTimeWithUserTimeZone(selectedTime);
//   console.log(formattedTime);

export const emptyFormState = (formArr: any): any => {
  formArr.form.map((formObj: any) => {
    if (formObj.value.length !== 0) {
      // window.location.reload()
      let obj = Object.assign(formObj);

      obj["value"] = "";
      obj["isTouched"] = true;
      obj["errorMessage"] = "";
      obj["isValidInput"] = "";
      return obj;
    } else {
      return formObj;
    }
    // console.log(ele.value)
  });
};

export function convertTo12HourFormat(militaryTime: any) {
  const [hours, minutes, seconds] = militaryTime.split(":");
  let ampm = "AM";

  let formattedHours = parseInt(hours, 10);
  if (formattedHours >= 12) {
    ampm = "PM";
    if (formattedHours > 12) {
      formattedHours -= 12;
    }
  }

  const twelveHourFormat = `${formattedHours}:${minutes}:${seconds} ${ampm}`;
  return twelveHourFormat;
}

export function formatDateTime(start_date: string, start_time: string): string {
  // Convert start_date and start_time to a Date object
  const dateComponents = start_date.split("-").map(Number);
  const timeComponents = start_time.split(":").map(Number);

  const combinedDateTime = new Date(
    dateComponents[2],
    dateComponents[1] - 1,
    dateComponents[0],
    timeComponents[0],
    timeComponents[1],
    timeComponents[2]
  );

  // Check for invalid date
  if (isNaN(combinedDateTime.getTime())) {
    return "Invalid Date";
  }

  // Format date as "DD-Mon-YYYY"
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const formattedDate = combinedDateTime.toLocaleDateString(
    "en-US",
    dateOptions
  );

  // Format time as "hh:mm AM/PM"
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = combinedDateTime.toLocaleTimeString(
    "en-US",
    timeOptions
  );

  // Combine formatted date and time
  const result = `${formattedDate} at ${formattedTime}`;

  return result;
}

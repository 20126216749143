
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../features/topbarHeadingSlice";

import PayrunTable from "../../Tables/PayrolTable/PayrollRunsTable";
import { getAllPayrollRuns, resetPayrollRunsState } from "../../../features/payrollRunsSlice";
import { RootState } from "../../../app/Store";

const data = [
  {
      id: "1",
      start_date: "2024-04-10",
      end_date: "2024-04-16",
      pay_run_date: "2024-08-10",
      status: "pending"
  }
]

const PayrollRuns = () => {
  document.title = "Payroll runs | KSM - Admin Dashboard.";
const {allPayrollRuns}=useSelector((state:RootState)=>state.payruns)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Payroll runs",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Payroll", link: "#" },
          { title: "runs", link: "#" },

        ],
      })
    );
    dispatch(getAllPayrollRuns());
    dispatch(resetPayrollRunsState());

  }, []);
  const columnData=[
    {
      label: 'Sr.No',
      field: 'id',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Start Date',
      field: 'start_date',
      sort: 'asc',
      width: 150
    },
    {
      label: 'End Date',
      field: 'end_date',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Pay Run Date',
      field: 'pay_run_date',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Status',
      field: 'status',
      sort: 'asc',
      width: 150
    },
]
  return <div>
      <PayrunTable columns={columnData} rows={allPayrollRuns}/>

    
  </div>;
};


export default PayrollRuns
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../../../features/topbarHeadingSlice";
import { Wrapper } from "../../../Services/AddService/StyledAddService";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { filterPayRunByWeek } from "../../../../features/payrollRunsSlice";
import { RootState } from "../../../../app/Store";
import { convertToBlobLink, generatePdf } from "./downloadReport";

const GenerateReport = () => {
  document.title = "Generate Payroll Report| KSM - Admin Dashboard.";
  const dispatch = useDispatch();
  const [resportType, setReporttype] = useState("");
  const { weeklyReport, isSuccess } = useSelector(
    (state: RootState) => state.payruns
  );
  const [week, setWeek] = useState("");
  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Generate Payroll Report",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Payrun", link: "#" },
          { title: "Payroll Report Generation", link: "#" },
        ],
      })
    );
  }, []);
  useEffect(() => {
    if (isSuccess === true) {
      // convertToBlobLink(data)
      if (resportType === "csv") {
        convertToBlobLink(weeklyReport, Number(week));
      } else if (resportType === "pdf") {
        generatePdf(weeklyReport, Number(week));
      } else {
        return;
      }
    }
  }, [isSuccess]);

  // Generate years options (e.g., last 10 years)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => currentYear - i);

  // Generate weeks options (1 to 52)
  const weeks = Array.from({ length: 52 }, (_, i) => i + 1);

  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      console.log(values);
    }
  };
  const handleSubmitWeekly = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      // console.log(values);
      // if (values.report_type === "csv") {
      console.log(values);
      setReporttype(values.report_type);
      setWeek(values.week);
      dispatch(filterPayRunByWeek(values.week));
      // }
    }
  };
  return (
    <Wrapper>
      <Card>
        <CardBody>
          {/* <h4 className="card-title">Employee Payroll Report</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="pfrom-date">From Date</Label>
                  <AvField
                    name="from-date"
                    type="date"
                    errorMessage="Please select from date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="pfrom-date"
                  />
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="pto-date">To Date</Label>
                  <AvField
                    name="to-date"
                    type="date"
                    errorMessage="Please select to date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="pto-date"
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="preport-type">Report Type</Label>
                  <AvField
                    name="report-type"
                    type="select"
                    errorMessage="Please select report type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type"
                  >
                    <option value="">Select Report Type</option>
                    <option value="csv">CSV</option>
                    <option value="pdf">PDF</option>
                  </AvField>
                </div>
              </Col>
              
              
            </Row>
            
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Generate Report
              </Button>
            </div>
          </AvForm>
          <div className='m-4'>
            <h2 className='text-center text-secondary'>OR</h2>
          </div> */}
          <h4 className="card-title">Weekly Employee Payroll Report</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmitWeekly}>
            <Row>
              {/* <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="pyear">Year</Label>
                  <AvField
                    name="year"
                    type="select"
                    errorMessage="Please select year."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="pyear"
                  >
                    <option value="">Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col> */}
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="pweek">Week Number</Label>
                  <AvField
                    name="week"
                    type="select"
                    errorMessage="Please select week number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="pweek"
                  >
                    <option value="">Select Week</option>
                    {weeks.map((week) => (
                      <option key={week} value={week}>
                        Week {week}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="preport-type">Report Type</Label>
                  <AvField
                    name="report_type"
                    type="select"
                    errorMessage="Please select report type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type"
                  >
                    <option value="">Select Report Type</option>
                    <option value="csv">CSV</option>
                    <option value="pdf">PDF</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Generate Report
              </Button>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default GenerateReport;

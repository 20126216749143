export const adminPermissions = {
  id: "1",
  role_name: "admin",
  permissions: {
    buses: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    stops: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    trips: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    users: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    routes: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    staffs: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    designations: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payrolls: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payrollComponents: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    services: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    userrole: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    employees: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
    payruns: {
      add: true,
      edit: true,
      view: true,
      delete: true,
      view_list: true,
    },
  },
  created_by: "1",
  updated_by: "1",
  created_at: "2024-08-16 18:34:23",
  updated_at: "2024-08-16 19:19:20",
  deleted_at: null,
};

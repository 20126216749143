import { useDispatch, useSelector } from "react-redux"
import { StyledBurger } from "./StyledWrapper"
import { RootState } from "../../app/Store"
import { toggleLeftMenu } from "../../features/leftMenuSlice"



const HumBurger:React.FC = () => {

  const dispatch = useDispatch();
  const openMenu = useSelector((state:RootState)=>state.leftMenuSlice.open);

 const handleOpenMenu=()=>{
  dispatch(toggleLeftMenu({
    open:!openMenu
  }))
 }
  return (
    <StyledBurger open={openMenu} onClick={handleOpenMenu}>
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}

export default HumBurger;
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeHeading } from '../../../features/topbarHeadingSlice'
import EmployeeTable from '../../Tables/EmployeeTables/EmployeeTable'
import { RootState } from '../../../app/Store'
import { getAllEmployees, resetemployeeState } from '../../../features/employeesSlice'
import { resetUploasState } from '../../../features/uploaderSlice'



const data = [
    {id:1,username:"usr123",employee_id:"emp012",employee_name:"john doe",status:"Active"},
    {id:2,username:"usr123",employee_id:"emp012",employee_name:"john doe",status:"Active"},
    {id:2,username:"usr123",employee_id:"emp012",employee_name:"john doe",status:"Active"}

]
const EmployeeList = () => {
  document.title = "Employee list| KSM - Admin Dashboard.";
    
    const {allEmployees} = useSelector((state:RootState)=>state.employees);
console.log(allEmployees)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Employee list",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Employee list", link: "#" },
            ],
          })
        );
        dispatch(getAllEmployees());
        dispatch(resetUploasState());
        dispatch(resetemployeeState());

      }, []);



    const columnData=[
          {
            label: 'Sr.No',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Username',
            field: 'username',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee ID',
            field: 'employee_id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Employee Name',
            field: 'employee_name',
            sort: 'asc',
            width: 150
          },          
          {
            label: 'Role',
            field: 'designation',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Status',
            field: 'status',
            sort: 'asc',
            width: 150
          },
          
          
    ]
  return (
    <div>
      <EmployeeTable columns={columnData} rows={allEmployees}/>

    </div>
  )
}

export default EmployeeList
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { myPermissions } from "../constants/permissions";
import { handle500Status } from "../utility/Utility";

// Define the permissionState interface
export interface PermissionState {
  buses: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  trips: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  users: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  routes: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  staffs: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  drivers: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  cleaners: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };

  services: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  stops: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  userrole: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  employees: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  designations: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payrolls: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payrollComponents: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
  payruns: {
    add: boolean;
    edit: boolean;
    view: boolean;
    delete: boolean;
    view_list: boolean;
  };
}

interface initialStateProp {
  permissions: PermissionState;
}

const initialState: initialStateProp = {
  permissions: {
    buses: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    trips: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    users: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    routes: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    staffs: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    drivers: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    cleaners: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    services: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    stops: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    userrole: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    employees: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },

    payrolls: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    payrollComponents: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    payruns: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
    designations: {
      add: false,
      edit: false,
      view: false,
      delete: false,
      view_list: false,
    },
  },
};

interface permissionsProp {
  permissions: PermissionState;
}

export const getPermissions = createAsyncThunk(
  "get all permissions",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allPermisions, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      if (response.status === 200) {
        const data = await response.json();
        if (data.permissions?.all === "all") {
          dispatch(setPermissions({ permissions: myPermissions }));
        } else {
          dispatch(setPermissions({ permissions: data?.permissions }));
        }
        console.log(data);
      } else if (response.status === 500) {
        // handle500Status();
      } else {
        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const permissionsSlice = createSlice({
  name: "Permission slice",
  initialState: initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<permissionsProp>) => {
      state.permissions = action.payload.permissions;
    },
  },
});

export const { setPermissions } = permissionsSlice.actions;

export const permissionsSelector = (state: { permissions: initialStateProp }) =>
  state.permissions.permissions;

export default permissionsSlice.reducer;

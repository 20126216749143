import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/Store";
import { changeHeading } from "../../../features/topbarHeadingSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { routerPath } from "../../../routes/routes";
import { addNewRoute } from "../../../features/routeSlice";
import { getUserData } from "../../../features/DashboardSlice";
import {
  ImageUploader,
  documentUploader,
  setDocumentUrl,
  setImgUrl,
  setUserAvtarUrl,
  userAvtarUploader,
} from "../../../features/uploaderSlice";
import { addNewdriver } from "../../../features/driverSlice";
import {
  addNewemployee,
  getAllDesignation,
  getemployeeDetail,
  updateEmployee,
} from "../../../features/employeesSlice";
import DownloadEmployeePdf from "../../../components/JsonToPdf/DownloadEmployeePdf";
import DownloadImage from "../../../components/Download/DownloadImage";
import DownloadEmployeeCsv from "../../../components/JsonToPdf/DownloadEmployeeCsv";

interface SelectProp {
  username: string;
  id: any;
}
interface employeeState {
  id: any;
  user_id: any;
  employee_id: any;
  photo_url: any;
  employee_name: any;
  designation: any;
  id_type: any;
  id_number: any;
  id_issued_by: any;
  id_issue_date: any;
  id_expiry_date: any;
  license_number: any;
  license_expiry_date: any;
  pan_number: any;
  pan_url: any;
  document_upload: any;
  contact_number: any;
  address: any;
  city: any;
  state: any;
  country: any;
  zip_code: any;
  emergency_contact_name: any;
  emergency_contact_number: any;
  emergency_contact_relation: any;
  status: any;
}
const ViewEmployee: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { isSuccess, employee,allDesignation } = useSelector(
    (state: RootState) => state.employees
  );
  const { usersData } = useSelector((state: RootState) => state.dashBoard);
  const [selectedUser, setSelectedUser] = useState<any>({});
  const [selectedFormat, setSelectedFormat] = useState<any>("");

  const { imageUrl, userAvtar, document } = useSelector(
    (state: RootState) => state.uploader
  );
  const [formData, setFormData] = useState<employeeState>({
    id: "",
    user_id: "",
    employee_id: "",
    photo_url: "",
    employee_name: "",
    designation: "",
    id_type: "",
    id_number: "",
    id_issued_by: "",
    id_issue_date: "",
    id_expiry_date: "",
    license_number: "",
    license_expiry_date: "",
    pan_number: "",
    pan_url: "",
    document_upload: "",
    contact_number: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    emergency_contact_name: "",
    emergency_contact_number: "",
    emergency_contact_relation: "",
    status: "",
  });
  console.log(employee);

  useEffect(() => {
    const {
      id,
      user_id,
      employee_id,
      photo_url,
      employee_name,
      designation,
      id_type,
      id_number,
      id_issued_by,
      id_issue_date,
      id_expiry_date,
      license_number,
      license_expiry_date,
      pan_number,
      pan_url,
      document_upload,
      contact_number,
      address,
      city,
      state,
      country,
      zip_code,
      emergency_contact_name,
      emergency_contact_number,
      emergency_contact_relation,
      status,
    } = employee;
    setFormData({
      id,
      user_id,
      employee_id,
      photo_url,
      employee_name,
      designation,
      id_type,
      id_number,
      id_issued_by,
      id_issue_date,
      id_expiry_date,
      license_number,
      license_expiry_date,
      pan_number,
      pan_url,
      document_upload,
      contact_number,
      address,
      city,
      state,
      country,
      zip_code,
      emergency_contact_name,
      emergency_contact_number,
      emergency_contact_relation,
      status,
    });
    const user = usersData.find((user: SelectProp) => user.id === user_id);
    setSelectedUser(user);
    dispatch(
      setUserAvtarUrl({
        userAvtar: photo_url,
      })
    );
    dispatch(
      setImgUrl({
        imageUrl: pan_url,
      })
    );
    dispatch(
      setDocumentUrl({
        document: document_upload?.license_doc,
      })
    );
  }, [employee]);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "View Employee",
        isBackBtn: true,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Employee", link: "#" },
          { title: `${params.id}`, link: "#" },
        ],
      })
    );
    dispatch(getUserData());
    dispatch(getAllDesignation());
    dispatch(getemployeeDetail(params.id));
  }, []);

  useEffect(() => {
    if (isSuccess === true) {
      navigate(routerPath.employee);
    }
  }, [isSuccess]);

  const handleUserSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUsername = event.target.value;
    const user = usersData.find(
      (user: SelectProp) => user.id === selectedUsername
    );
    setSelectedUser(user);
  };
  console.log(selectedUser);

  const handleFormatSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const format = event.target.value;

    setSelectedFormat(format);
  };
  const downloadFile = async (url: string, filename: string) => {
    try {
      // Fetch the file from the URL
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");

      // Create a Blob from the response
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the body and click it to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <h4 className="card-title">View Employee</h4>

          <AvForm className="needs-validation">
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="Username">Username</Label>
                  <AvField
                    disabled
                    name="user_id"
                    type="select"
                    errorMessage="Please select username."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="Username"
                    onChange={handleUserSelect}
                    value={formData?.user_id}
                  >
                    <option value="">Select Username</option>
                    {usersData.map((userNameObj: SelectProp) => (
                      <option key={userNameObj.username} value={userNameObj.id}>
                        {userNameObj.username}
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="routeid">Employee ID</Label>
                  <AvField
                    disabled
                    name="employee_id"
                    placeholder="Employee ID"
                    type="text"
                    errorMessage="Please enter Employee ID."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="routeid"
                    value={formData?.employee_id}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="origin">Employee Name</Label>
                  <AvField
                    name="employee_name"
                    disabled
                    placeholder="employee name"
                    type="text"
                    errorMessage="Please provide a employee name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="origin"
                    value={formData?.employee_name}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="preport-type12">Employee Designation</Label>
                  <AvField
                  disabled
                    name="designation"
                    type="select"
                    errorMessage="Please select employee role."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="preport-type12"
                    value={formData?.designation}
                  >
                    <option value="">Select Employee Role</option>
                    {allDesignation.map((Obj: any) => (
                      <option
                      key={Obj?.id}
                      value={Obj?.id}
                      >
                         {Obj?.id} - {Obj?.designation_name} 
                      </option>
                    ))}
                  </AvField>
                </div>
              </Col>

              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination">ID type</Label>
                  <AvField
                    disabled
                    name="id_type"
                    placeholder="ID type"
                    type="text"
                    errorMessage="Please provide a valid ID type."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination"
                    value={formData?.id_type}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination2">ID number</Label>
                  <AvField
                    disabled
                    name="id_number"
                    placeholder="ID number"
                    type="text"
                    errorMessage="Please provide a valid ID number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination2"
                    value={formData?.id_number}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="destination1">ID issued by</Label>
                  <AvField
                    disabled
                    name="id_issued_by"
                    placeholder="ID issued by"
                    type="text"
                    errorMessage="Please provide ID issued by."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="destination1"
                    value={formData?.id_issued_by}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDissuedate">ID issue date</Label>
                  <AvField
                    disabled
                    name="id_issue_date"
                    placeholder="ID issue date"
                    type="date"
                    errorMessage="Please provide a ID issue date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDissuedate"
                    value={formData?.id_issue_date}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDexpirydate">ID expiry date</Label>
                  <AvField
                    disabled
                    name="id_expiry_date"
                    placeholder="ID expiry date"
                    type="date"
                    errorMessage="Please provide a ID expiry date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDexpirydate"
                    value={formData?.id_expiry_date}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="PANNumber">PAN Number</Label>
                  <AvField
                    disabled
                    name="pan_number"
                    placeholder="PAN Number"
                    type="text"
                    errorMessage="Please provide a PAN Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="PANNumber"
                    value={formData?.pan_number}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="PANNumber1">License Number</Label>
                  <AvField
                    disabled
                    name="license_number"
                    placeholder="License Number"
                    type="text"
                    errorMessage="Please provide a License Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="PANNumber1"
                    value={formData?.license_number}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="IDexpirydate1">License Expiry Date</Label>
                  <AvField
                    disabled
                    name="license_expiry_date"
                    placeholder="License Expiry Date"
                    type="date"
                    errorMessage="Please provide a License Expiry Date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="IDexpirydate1"
                    value={formData?.license_expiry_date}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="EmailAddress">Email Address</Label>
                  <AvField
                    disabled
                    name="email_address"
                    placeholder="Email Address"
                    type="text"
                    errorMessage="Please provide a Email Address."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="EmailAddress"
                    value={selectedUser?.email}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="ContactNumber">Contact Number</Label>
                  <AvField
                    disabled
                    name="contact_number"
                    placeholder="Contact Number"
                    type="number"
                    errorMessage="Please provide a Contact Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="ContactNumber"
                    value={formData?.contact_number}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="Address">Address</Label>
                  <AvField
                    disabled
                    name="address"
                    placeholder="Address"
                    type="textarea"
                    errorMessage="Please provide a Address."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="Address"
                    value={formData?.address}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="city">City</Label>
                  <AvField
                    disabled
                    name="city"
                    placeholder="City"
                    type="text"
                    errorMessage="Please provide a City."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="City"
                    value={formData?.city}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="State">State</Label>
                  <AvField
                    disabled
                    name="state"
                    placeholder="state"
                    type="text"
                    errorMessage="Please provide a State."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="State"
                    value={formData?.state}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="Country">Country</Label>
                  <AvField
                    disabled
                    name="country"
                    placeholder="Country"
                    type="text"
                    errorMessage="Please provide a Country."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="Country"
                    value={formData?.country}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="ZIP Code">ZIP Code</Label>
                  <AvField
                    disabled
                    name="zip_code"
                    placeholder="ZIP Code"
                    type="number"
                    errorMessage="Please provide a ZIP Code."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="ZIPCode"
                    value={formData?.zip_code}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    disabled
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={formData?.status}
                  >
                    <option value="">Select Status</option>
                    <option value="0">Active</option>
                    <option value="1">Inactive</option>
                    <option value="2">Terminated</option>
                    <option value="3">Rest</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="EmergencyContactName">
                    Emergency Contact Name
                  </Label>
                  <AvField
                    disabled
                    name="emergency_contact_name"
                    placeholder="Emergency Contact Name"
                    type="text"
                    errorMessage="Please provide a Emergency Contact Name."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="EmergencyContactName"
                    value={formData?.emergency_contact_name}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="EmergencyContactNumber">
                    Emergency Contact Number
                  </Label>
                  <AvField
                    disabled
                    name="emergency_contact_number"
                    placeholder="Emergency Contact Number"
                    type="number"
                    errorMessage="Please provide a Emergency Contact Number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="EmergencyContactNumber"
                    value={formData?.emergency_contact_number}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="EmergencyContactRelation">
                    Emergency Contact Relation
                  </Label>
                  <AvField
                    disabled
                    name="emergency_contact_relation"
                    placeholder="Emergency Contact Relation"
                    type="text"
                    errorMessage="Please provide a Emergency Contact Relation."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="EmergencyContactRelation"
                    value={formData?.emergency_contact_relation}
                  />
                </div>
              </Col>
            </Row>

            <hr />
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="currentPhoto">Photo</Label>
                </div>
              </Col>
              {userAvtar !== null && (
                <div className="d-flex justify-content-around align-items-center">
                  <img
                    src={userAvtar}
                    key={userAvtar}
                    alt="employeeImg"
                    width={"200px"}
                  />
                </div>
              )}
            </Row>
            <div className="d-flex justify-content-end">
              <DownloadImage imgUrl={userAvtar} filename="avtar" />
            </div>
            <hr />
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="currentPhoto1">Pan Card</Label>
                </div>
              </Col>
              {imageUrl !== null && (
                <div className="d-flex justify-content-around align-items-center">
                  <img
                    src={imageUrl}
                    key={imageUrl}
                    alt="driverImg"
                    width={"300px"}
                  />
                </div>
              )}
            </Row>
            <div className="d-flex justify-content-end">
              <DownloadImage imgUrl={imageUrl} filename="pan" />
            </div>
            <hr />
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="currentPhoto12">License</Label>
                </div>
              </Col>
              {document !== null && (
                <div className="d-flex justify-content-around align-items-center">
                  <embed
                    src={document}
                    width="500"
                    height="400"
                    type="application/pdf"
                  />
                </div>
              )}
            </Row>
            <hr />
            <Row>
              <Col md="4">
                <div className="mb-3">
                  <Label htmlFor="status12">Download Details</Label>
                  <AvField
                    name="download"
                    type="select"
                    className="form-control"
                    id="status12"
                    onChange={handleFormatSelect}
                  >
                    <option value="0">Select Format</option>
                    <option value="pdf">PDF</option>
                    <option value="csv">CSV</option>
                  </AvField>
                </div>
              </Col>
              <Col md="4">
                <div className=" mt-4 pt-1">
                  {selectedFormat === "pdf" ? (
                    <DownloadEmployeePdf
                      data={formData}
                      filename={`employee/${params.id}`}
                    />
                  ) :selectedFormat === "csv"? (
                    <DownloadEmployeeCsv
                      data={formData}
                      filename={`employee/${params.id}`}
                    />
                  ):''}
                </div>
              </Col>
            </Row>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

// Export the AddRoute component
export default ViewEmployee;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  busData: any;
  routeData: any;
  busNumberData: any;
  routeIdData: any;
  usersData: any;
  servicesData: any;
  tripsData: any;
  employeeData: any;
}

const initialState: initialStateProp = {
  busData: [],
  routeData: [],
  usersData: [],
  busNumberData: [],
  routeIdData: [],
  servicesData: [],
  tripsData: [],
  employeeData: [],
};

interface busDataProp {
  busData: any;
}

interface routesProp {
  routeData: any;
}
interface usesProp {
  usersData: any;
}
interface busNumberDataProp {
  busNumberData: any;
}

interface routeIdDataProp {
  routeIdData: any;
}

interface allServicesProp {
  servicesData: any;
}
interface allTripsProp {
  tripsData: any;
}
interface allEmployeesProp {
  employeeData: any;
}

export const getBusData = createAsyncThunk(
  "get all buses",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allBuses, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      if (response.status === 200) {
        // toast.success("All Buses Fetch successfully!.");
        let numberArr: any = [];
        const data = await response.json();
        dispatch(setBusData({ busData: data }));
        // console.log(data);

        data?.map((item: any) => {
          numberArr.push({ value: item.bus_number, label: item.bus_number });
          // console.log(numberArr);
        });
        dispatch(
          setBusNumberData({
            busNumberData: numberArr,
          })
        );
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        // toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getRouteData = createAsyncThunk(
  "get all routes", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allRoutes, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("Route Data Fetch SUccessfully!.");
        let routeArr: any = [];
        const data = await response.json();
        // console.log(data);
        dispatch(
          setRouteData({
            routeData: data,
          })
        );
        // Check the statusCode from the API response
        data?.map((item: any) => {
          routeArr.push({ value: item.routeId, label: item.routeId });
          // console.log(routeArr);
        });
        dispatch(
          setRouteIdData({
            routeIdData: routeArr,
          })
        );
        // console.log(routeArr);
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        // toast.error("Sorry, Something went wrong!.");

        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      // toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      // console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getUserData = createAsyncThunk(
  "get all users",
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allUser, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      if (!response.ok) {
        // Handle non-successful responses (e.g., 4xx, 5xx)
        throw new Error(`Failed to fetch users. Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data);
      if (response.status === 200) {
        // toast.success("All Users Fetch successfully!.");
        dispatch(setUsersData({ usersData: data }));
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        // toast.error("Sorry, Something went wrong!.");

        console.error(`Error: ${response.status}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      // console.log("Finally");
    }
  }
);

export const getServiceData = createAsyncThunk(
  "get all services", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allServices, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        // body: JSON.stringify(payload),
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("Services Data Fetch successfully!.");
        const data = await response.json();
        dispatch(
          setServicesData({
            servicesData: data,
          })
        );
        // console.log(data);
      } else if (response.status === 500) {
        handle500Status();
      } else {
        // Handle non-200 status codes here
        // toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      // toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      // console.log("Finally");
    }
  }
);

export const getTripsData = createAsyncThunk(
  "get all trips", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allTrips, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("trip Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setTripsData({
            tripsData: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        handle500Status();
        toast.error("internal server error.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      // console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
export const getEmployeeData = createAsyncThunk(
  "get all employees", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allEmployees, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setAllEmployees({
            employeeData: data,
          })
        );

        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else if (response.status === 404 && data.status === 404) {
        toast.error("No employee found.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);
const dashboardSlice = createSlice({
  name: "Dashboard slice",
  initialState: initialState,
  reducers: {
    setBusData: (state, action: PayloadAction<busDataProp>) => {
      return {
        ...state,
        busData: action.payload.busData,
      };
    },
    setRouteData: (state, action: PayloadAction<routesProp>) => {
      return {
        ...state,
        routeData: action.payload.routeData,
      };
    },
    setUsersData: (state, action: PayloadAction<usesProp>) => {
      return {
        ...state,
        usersData: action.payload.usersData,
      };
    },
    setBusNumberData: (state, action: PayloadAction<busNumberDataProp>) => {
      return {
        ...state,
        busNumberData: action.payload.busNumberData,
      };
    },
    setRouteIdData: (state, action: PayloadAction<routeIdDataProp>) => {
      return {
        ...state,
        routeIdData: action.payload.routeIdData,
      };
    },
    setServicesData: (state, action: PayloadAction<allServicesProp>) => {
      return {
        ...state,
        servicesData: action.payload.servicesData,
      };
    },
    setTripsData: (state, action: PayloadAction<allTripsProp>) => {
      return {
        ...state,
        tripsData: action.payload.tripsData,
      };
    },
    setAllEmployees: (state, action: PayloadAction<allEmployeesProp>) => {
      return {
        ...state,
        employeeData: action.payload.employeeData,
      };
    },
  },
});

export const {
  setBusData,
  setRouteData,
  setBusNumberData,
  setRouteIdData,
  setUsersData,
  setServicesData,
  setTripsData,
  setAllEmployees,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

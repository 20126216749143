import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  SectioTwo,
  Section,
  SectionWrapper,
  SectionWrapper2,
  Wrapper,
} from "./StyledDashboard";
import { Row, Col } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { changeHeading } from "../../features/topbarHeadingSlice";

import { AppDispatch, RootState } from "../../app/Store";
import { getAllUsers, getLoggedInUser } from "../../features/userSlice";
import { getAllBuses } from "../../features/busSlice";
import { getAllRoutes } from "../../features/routeSlice";
import Miniwidget from "../../components/Dashboard/Miniwidget/Miniwidget";
import ExampleGraph from "../../components/Dashboard/Graph/DashboardGraph";
import TotalDataGraph from "../../components/Dashboard/Graph/TotalDataGraph";



const Dashboard: React.FC = () => {
  document.title = "Dashboard | KSM - Admin Dashboard.";

  const { busData,routeData,servicesData,tripsData,employeeData}=useSelector((state:RootState)=>state.dashBoard);
  console.log(employeeData)
  const reports = [
    {
      title: "Trips",
      iconClass: "cube-outline",
      total: tripsData?.length,
      average: "+11%",
      badgecolor: "#bf9000",
    },
    {
      title: "Revenue",
      iconClass: "buffer",
      total: "$46,782",
      average: "-29%",
      badgecolor: "#2c5773",
    },
    {
      title: "Staff",
      iconClass: "tag-text-outline",
      total: employeeData?.length,
      average: "0%",
      badgecolor: "#bf9000",
    },
    {
      title: "Ticket Sold",
      iconClass: "briefcase-check",
      total: "1890",
      average: "+89%",
      badgecolor: "#2c5773",
    },
  ];
  const dispatch: AppDispatch = useDispatch();
  const buses: [] = useSelector((state: RootState) => state.dashBoard.busData);
  const routes: [] = useSelector(
    (state: RootState) => state.dashBoard.routeData
  );
  const users: [] = useSelector(
    (state: RootState) => state.dashBoard.usersData
  );
  const services: [] = useSelector(
    (state: RootState) => state.dashBoard.servicesData
  );

  console.log(services);

  useEffect(() => {
    dispatch(
      changeHeading({
        heading: "Dashboard",
        isBackBtn: false,
        breadcrumbItems: [
          { title: "Ksm", link: "#" },
          { title: "Dashboard", link: "#" },
        ],
      })
    );
  }, []);

  return (
    <Wrapper>
      <Miniwidget reports={reports} />
      <Row>
        <Col xl="8">
          {/* Monthly Earnings */}
          <ExampleGraph />
        </Col>

        <Col xl="4">
          {/* Email sent */}
          <TotalDataGraph />
        </Col>
      </Row>
      {/* <h5> i am Dashboard Page!!</h5> */}
    </Wrapper>
  );
};

export default Dashboard;

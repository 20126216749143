import React, { useState, FunctionComponent } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
// import user1 from "../../../assets/images/users/user-1.jpg";
import user1 from "../../assets/images/users/user-6.jpg";
import { setLocalStorage } from "../../localStorage/SetLocalStorage";
import * as localStorageActionType from "../../localStorage/ActionTypes";
import { toast } from "react-toastify";
import { routerPath } from "../../routes/routes";


const ProfileMenu: FunctionComponent = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const handleLogout=()=>{
    setLocalStorage(localStorageActionType.CLEAR_LOGIN_USER_DETAIL, "");
    toast.success("Logout Successfull!.")
    window.location.reload();
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={()=>navigate(routerPath.profile)}>
            <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
            Profile
          </DropdownItem>

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="mdi mdi-lock-open-outline font-size-17 text-muted align-middle me-1" />
            Lock screen
          </DropdownItem> */}

          <div className="dropdown-divider" />
          <DropdownItem onClick={handleLogout} className="dropdown-item text-danger ">
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span >Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;

import React, { useState } from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';

interface GraphState {
    options: {
        colors: string[];
        chart: {
            toolbar: {
                show: boolean;
            };
        };
        dataLabels: {
            enabled: boolean;
        };
        stroke: {
            curve: 'smooth'; // Updated value
            width: number;
        };
        grid: {
            borderColor: string;
            row: {
                colors: string[];
                opacity: number;
            };
        };
        xaxis: {
            categories: string[];
            axisBorder: {
                show: boolean;
            };
            axisTicks: {
                show: boolean;
            };
        };
        legend: {
            show: boolean;
        };
    };
    series: {
        name: string;
        data: number[];
    }[];
}

const ExampleGraph: React.FC = () => {
    const [state] = useState<GraphState>({
        options: {
            colors: ['#9D7701', '#D4A103', '#FCBF02'],
            chart: {
                toolbar: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
                width: 0.1,
            },
            grid: {
                borderColor: '#f8f8fa',
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5,
                },
            },
            xaxis: {
                categories: ['2011', '2012', '2013', '2014', '2015', '2016', '2017'],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
        },
        series: [
            {
                name: 'Series A',
                data: [0, 150, 60, 180, 90, 75, 30],
            },
            {
                name: 'Series B',
                data: [0, 45, 150, 36, 60, 240, 30],
            },
            {
                name: 'Series C',
                data: [0, 15, 195, 21, 360, 120, 30],
            },
        ],
    });

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Email Sent</h4>

                    <Row className="text-center mt-5">
                        <Col xs="4">
                            <h5 className="font-size-20">$ 89425</h5>
                            <p className="text-muted">Marketplace</p>
                        </Col>
                        <Col xs="4">
                            <h5 className="font-size-20">$ 56210</h5>
                            <p className="text-muted">Total Income</p>
                        </Col>
                        <Col xs="4">
                            <h5 className="font-size-20">$ 8974</h5>
                            <p className="text-muted">Last Month</p>
                        </Col>
                    </Row>

                    <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                        <ReactApexChart options={state.options} series={state.series} type="area" height="300" />
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ExampleGraph;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "../../styledPayroll";
import { Button, Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAllDesignation, getAllEmployees } from "../../../../features/employeesSlice";
import { RootState } from "../../../../app/Store";
import { routerPath } from "../../../../routes/routes";
import { useNavigate, useParams } from "react-router-dom";
import { getpayrollRunsDetail, updatepayrollRuns } from "../../../../features/payrollRunsSlice";
import { changeHeading } from "../../../../features/topbarHeadingSlice";

interface Employee {
  days_worked: any;
  total_pay: any;
  id: string;
  user_id: string;
  employee_id: string;
  photo_url: string;
  employee_name: string;
  designation: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  license_number: string;
  license_expiry_date: string;
  pan_number: string;
  pan_url: string;
  document_upload: {
    license_doc: string;
  };
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: string;
}

interface Designation {
  id: string;
  designation_name: string;
  status: string;
}

interface PayrollRunDetail {
  employee: {
    employee_id: string;
  };
  days_worked: number;
  total_pay: number;
}

interface PayrollRun {
  start_date: string;
  end_date: string;
  status: string;
  week_number: number;
  pay_details: PayrollRunDetail[];
  created_by?: number;
}

const ViewPayrun = () => {
  const { id } = useParams();
  document.title = "View Payrun | KSM - Admin Dashboard";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { allEmployees, allDesignation } = useSelector((state: RootState) => state.employees);
  const { isSuccess, payrollRuns } = useSelector((state: RootState) => state.payruns);
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Set<string>>(new Set());
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(changeHeading({
      heading: "View Payrun",
      isBackBtn: true,
      breadcrumbItems: [
        { title: "KSM", link: "#" },
        { title: "Payrun", link: "#" },
        { title: `${id}`, link: "#" },
      ],
    }));
    dispatch(getAllDesignation());
    dispatch(getAllEmployees());
    if (id) {
      dispatch(getpayrollRunsDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (payrollRuns?.pay_details) {
      const selectedIds: Set<string> = new Set(
        payrollRuns.pay_details.map((detail: PayrollRunDetail) => detail.employee.employee_id)
      );
      setSelectedEmployeeIds(selectedIds);
    }
  }, [payrollRuns]);

  useEffect(() => {
    if (isSuccess) {
      navigate(routerPath.payrollRuns);
    }
  }, [isSuccess, navigate]);

  const findDesignationIdByName = (name: string): string | undefined =>
    allDesignation.find((d: Designation) => d.designation_name === name)?.id;

  const handleCheckboxChange = (employee: Employee) => {
    setSelectedEmployeeIds(prevIds => {
      const newIds = new Set(prevIds);
      if (newIds.has(employee.id)) {
        newIds.delete(employee.id);
      } else {
        newIds.add(employee.id);
      }
      return newIds;
    });
  };

  const findPayDetail = (employeeId: string) => {
    const payDetails = payrollRuns?.pay_details || [];
    return payDetails.find(
      (detail: PayrollRunDetail) => detail?.employee?.employee_id === employeeId
    ) || { days_worked: 0, total_pay: 0 };
  };

  const formatSelectedEmployees = () => {
    return Array.from(selectedEmployeeIds).map(employeeId => {
      const employee = allEmployees.find((emp: Employee) => emp.id === employeeId);
      return employee ? {
        employee_id: employee.id,
        designation_id: findDesignationIdByName(employee.designation) || '',
      } : null;
    }).filter((item): item is { employee_id: string; designation_id: string } => item !== null);
  };
  const getDesignationName = (id: any) => {
    return allDesignation.map((designstion: any) => {
      if (designstion.id === id) {
        // console.log(designstion.designation_name);
        return designstion.designation_name;
      }
    });
  };
  const handleSubmit = (event: any, errors: any, values: any) => {
    if (errors.length === 0) {
      if (selectedEmployeeIds.size === 0) {
        setFormError("Please select at least one employee.");
        return;
      }
      setFormError(null); // Clear error if validation passes
      const payloadData = {
        ...values,
        id: id,
        employees: formatSelectedEmployees(),
        // created_by: payrollRuns?.created_by || 1,
        // updated_by: 1, // Adjust as needed
      };
      console.log(payloadData);
      dispatch(updatepayrollRuns(payloadData));
    }
  };

  const employeeRows = useMemo(() => {
    return allEmployees.map((employee: Employee) => {
      const payDetail = findPayDetail(employee.id);

      return (
        <tr key={employee.id}>
          <td>
            <input
              disabled
              type="checkbox"
              checked={selectedEmployeeIds.has(employee.id)}
              onChange={() => handleCheckboxChange(employee)}
            />
          </td>
          <td>{employee.employee_name}</td>
          <td className="text-capitalize">{getDesignationName(employee.designation)}</td>
          <td>{payDetail.days_worked}</td>
          <td>{payDetail.total_pay}</td>
        </tr>
      );
    });
  }, [allEmployees, payrollRuns?.pay_details, selectedEmployeeIds]);

  return (
    <Wrapper>
      <Card>
        <CardBody>
          <h4 className="card-title">View Payrun</h4>
          <AvForm className="needs-validation" onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="start_date1">Start Date</Label>
                  <AvField
                    disabled
                    name="start_date"
                    type="date"
                    placeholder="Select start date."
                    errorMessage="Please select a start date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="start_date1"
                    value={payrollRuns?.start_date || ''}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="end_date1">End Date</Label>
                  <AvField
                    disabled
                    name="end_date"
                    type="date"
                    placeholder="Select end date."
                    errorMessage="Please select an end date."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="end_date1"
                    value={payrollRuns?.end_date || ''}
                  />
                </div>
              </Col>

              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="status1">Status</Label>
                  <AvField
                    disabled
                    name="status"
                    type="select"
                    errorMessage="Please provide a valid status."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="status1"
                    value={payrollRuns?.status || ''}
                  >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="complete">Complete</option>
                  </AvField>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="week_number1">Week Number</Label>
                  <AvField
                    disabled
                    name="week_number"
                    type="number"
                    placeholder="Select week number."
                    errorMessage="Please select a week number."
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="week_number1"
                    value={payrollRuns?.week_number || ''}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="employees">Employees</Label>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Employee Name</th>
                        <th>Designation</th>
                        <th>Worked Days</th>
                        <th>Total Pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeRows}
                    </tbody>
                  </Table>
                  {formError && <div className="text-danger">{formError}</div>}
                </div>
              </Col>
            </Row>

            {/* Uncomment the button if needed */}
            {/* <div className="d-flex justify-content-end">
              <Button color="primary" type="submit">
                Submit
              </Button>
            </div> */}
          </AvForm>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default ViewPayrun;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as endpoints from "../networkUtility/endpoint";
import { toggleLoader } from "./loaderSlice";
import { toast } from "react-toastify";
import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import { handle500Status } from "../utility/Utility";

interface initialStateProp {
  allDriver: any;
  driver: any;
  isSuccess: boolean;
}

const initialState: initialStateProp = {
  allDriver: [],
  driver: {},
  isSuccess: false,
};

interface allDriverProp {
  allDriver: any;
}
interface driverProp {
  driver: any;
}

interface busdriverProp {
  isSuccess: boolean;
}

interface adddriverPayload {
  user_id: any;
  employee_id: string;
  photo_url: string;
  driver_name: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  pan_number: string;
  license_number: string;
  license_expiry_date: string;
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: any;
}
interface updatedriverPayload {
  id: any;
  user_id: any;
  employee_id: string;
  photo_url: string;
  driver_name: string;
  id_type: string;
  id_number: string;
  id_issued_by: string;
  id_issue_date: string;
  id_expiry_date: string;
  pan_number: string;
  license_number: string;
  license_expiry_date: string;
  contact_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  emergency_contact_name: string;
  emergency_contact_number: string;
  emergency_contact_relation: string;
  status: any;
}
export const getAllDriver = createAsyncThunk(
  "get all drivers", // Adjusted action name for clarity
  async (payload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.allDrivers, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      dispatch(toggleLoader({ isLoading: false }));

      // Check if the response status is 200
      if (response.status === 200) {
        // toast.success("driver Data Fetch Successfully!.");
        const data = await response.json();
        // console.log(data);
        dispatch(
          setAllDriver({
            allDriver: data,
          })
        );
        // Check the statusCode from the API response
      } else if (response.status === 500) {
        // handle500Status();
        toast.error("internal server error.");
      } else {
        // Handle non-200 status codes here
        toast.error("Sorry, Something went wrong!.");
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      // Handle fetch or JSON parsing errors here
      console.error(error);
      toast.error("An error occurred. Please try again."); // You can customize the error message
    } finally {
      console.log("Finally");
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const addNewdriver = createAsyncThunk(
  "add new driver",
  async (payload: adddriverPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(endpoints.addDriver, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.status === 201) {
        toast.success(data.messsage);
        // console.log(data);
        dispatch(
          setdriverState({
            isSuccess: true,
          })
        );
        dispatch(getAllDriver());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const deletedriverHandler = createAsyncThunk(
  "delete driver from db",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.deleteDriver}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        dispatch(getAllDriver());
        // console.log(data);
      } else {
        toast.success(data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Sorry!, Something went wrong.");
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const getdriverDetail = createAsyncThunk(
  "get driver details",
  async (payload: any, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const response = await fetch(`${endpoints.viewDriver}${payload}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        dispatch(
          setdriverDetails({
            driver: data,
          })
        );
        toast.success("driver data fetch successfully.");
        console.log(data);
      } else if (data.code === 404) {
        toast.error(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

export const updatedriver = createAsyncThunk(
  "update driver",
  async (payload: updatedriverPayload, { dispatch }) => {
    try {
      dispatch(toggleLoader({ isLoading: true }));

      const bodyObj = {
        user_id: payload.id,
        employee_id: payload.employee_id,
        photo_url: payload.photo_url,
        driver_name: payload.driver_name,
        id_type: payload.id_type,
        id_number: payload.id_number,
        id_issued_by: payload.id_issued_by,
        id_issue_date: payload.id_issue_date,
        id_expiry_date: payload.id_expiry_date,
        pan_number: payload.pan_number,
        license_number: payload.license_number,
        license_expiry_date: payload.license_expiry_date,
        contact_number: payload.contact_number,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        zip_code: payload.zip_code,
        emergency_contact_name: payload.emergency_contact_name,
        emergency_contact_number: payload.emergency_contact_number,
        emergency_contact_relation: payload.emergency_contact_relation,
        status: payload.status,
      };

      const response = await fetch(`${endpoints.editDriver}${payload.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getLocalStorage(
            localStorageActionType.GET_ACCESS_TOKEN
          )}`,
        },
        body: JSON.stringify(bodyObj),
      });

      const data = await response.json();
      if (response.status === 200) {
        toast.success(data.message);
        // console.log(data);
        dispatch(
          setdriverState({
            isSuccess: true,
          })
        );
        dispatch(getAllDriver());
      } else {
        toast.error(data.error);
      }

      if (data.code === 500) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error adding new route:", error);
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
    }
  }
);

const driverSlice = createSlice({
  name: "driver slice",
  initialState: initialState,
  reducers: {
    setAllDriver: (state, action: PayloadAction<allDriverProp>) => {
      return {
        ...state,
        allDriver: action.payload.allDriver,
      };
    },
    setdriverDetails: (state, action: PayloadAction<driverProp>) => {
      return {
        ...state,
        driver: action.payload.driver,
      };
    },
    setdriverState: (state, action: PayloadAction<busdriverProp>) => {
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
      };
    },
    resetdriverState: (state) => {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  setAllDriver,
  setdriverState,
  resetdriverState,
  setdriverDetails,
} = driverSlice.actions;
export default driverSlice.reducer;

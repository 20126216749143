import { FC } from "react";
import Papa from "papaparse";
import useDimension from "../../customHook/useDimention";
import { Button } from "reactstrap";

interface ParseToCsvProps {
  data: any; // The JSON data to be converted to CSV
  fileLabel: string; // A label to be included in the file name
}

const ParseToCsv: FC<ParseToCsvProps> = ({ data, fileLabel }): JSX.Element => {
  // Function to convert JSON data to a CSV blob link
  const convertToBlobLink = () => {
    let csvData = Papa.unparse(data); // Convert JSON to CSV
    let blob = new Blob([csvData], { type: "text/csv" }); // Create a Blob object with CSV data
    let url = window.URL.createObjectURL(blob); // Create a URL for the Blob
    downloadToCsv(url); // Trigger the download
  };

  // Function to download the CSV file
  const downloadToCsv = (blobUrl: string) => {
    let dateObj = new Date();
    let link = document.createElement("a");
    let dateString = `${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`; // Create a date string for the file name
    link.download = `ksm_export_${fileLabel}_${dateString}_${dateObj.getTime()}.csv`; // Set the download file name
    link.href = blobUrl; // Set the href to the Blob URL
    link.click(); // Programmatically click the link to trigger the download
  };

  const dimension = useDimension(); // Assuming useDimension is a custom hook for some functionality

  return (
    <Button
      type="button"
      color="primary"
      className="waves-effect waves-light"
      onClick={convertToBlobLink} // Trigger the conversion and download on click
    >
      Export
    </Button>
  );
};

export default ParseToCsv;

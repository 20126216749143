import { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import * as localStorageActionType from "../localStorage/ActionTypes";
import { getLocalStorage } from "../localStorage/GetLocalStorage";
import Dashboard from "../containers/Dashboard/Dashboard";
import Login from "../containers/Auth/Login/Login";
import ServicesList from "../containers/Services/ServicesList/ServicesList";
import StopsList from "../containers/Schedules/StopsList/StopsList";
import RouteList from "../containers/Schedules/RouteList/RouteList";
import Buses from "../containers/Schedules/Buses/Buses";
import ServiceDetail from "../containers/Services/ServiceDetail/ServiceDetail";
import Profile from "../containers/Profile/Profile";
import BusDetails from "../containers/Schedules/Buses/BusDetails/BusDetails";
import AddService from "../containers/Services/AddService/AddService";
import UserList from "../containers/Users/UsersList/UserList";
import Register from "../containers/Auth/Register/Register";
import AddUser from "../containers/Users/AddUser/AddUser";
import UpdateService from "../containers/Services/UpdateService/UpdateService";
import Page404 from "../containers/NotFound/Page404";
import UserRoleList from "../containers/Users/UserRoleList/UserRoleList";
import AddBus from "../containers/Schedules/Buses/AddBus/AddBus";
import AddUserRole from "../containers/Users/AddUser/AddUserRole";
import AddRoute from "../containers/Schedules/RouteList/AddRoute/AddRoute";
import AddServiceStop from "../containers/Services/AddStop/AddServiceStop";
import UpdateServiceStop from "../containers/Services/UpdateStop/UpdateServiceStop";
import AddStop from "../containers/Schedules/StopsList/AddStop/AddStop";
import UpdateUserRole from "../containers/Users/Update User/UpdateUserRole";
import UserRoleDetails from "../containers/Users/UserDetails/UserRoleDetails";
import TripList from "../containers/Trip/TripList";

import ViewRoute from "../containers/Schedules/RouteList/ViewRoute/ViewRoute";
import UpdateRoute from "../containers/Schedules/RouteList/UpdateRoute/UpdateRoute";
import UpdateBus from "../containers/Schedules/Buses/UpdateBus/UpdateBus";
import ViewStop from "../containers/Schedules/StopsList/ViewStop/ViewStop";
import UpdateStop from "../containers/Schedules/StopsList/UpdateStop/UpdateStop";
import ComplianceList from "../containers/Compliance/ComplianceList";
import Construction from "../containers/NotFound/Construction";
import ViewUser from "../containers/Users/UserDetails/UserDetails";
import UpdateUser from "../containers/Users/Update User/UpdateUser";
import AddTrip from "../containers/Trip/AddTrip/AddTrip";
import UpdateTrip from "../containers/Trip/UpdateTrip/UpdateTrip";
import ViewTrip from "../containers/Trip/ViewTrip/ViewTrip";
import EmployeeList from "../containers/EmployeeList/Employee/EmployeeList";
import AddEmployee from "../containers/EmployeeList/Employee/AddEmployee";
import UpdateEmployee from "../containers/EmployeeList/Employee/UpdateEmployee";
import ViewEmployee from "../containers/EmployeeList/Employee/ViewEmployee";
import PayrollList from "../containers/Payroll/PayrollList/PayrollList";
import AddToPayroll from "../containers/Payroll/PayrollList/AddToPayroll/AddToPayroll";
import ViewPayroll from "../containers/Payroll/PayrollList/ViewPayroll/ViewPayroll";
import UpdatePayroll from "../containers/Payroll/PayrollList/UpdatePayroll/UpdatePayroll";
import PretripChecklist from "../containers/TripChecklist/PretripChecklist";
import ComponentList from "../containers/Payroll/PayrollComponent/ComponentList";
import AddPayrollComponent from "../containers/Payroll/PayrollComponent/AddPayrollComponent/AddPayrollComponent";
import ViewPayrollComponent from "../containers/Payroll/PayrollComponent/ViewPayrollComponent/ViewPayrollComponent";
import UpdatePayrollComponent from "../containers/Payroll/PayrollComponent/UpdatePayrollComponent/UpdatePayrollComponent";
import DesignationList from "../containers/EmployeeList/Designation/DesignationList";
import PayrollRuns from "../containers/Payroll/PayrollRuns/PayrollRuns";
import AddDesignation from "../containers/EmployeeList/Designation/AddDesignation/AddDesignation";
import UpdateDesignation from "../containers/EmployeeList/Designation/UpdateDesignation/UpdateDesignation";
import ViewDesignation from "../containers/EmployeeList/Designation/ViewDesignation/ViewDesignation";
import AddPayrun from "../containers/Payroll/PayrollRuns/AddParun/AddPayrun";
import UpdatePayrun from "../containers/Payroll/PayrollRuns/UpdateParun/UpdatePayrun";
import ViewPayrun from "../containers/Payroll/PayrollRuns/ViewPayrun/ViewPayrun";
import GenerateReport from "../containers/Payroll/PayrollRuns/GenerateReport/GenerateReport";



// import of views

let accessToken = getLocalStorage(localStorageActionType.GET_ACCESS_TOKEN);
// console.log(accessToken)

let rootView = accessToken ? <Dashboard /> : <Login />;

//testing
// let rootView =  <Dashboard /> ;

const routeToBusDetail = (id:number | null) => {
  if (id === null) {
      return "/buses/detail/:busId"
  } else {
      return `/buses/detail/${id}`
  }
}

const routeToUpdateBus = (id:number | null) => {
  if (id === null) {
      return "/buses/update/:busId"
  } else {
      return `/buses/update/${id}`
  }
}

const routeToServiceDetail = (id:any | null) => {
  if (id === null) {
      return "/services/:id"
  } else {
      return `/services/${id}`
  }
}
const routeToAddServiceStop = (id:any | null) => {
  if (id === null) {
      return "/services/add-stop/:id"
  } else {
      return `/services/add-stop/${id}`
  }
}
const routeToUpdateServiceStop = (id:any | null) => {
  if (id === null) {
      return "/services/update-stop/:id"
  } else {
      return `/services/update-stop/${id}`
  }
}

const routeToUpdateService = (id:any | null) => {
  if (id === null) {
      return "/services/update/:id"
  } else {
      return `/services/update/${id}`
  }
}
const routeToViewRoute = (id:any | null) => {
  if (id === null) {
      return "/route/view/:id"
  } else {
      return `/route/view/${id}`
  }
}
const routeToUpdateRoute = (id:any | null) => {
  if (id === null) {
      return "/route/update/:id"
  } else {
      return `/route/update/${id}`
  }
}

const routeToViewStop = (id:any | null) => {
  if (id === null) {
      return "/stops/view/:id"
  } else {
      return `/stops/view/${id}`
  }
}
const routeToUpdateStop = (id:any | null) => {
  if (id === null) {
      return "/stops/update/:id"
  } else {
      return `/stops/update/${id}`
  }
}
const routeToUpdateUserRole = (id:any | null) => {
  if (id === null) {
      return "/user-role/update/:id"
  } else {
      return `/user-role/update/${id}`
  }
}
const routeToViewUserRole = (id:any | null) => {
  if (id === null) {
      return "/user-role/view/:id"
  } else {
      return `/user-role/view/${id}`
  }
}

const routeToUpdateUser = (id:any | null) => {
  if (id === null) {
      return "/users/update/:id"
  } else {
      return `/users/update/${id}`
  }
}
const routeToViewUser = (id:any | null) => {
  if (id === null) {
      return "/user/view/:id"
  } else {
      return `/user/view/${id}`
  }
}
const routeToUpdateTrip = (id:any | null) => {
  if (id === null) {
      return "/trip/update/:id"
  } else {
      return `/trip/update/${id}`
  }
}
const routeToViewTrip = (id:any | null) => {
  if (id === null) {
      return "/trip/view/:id"
  } else {
      return `/trip/view/${id}`
  }
}

const routeToUpdateDriver = (id:any | null) => {
  if (id === null) {
      return "/driver/update/:id"
  } else {
      return `/driver/update/${id}`
  }
}
const routeToViewDriver = (id:any | null) => {
  if (id === null) {
      return "/driver/view/:id"
  } else {
      return `/driver/view/${id}`
  }
}

const routeToUpdateStaff = (id:any | null) => {
  if (id === null) {
      return "/staff/update/:id"
  } else {
      return `/staff/update/${id}`
  }
}
const routeToViewStaff = (id:any | null) => {
  if (id === null) {
      return "/staff/view/:id"
  } else {
      return `/staff/view/${id}`
  }
}

const routeToUpdateCleaner = (id:any | null) => {
  if (id === null) {
      return "/cleaner/update/:id"
  } else {
      return `/cleaner/update/${id}`
  }
}
const routeToViewCleaner = (id:any | null) => {
  if (id === null) {
      return "/cleaner/view/:id"
  } else {
      return `/cleaner/view/${id}`
  }
}
const routeToUpdateEmployee = (id:any | null) => {
  if (id === null) {
      return "/employee/update/:id"
  } else {
      return `/employee/update/${id}`
  }
}
const routeToViewEmployee = (id:any | null) => {
  if (id === null) {
      return "/employee/view/:id"
  } else {
      return `/employee/view/${id}`
  }
}
const routeToViewPayroll = (id:any | null) => {
  if (id === null) {
      return "/payroll/list/view/:id"
  } else {
      return `/payroll/list/view/${id}`
  }
}
const routeToUpdatePayroll = (id:any | null) => {
  if (id === null) {
      return "/payroll/list/update/:id"
  } else {
      return `/payroll/list/update/${id}`
  }
}
const routeToUpdatePayrollComponent = (id:any | null) => {
  if (id === null) {
      return "/payroll/components/update/:id"
  } else {
      return `/payroll/components/update/${id}`
  }
}
const routeToViewPayrollComponent = (id:any | null) => {
  if (id === null) {
      return "/payroll/components/view/:id"
  } else {
      return `/payroll/components/view/${id}`
  }
}
const routeToUpdateDesignation = (id:any | null) => {
  if (id === null) {
      return "/designation/update/:id"
  } else {
      return `/designation/update/${id}`
  }
}
const routeToViewDesignation = (id:any | null) => {
  if (id === null) {
      return "/designation/view/:id"
  } else {
      return `/designation/view/${id}`
  }
}

const routeToUpdatePayrun = (id:any | null) => {
  if (id === null) {
      return "/payrun/update/:id"
  } else {
      return `/payrun/update/${id}`
  }
}
const routeToViewPayrun = (id:any | null) => {
  if (id === null) {
      return "/payrun/view/:id"
  } else {
      return `/payrun/view/${id}`
  }
}
interface IRouterPath {
  rootPath: any;
  dashboard: any;
  login: any;
  register: any;
  profile: any;

  users: any;
  addUser: any;
  update_user: any;
  view_user: any;

  user_role: any;
  add_user_role: any;
  update_user_role: any;
  view_user_role: any;

  servicesList: any;
  addServiceStop: any;
  updateServiceStop: any;
  serviceDetail: any;
  updateService: any;
  addService: any;

  stopList: any;
  addStop: any;
  view_stop: any;
  update_stop: any;

  route: any;
  view_route: any;
  update_route: any;
  add_route: any;

  buses: any;
  add_bus: any;
  busDetail: any;
  updateBus: any;

 

  employee: any;
  addEmployee: any;
  employeeDetail: any;
  updateEmployee: any;

  designation: any;
  addDesignation: any;
  designationDetail: any;
  upDatedesignation: any;

  trip: any;
  addTrip: any;
  tripDetail: any;
  updateTrip: any;
  preTripcheklist:any;
  compliance: any;

  payrollList: any;
  addPayroll: any;
  viewPayroll: any;
  updatePayroll: any;
  generatePayrollReport: any;

  payrollComponents: any;
  addPayrollComponents: any;
  updatePayrollComponents: any;
  viewPayrollComponents: any;

  payrollRuns: any;
  addPayrun: any;
  updatePayrun: any;
  viewPayrun: any;


  construction: any;
}
export const routerPath:IRouterPath = {
  rootPath: "/",
  dashboard:"/",
  login:"/account/login",
  register:"/account/register",
  profile:"/account/profile",

  users:"/users",
  addUser:"/users/add",
  update_user:routeToUpdateUser,
  view_user:routeToViewUser,

  user_role:"/user-role",
  add_user_role:"/user-role/add",
  update_user_role:routeToUpdateUserRole,
  view_user_role:routeToViewUserRole,

  servicesList:"/services",
  addServiceStop:routeToAddServiceStop,
  updateServiceStop:routeToUpdateServiceStop,
  serviceDetail:routeToServiceDetail,
  updateService:routeToUpdateService,
  addService:"/services/add",

  stopList:"/stops",
  addStop:"/stops/add",
  view_stop:routeToViewStop,
  update_stop:routeToUpdateStop,

  route:"/route",
  view_route:routeToViewRoute,
  update_route:routeToUpdateRoute,
  add_route:"/route/add",

  buses:"/buses",
  add_bus:"/buses/add",
  busDetail:routeToBusDetail,
  updateBus:routeToUpdateBus,

 

  employee:"/employee",
  addEmployee:"/employee/add",
  employeeDetail:routeToViewEmployee,
  updateEmployee:routeToUpdateEmployee,

  designation:'/designation',
  addDesignation:'/designation/add',
  designationDetail:routeToViewDesignation,
  upDatedesignation:routeToUpdateDesignation,

  trip:"/trip",
  addTrip:"/trip/add",
  tripDetail:routeToViewTrip,
  updateTrip:routeToUpdateTrip,
  preTripcheklist:"/checklist/pre-trip",

  compliance:"/compliance",
  
  payrollList:"/payroll/list",
  addPayroll:"/payroll/list/add",
  viewPayroll:routeToViewPayroll,
  updatePayroll:routeToUpdatePayroll,
  generatePayrollReport:"/payroll/runs/generate-report",

  payrollComponents:"/payroll/components",
  addPayrollComponents:"/payroll/components/add",
  updatePayrollComponents:routeToUpdatePayrollComponent,
  viewPayrollComponents:routeToViewPayrollComponent,
  construction:"/construction",

  payrollRuns:"/payroll/runs",
  addPayrun:"/payroll/runs/add",
updatePayrun:routeToUpdatePayrun,
  viewPayrun:routeToViewPayrun,

};

const AppRoute: FC = () => {

  // add accessToken when you implement login api for now we add !accesToken
  return accessToken ? (
    <Routes>
      <Route path={routerPath.rootPath} element={rootView} />
      <Route path={routerPath.servicesList} element={<ServicesList/>}/>
      <Route path={routerPath.addService} element={<AddService/>}/>
      <Route path={routerPath.addServiceStop(null)} element={<AddServiceStop/>}/>
      <Route path={routerPath.updateServiceStop(null)} element={<UpdateServiceStop/>}/>

      <Route path={routerPath.serviceDetail(null)} element={<ServiceDetail/>}/>
      <Route path={routerPath.updateService(null)} element={<UpdateService/>}/>
      
      <Route path={routerPath.stopList} element={<StopsList/>}/>
      <Route path={routerPath.addStop} element={<AddStop/>}/>
      <Route path={routerPath.view_stop(null)} element={<ViewStop/>}/>
      <Route path={routerPath.update_stop(null)} element={<UpdateStop/>}/>



      <Route path={routerPath.route} element={<RouteList/>}/>
      <Route path={routerPath.add_route} element={<AddRoute/>}/>
      <Route path={routerPath.view_route(null)} element={<ViewRoute/>}/>
      <Route path={routerPath.update_route(null)} element={<UpdateRoute/>}/>


      <Route path={routerPath.buses} element={<Buses/>}/>
      <Route path={routerPath.add_bus} element={<AddBus/>}/>
      <Route path={routerPath.busDetail(null)} element={<BusDetails/>}/>
      <Route path={routerPath.updateBus(null)} element={<UpdateBus/>}/>

      <Route path={routerPath.trip} element={<TripList/>}/>
      <Route path={routerPath.addTrip} element={<AddTrip/>}/>
      <Route path={routerPath.updateTrip(null)} element={<UpdateTrip/>}/>
      <Route path={routerPath.tripDetail(null)} element={<ViewTrip/>}/>
      <Route path={routerPath.preTripcheklist} element={<PretripChecklist/>}/>



      <Route path={routerPath.compliance} element={<ComplianceList/>}/>


   
      
      <Route path={routerPath.employee} element={<EmployeeList/>}/>
      <Route path={routerPath.addEmployee} element={<AddEmployee/>}/>
      <Route path={routerPath.updateEmployee(null)} element={<UpdateEmployee/>}/>
      <Route path={routerPath.employeeDetail(null)} element={<ViewEmployee/>}/>


      <Route path={routerPath.designation} element={<DesignationList/>}/>
      <Route path={routerPath.addDesignation} element={<AddDesignation/>}/>
      <Route path={routerPath.upDatedesignation(null)} element={<UpdateDesignation/>}/>
      <Route path={routerPath.designationDetail(null)} element={<ViewDesignation/>}/>



      <Route path={routerPath.profile} element={<Profile/>}/>

      <Route path={routerPath.users} element={<UserList/>}/>
      <Route path={routerPath.view_user(null)} element={<ViewUser/>}/>
      <Route path={routerPath.update_user(null)} element={<UpdateUser/>}/>
      <Route path={routerPath.addUser} element={<AddUser/>}/>


      <Route path={routerPath.user_role} element={<UserRoleList/>}/>
      <Route path={routerPath.add_user_role} element={<AddUserRole/>}/>
      <Route path={routerPath.update_user_role(null)} element={<UpdateUserRole/>}/>
      <Route path={routerPath.view_user_role(null)} element={<UserRoleDetails/>}/>

      <Route path={routerPath.payrollList} element={<PayrollList/>}/>
      <Route path={routerPath.addPayroll} element={<AddToPayroll/>}/>
      <Route path={routerPath.viewPayroll(null)} element={<ViewPayroll/>}/>
      <Route path={routerPath.updatePayroll(null)} element={<UpdatePayroll/>}/>
      
      <Route path={routerPath.generatePayrollReport} element={<GenerateReport/>}/>

      <Route path={routerPath.payrollComponents} element={<ComponentList/>}/>
      <Route path={routerPath.addPayrollComponents} element={<AddPayrollComponent/>}/>
      <Route path={routerPath.viewPayrollComponents(null)} element={<ViewPayrollComponent/>}/>
      <Route path={routerPath.updatePayrollComponents(null)} element={<UpdatePayrollComponent/>}/>

      <Route path={routerPath.payrollRuns} element={<PayrollRuns/>}/>
      <Route path={routerPath.addPayrun} element={<AddPayrun/>}/>
      <Route path={routerPath.viewPayrun(null)} element={<ViewPayrun/>}/>
      <Route path={routerPath.updatePayrun(null)} element={<UpdatePayrun/>}/>

      <Route path={"*"} element={<Page404/>} />
      <Route path={routerPath.construction} element={<Construction/>} />

    

      <Route path={routerPath.login} element={<Navigate to={routerPath.rootPath} />} />
      {/* <Route path={routerPath.login} element={<Login/>} /> */} 
      //testing     


    </Routes>
  ) : (
    <Routes>
      <Route path={routerPath.login} element={rootView} />
      {/* <Route path={routerPath.register} element={<Register/>}/> */}
      
      <Route path={"*"} element={<Navigate to={routerPath.login} />} />
    </Routes>
    
  );
};

export default AppRoute;

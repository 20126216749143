import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { changeHeading } from '../../features/topbarHeadingSlice';

const PretripChecklist = () => {
    document.title = "Pre Trip Checklist | KSM - Admin Dashboard.";

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
          changeHeading({
            heading: "Pre Trip Checklist",
            isBackBtn: false,
            breadcrumbItems: [
              { title: "Ksm", link: "#" },
              { title: "Pre Trip Checklist", link: "#" },
            ],
          })
        );

      }, []);
  return (
    <div>PretripChecklist</div>
  )
}

export default PretripChecklist